$xxx-desktop: 3200px;
$xx-desktop: 2800px;
$x-desktop: 2400px;
$huge-desktop: 2000px;
$large-desktop: 1600px;
$big-desktop: 1200px;
$desktop: 1000px;
$small-desktop: 800px;
$tablet: 720px;
$phone: 480px;

$background-color:#d9e1e4;
$background-color-mobile: #dbe3e8; //#cdd7de; //#d7e0e5; <- need to change comment-feed-bar-top too


$darkest-dark: #282828;
$contrast-blue: #015d92;
$fill-blue: #0082cb;
$bright-blue: #59c4ff;
$fill-gray: #4e5962;
$bright-orange: #ffa740;
$light-grey-background:#ededed;
$grey-border: #e1e1e1;
$mid-grey-border: #727272;
$dark-grey-border: #525252;
$hover-grey: #eaeaea;

body {
	// background-color: #c1cfd7; bluer background
	background-color: $background-color;
	// background:linear-gradient(90deg, #cdd6da 10%, #a7b7be 45%, #a7b7be 55%, #cdd6da 90%);

	font-family: poppins, noto sans, arial, sans-serif, 'Abril Fatface', 'Playfair';
	//darker-blue
	//background-color: #bac7cf;
	color: $darkest-dark;
	// scroll-behavior: smooth;
	// position: relative;
	font-size: 15px;
	// margin: 33px;
	//max-width: 1600px; //hmm not doing what I wanted
	@media screen and (max-width: $phone) {
		font-size: 14px;
		background-color: $background-color-mobile;
		// background: linear-gradient(90deg, #b3bcc2 10%, #c5d9e2 90%); //linear-gradient(90deg, #b0b6bd 10%, #bcd5df 90%)
	}
}

// FUCK APPLE 
button{
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
}

.dropdown{
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
}

.img_fluid{
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
}

p,ol,ul,td {
	font-family: poppins, Helvetica, noto sans, arial, sans-serif;
	font-size: 15px;
	line-height: 1.5;
	margin-bottom: 0.5rem;
	@media screen and (max-width:$desktop){
	    font-size:14px;
	}
}

p:empty{
	margin-bottom:0px !important;
}

ol {
	padding-left: 1rem !important;
}

pre {
	background-color: #eee;
	padding: 10px;
	font-size: 11px;
}

a {
	color: #1f97db;
	text-decoration: none;
}

a:visited {
	color: #1f97db;
}

a:hover {
	color: #0c53bb;
	// color: rgb(0, 0, 0);
	text-decoration: none;
	//background-color: #000;
}

th {
	padding-bottom: 5px;
}

td {
	padding: 0 5px 7px;
}

div.field,
div.actions {
	margin-bottom: 10px;
	padding: 10px;
}

#notice {
	color: green;
}

table{
	margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}


.field_with_errors {
	padding: 2px;
	background-color: red;
	display: table;
}

#error_explanation {
	width: 450px;
	border: 2px solid red;
	padding: 7px 7px 0;
	margin-bottom: 20px;
	background-color: #f0f0f0;
}

#error_explanation h2 {
	text-align: left;
	font-weight: bold;
	padding: 5px 5px 5px 15px;
	font-size: 12px;
	margin: -7px -7px 0;
	background-color: #c00;
	color: #fff;
}

#error_explanation ul li {
	font-size: 12px;
	list-style: square;
}

label {
	display: block;
}

img {
	max-width: 100%;
	height: auto;
	border-radius: 0.1rem;
}

iframe {
	max-width: 100%;
	// height: auto;
	// border-radius:0.5rem; <<< This does not only effect videos it effects the create comment section as well!
}

blockquote{
	padding: 1rem;
    background-color: #f4f4f4;
    border-radius: 0.25rem;
}

thead, tbody, tfoot, tr, td, th {
    border-color: #d5d5d5;
    border-style: solid;
    border-width: 1px;
}

td{
	padding:0.5rem;
}
//NEED A GROUP LIKE THIS - including comments & elsewhere??

// .main-col h1,
// .post-page-post h1,
// .user-intro h1,
// .front-top-box h1 {
// 	font-size: 1.3rem;
// 	@media screen and (max-width: $phone) {
// 		font-size: 1.3rem;
// 	}
// }


// .post-card-text-cont p{
// 	display:inline-block !important;
// }


// IMAGES!!! /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// post-card images

.post_card_img_container{
	display:flex;
	margin-top: 0.5rem;
	width:100%;

	// width:calc(100% + 2rem);
	// max-width:calc(100% + 2rem);
	// margin-left:-1rem !important;
	border-radius:0rem;
	// min-height:200px;
	margin-bottom:0.25rem !important;
	// @media screen and (max-width:$desktop){
	// 	width:calc(100% + 2rem);
	// 	max-width:calc(100% + 2rem);
	// 	margin-left:-1rem !important;
	// 	border-radius: 0rem;
	// 	margin-bottom:0rem !important;
	// 	// max-height:350px;
	// 	// box-shadow: #0000002b 0px 2px 2px 1px, #0000002b 0px -2px 2px 1px;
    // }
}

.post_card_img{
	margin-left:auto;
	margin-right:auto;
	width:auto;
	max-width: 100%;
	height:auto !important;
	max-height: 600px !important;
	border-radius: 1rem;
	@media screen and (max-width:$desktop){
		max-height: 400px !important;
		border-radius: 0.25rem !important;
    }
	@media screen and (max-width:$desktop) and (orientation: landscape){
		max-width:400px !important;
		border-radius: 0.25rem !important;
    }
}

// images in post

.post_img_container{
	display:flex;
	margin-bottom: 3rem;
	margin-top: 3rem;
	@media screen and (max-width:$desktop){
		margin-top:2rem;
		margin-bottom:2rem;
    }
}

.post_img_container:first-child{
	margin-top: 1rem;
}


.post_img{
	width:auto;
	height:auto !important;
	margin-left:auto;
	margin-right:auto;
	max-width: 100%;
	max-height: 500px;
	border-radius: 0.25rem;
	min-height:100px;
	// background-color: black;
	// background-image: url(../images/sideCards/sacrifices.jpg);
	// background-position: 0 0, 0 0;
	// background-repeat: no-repeat, no-repeat;
	@media screen and (max-width:$desktop){
		min-height:100px;
		max-height: 500px;
		border-radius: 0.25rem;
    }
	@media screen and (max-width:$desktop) and (orientation: landscape){
		max-width:400px;
		border-radius: 0.25rem !important;
    }
}

.post_img:before{
	content:" "; 
	background:url(../images/missingImage/missingImage.jpg);
	display: block; 
	position:absolute;
	background-position: 0 0, 0 0;
	background-repeat: no-repeat, no-repeat;
	background-size:contain;
	width:400px;
	height:00px;
	transform: translate( calc(-50% + 8px), 0%);
	@media screen and (max-width:$desktop){
		width:200px;
		height:100px;
	}
}

// images in comments

.comment_img_container{
	display:flex;
	margin-bottom: 0.75rem;
	margin-top: 0rem;
}

.comment_post_img{
	width:auto;
	height:auto !important;
	margin-left:auto;
	margin-right:auto;
	max-width: 100%;
	max-height: 300px;
	border-radius: 0.25rem;
	@media screen and (max-width:$desktop){
		max-height: 250px;
    }
	@media screen and (max-width:$desktop) and (orientation: landscape){
		max-width:300px;
		border-radius: 0.25rem !important;
    }
}

.editor-image{
	// margin-bottom: 1.5rem;
	// margin-top: 1.5rem;
	max-height: 800px;
	border-radius: 0.25rem;
	min-height:100px;
	@media screen and (max-width:$desktop){
		// margin-top:1rem;
		// margin-bottom:1rem;
		max-height: 700px;
    }
}

.editor-image:first-child{
	margin-top:0rem;
}

// Videos /////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// post card video

.post_card_video_container{
	display:flex;
	margin-left:auto;
	margin-right:auto;
	margin-top:0.5rem !important;
	margin-bottom:0.5rem !important;
	width:100%;
	
	// width:calc(100% + 2rem);
	// max-width:calc(100% + 2rem);
	// margin-left:-1rem !important;
	height:auto !important;
	max-height: 450px;
	min-height:160px;
	border-radius:0.5rem;
}

.post_card_video{
	max-width: 100%;
	max-height: 400px;
	min-height:180px;
	margin-top:0.75rem;
	border-radius: 0.25rem;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	//   height:auto;
	@media screen and (max-width:$desktop){
		max-height: 160px !important;
		border-radius:0.25rem;
    }
	@media screen and (max-width:$desktop) and (orientation: landscape){
		max-width: 400px;
		border-radius: 0.25rem !important;
    }
}

// post videos

.post_video_container{
	display:flex;
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

.post_video{
	border-radius: 0.25rem;
	max-width: 100%;
	margin-top:0rem;
	width:100%;
	max-height: 400px;
	min-height:180px;
}

// videos in comments

.comment_post_video{
	border-radius: 0.1rem;
	max-width: 100%;
	width:100%;
	max-height: 350px;
	min-height:180px;
}

// I have no idea what this is //////////////////////////////////
.ytp-chrome-top{
	@media screen and (max-width:$desktop){
		display:none !important;
    }
}

// Lists ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.post-page-post-text ul, 
.comment_text ul,
.user-intro ul,
.horz-accord-card ul {
	padding-left: 2rem !important;
}

.is_clickable ul{
	padding-left: 2rem !important;
}

.post-page-post-text ol, 
.comment_text ol,
.user-intro ol,
.sideCard ol,
.horz-accord-card ol {
	padding-left: 2rem !important;
}

.is_clickable ol{
	padding-left: 2rem !important;
}

.tinymce_image_class{
	max-width:100%;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:0.5rem;
	display:flex;
}

// button ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.btn-primary {
	background-color: $fill-blue !important;
	border: 1px solid $fill-blue !important;
}

.btn-primary:hover {
	background-color: $hover-grey !important;
	border: 1px solid $hover-grey !important;
	color:$darkest-dark !important;
}

.sec-btn {
	background-color: $fill-gray !important;
	// border: 1px solid $fill-gray !important;
}

.sec-btn:hover {
	background-color: $hover-grey !important;
	color:$darkest-dark !important;
	// border: none !important;
	// margin-top: 1px;
	// margin-bottom: 1px;
}


h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 600;
}

h1 {
	font-size: 24px;;
	@media screen and (max-width: $phone) {
		font-size: 24px;;
	}
}

h2 {
	font-size: 20px;
	color: $darkest-dark;
	font-style: normal;
	@media screen and (max-width: $phone) {
		font-size: 20px;
	}
}

h3 {
	font-size: 17px;
	@media screen and (max-width: $phone) {
		font-size: 17px;
	}
}

h4 {
	font-size: 15px;
	@media screen and (max-width: $phone) {
		font-size: 15px;
	}
}

h5 {
	font-size: 15px;
	@media screen and (max-width: $phone) {
		font-size: 15px;
	}
}

h6 {
	font-size: 15px;
	@media screen and (max-width: $phone) {
		font-size: 15px;
	}
}

h2 strong{
	font-weight:600;
}

h3 strong{
	font-weight:600;
}


// p,ol,ul,td 
.ProseMirror p,
.horz-accord-card p,
.post-page-post p,
.user-intro p,
.front-top-box p {
	margin-bottom:1rem;
	line-height: 1.7;
}

.ProseMirror ul,
.horz-accord-card ul,
.post-page-post ul,
.user-intro ul,
.front-top-box ul {
	margin-bottom:1rem;
	line-height: 1.7;
}

.ProseMirror ol,
.horz-accord-card ol,
.post-page-post ol,
.user-intro ol,
.front-top-box ol {
	margin-bottom:1rem;
	line-height: 1.7;
}

.post-page-post h2:first-child,
.ProseMirror h2:first-child,
.user-intro h2:first-child{
	margin-top:0rem;
}

.post-page-post h3:first-child,
.ProseMirror h3:first-child,
.user-intro h3:first-child{
	margin-top:0rem;
}

.comment_text h1,
.ProseMirror h1,
.horz-accord-card h1,
.post-page-post h1,
.user-intro h1,
.front-top-box h1 {
	font-size: 24px;
	letter-spacing:-0.25px;
	margin-bottom: 0.5rem;
	@media screen and (max-width: $phone) {
		font-size: 28px;
	}
}

.comment_text h2,
.ProseMirror h2,
.horz-accord-card h2,
.post-page-post h2,
.user-intro h2,
.front-top-box h2 {
	font-size: 18px;
	color: $darkest-dark;
	font-style: normal;
	letter-spacing: -0.15px;
	font-weight: 600;
	margin-bottom: 1rem;
	margin-top:3rem;
	@media screen and (max-width: $phone) {
		font-size: 18px;
	}
}

.comment_text h3,
.ProseMirror h3,
.horz-accord-card h3,
.post-page-post h3,
.user-intro h3,
.front-top-box h3 {
	font-size: 16px;
	font-weight: 600;
	color:$darkest-dark;
	margin-top:3rem;
	margin-bottom: 1rem;
	@media screen and (max-width: $phone) {
		font-size: 16px;
	}
}

.comment_text h2:first-child,
.ProseMirror h2:first-child,
.horz-accord-card h2:first-child,
.post-page-post h2:first-child,
.user-intro h2:first-child,
.front-top-box h2:first-child {
	margin-top:0rem !important;
}

.comment_text h3:first-child,
.ProseMirror h3:first-child,
.horz-accord-card h3:first-child,
.post-page-post h3:first-child,
.user-intro h3:first-child,
.front-top-box h3:first-child {
	margin-top:0rem !important;
}


.comment_text h4,
.ProseMirror h4,
.horz-accord-card h4,
.post-page-post h4,
.user-intro h4,
.front-top-box h4 {
	font-size: 15px;
	color:#575757;
	font-weight: 500;
	margin-bottom: 0.5rem;
	@media screen and (max-width: $phone) {
		font-size: 15px;
	}
}

.comment_text h5,
.ProseMirror h5,
.horz-accord-card h5,
.post-page-post h5,
.user-intro h5,
.front-top-box h5 {
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 0.5rem;
	@media screen and (max-width: $phone) {
		font-size: 15px;
	}
}

.comment_text h6,
.ProseMirror h6,
.horz-accord-card h6,
.post-page-post h6,
.user-intro h6,
.front-top-box h6 {
	font-size: 15px;
	margin-bottom: 0.5rem;
	font-weight: 400;
	@media screen and (max-width: $phone) {
		font-size: 15px;
	}
}

.comment_text h1{
	font-size: 17px;
	@media screen and (max-width: $phone) {
		font-size: 17px;
	}
}

.comment_text h2{
	font-size: 20px;
	color: $darkest-dark;
	font-style: normal;
	@media screen and (max-width: $phone) {
		font-size: 20px;
	}
}

.comment_text h3{
	font-size: 17px;
	@media screen and (max-width: $phone) {
		font-size: 17px;
	}
}

.comment_text h4{
	font-size: 1rem;
	@media screen and (max-width: $phone) {
		font-size: 1rem;
	}
}

.comment_text h5{
	font-size: 0.9rem;
	@media screen and (max-width: $phone) {
		font-size: 0.9rem;
	}
}

.comment_text h6{
	font-size: 0.8rem;
	@media screen and (max-width: $phone) {
		font-size: 0.8rem;
	}
}


.main-col h1{
	font-size: 17px;
	@media screen and (max-width: $phone) {
		font-size: 16px;
	}
}

.main-col h2{
	font-size: 15px;
	font-weight: 400;
	@media screen and (max-width: $phone) {
		font-size: 15px;
	}
}

.main-col h3{
	font-size: 15px;
	font-weight: 400;
	@media screen and (max-width: $phone) {
		font-size: 15px;
	}
}

.main-col h4{
	font-size: 15px;
	font-weight: 400;
	@media screen and (max-width: $phone) {
		font-size: 15px;
	}
}

.main-col h5{
	font-size: 15px;
	font-weight: 400;
	@media screen and (max-width: $phone) {
		font-size: 15px;
	}
}

.main-col h6{
	font-size: 15px;
	font-weight: 400;
	@media screen and (max-width: $phone) {
		font-size: 15px;
	}
}

.main-col li p{
	display:inline !important;
}

// h1 + h2{
// 	margin-top:0rem !important;
// }

// h2 + h3{
// 	margin-top:0rem !important;
// }