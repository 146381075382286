/*
* This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
 */

//do not comment out or remove $enable-smooth-scroll: false. See: https://github.com/LukeClancy/turbo_bootstrap_issue
//	it was a real pain, and its an issue that will take a while to find, and everyone will be like: what? why? when they find it
//	and then it will take even longer to fix.
$enable-smooth-scroll: false;

//dont have to import all bootstrap see: https://github.com/twbs/bootstrap-rubygem#a-ruby-on-rails
@import 'bootstrap-custom';
/////@import 'bootstrap-icons';
@import "scaffolds";
// import my changes to tinyMCE text editor
@import "tiny";

// Import text style for fancy intial letters
@font-face{
	font-family:"myGaramond";
	src: url("https://fonts.googleapis.com/css2?family=EB+Garamond")
}



 // Media-query variables
$xxx-desktop:3200px;
$xx-desktop:2800px;
$x-desktop:2400px;
$huge-desktop:2000px;
$large-desktop:1600px;
$big-desktop:1200px;
$desktop:1000px;
$small-desktop:800px;
$tablet:720px;
$phone:480px;

// $link-blue-two: #1f8ecc;
$link-blue-two: #0073b0; //darker
$link-blue: #0089d2;

// dull base
// $base-blue: #a6cade;
// $base-orange:#e7ccac; 
//bright base
$base-blue: #1c95ef; //#50a6e4; //#89bdd9; //#89bdd9; 
$base-orange: #f39827; //#ed9f40; //#E7BB86; //#E7BB86;
$base-green: #00b58d;


$bright-blue: #1c95ef; // #77b6d8; // #57b6eb; //#59c4ff;
//#7acbf9;
$bright-orange: #f39827; // #e7a75c; //#ffa740;

$dark-blue-sym: #187cb4;
// dark-orange-sym: #

 //#f5b267;
$dark-blue:#48606c;
$dark-blue-two: #628ca7;

$darkest-dark:#1f1f1f;

$background-color:#ccd4d9; // #d4dbdf
$background-color-mobile:#dfe5e9; //#d7e0e5;

$light-grey-background:#ededed;
$grey-border: #e5e5e5;
$mid-grey-border: #727272;
$dark-grey-border: #525252;
$hover-grey: #f1f1f1;


$back-grey: #e9ebef; // #e3e5e8; // #f0f0f0;
$back-grey-mobile:#dddddd;
$back-grey-two: #eaeaea;


$diffy-add: #0076bf;//#c9f0ec; 
$diffy-remove:  #d3882c; //#ffdddd;
$diffy-remove-line: #d3882c;

$common-box-shadow: none; // 0px 1px 3px 0px #4040400f;
$common-box-shadow-mobile: none; //0px 1px 2px 1px #40404026;

$common-border-radius: 0rem;
$common-border-radius-right: 0rem 0.25rem 0.25rem 0rem;
$common-border-radius-left: 0.25rem 0rem 0rem 0.25rem;
$common-border-radius-up: 0.25rem 0.25rem 0rem 0rem;
$common-border-radius-down: 0rem 0rem 0.25rem 0.25rem;

$common-border-radius-mobile:0rem; //0.25rem;
$common-border-radius-right-mobile: 0rem; // 0rem 0.25rem 0.25rem 0rem;
$common-border-radius-left-mobile: 0rem; //0.25rem 0rem 0rem 0.25rem;
$common-border-radius-up-mobile: 0rem; // 0.25rem 0.25rem 0rem 0rem;
$common-border-radius-down-mobile: 0rem; //0rem 0rem 0.25rem 0.25rem;

$margin-mobile-1:1px;
$margin-dt-1:1px;
$cardGap:5px;

$arch-border-radius: 0.15rem 0.15rem 0.15rem 0.15rem;

$depth-shadow-color:#bbc9d2; //#dce1e5;
$depth-shadow-mobile: linear-gradient(90deg, #a2aab2 10%, #8ca6b0 90%);
$depth-shadow-dark-blue:#acb8c1; //#95acb8; //#9db5c2; //#adbdc5; // between black and white
$depth-shadow-dark-blue-mobile: #acb8c1; //#88a1af;
$depth-shadow-blue: #b8c5ce; //#ccd4d9; //between white and white for heightened contrast
$depth-shadow-orange: #f0ede7;
// $cast-shadow: -40px 20px 200px 0px #acb6bb;
// $cast-shadow-menu: -40px 20px 200px 0px #b5c0c5, -10px 10px 5px 0px #b5c0c5;
// $cast-shadow-shaft: -40px 10px 200px 0px #b8c2c7;
$cast-shadow: none;
$cast-shadow-menu: none;
$cast-shadow-shaft: none;

$col-grad: #cdd6de; // linear-gradient(90deg, #cbd1d5 0%, #cdd2d6 5%, #eaeaea 70%);
$col-grad-light: #e9eff1;

.width-in{
    width:calc(1060px * 0.75);
	z-index:1;
	box-shadow:$cast-shadow-shaft;
	// background: $col-grad; //linear-gradient(90deg, #b0b7bf 10%, #a3bac4 90%);
	@media screen and (max-width:$desktop){
		// background: none;
		box-shadow:none;
    }
}


:root {
	--large:1060px;
	--med-large:980px;
	--med:880px;
	--med-small:780px;
	--small:720px;
	--x-small:640px;
}


.navbar{
	background-color: #ffffff;
    box-shadow: 0px 1px 0px 0px #40404040;
    color:$darkest-dark;
    border: 0;
    padding: 2px;
}


.width-out{
    padding:0px !important;
    // width:auto;
    flex: 1 0;
	z-index:0;
	@media screen and (max-width:$tablet){
        visibility: hidden;
    }
}

$col-width:0.75; //doesnt workkkk

.width-in{
    max-width:600px;
    // min-width:600px;
	margin-left:auto;
	margin-right:auto;
    @media screen and (min-width:$xxx-desktop){
        // width:calc(1600px * 0.8);
		width:calc(var(--large) * 0.90);
    }
    @media screen and (max-width:$xxx-desktop) and (min-width:$xx-desktop){
        // width:calc(1540px * 0.8);
		width:calc(var(--large) * 0.90);
    }
    @media screen and (max-width:$xx-desktop) and (min-width:$x-desktop){
        // width:calc(1480px * 0.8);
		width:calc(var(--med-large) * 0.80);
    }
    @media screen and (max-width:$x-desktop) and (min-width:$huge-desktop) {
        width:calc(var(--med-large) * 0.80);
    }
    @media screen and (max-width:$huge-desktop) and (min-width:$large-desktop){
		width:calc(var(--med) * 0.85);
    }
    @media screen and (max-width:$large-desktop) and (min-width:$big-desktop){
        width:calc(var(--med) * 0.85);
    }
    @media screen and (max-width:$big-desktop) and (min-width:$desktop){
        width:calc(var(--med-small) * 0.80);
    }
    @media screen and (max-width:$desktop) and (min-width:$small-desktop) {
        width:calc(var(--small) * 0.70);
    }
    @media screen and (max-width:$small-desktop) and (min-width:$tablet){
        width:calc(var(--x-small) * 0.70);
    }
    @media screen and (max-width:$tablet) and (min-width:$phone){
        width:calc(100% );
    }
    @media screen and (max-width:$phone){
        width:calc(100%);
        // min-width:none;
    }
	width:calc(var(--med-large) * 0.80);
    flex:none;
	// min-height:calc(100vh - 6rem); <breaks small posts why did i do this?
}

.my-footer-is-cool .width-in{
	min-height:100% !important;
}

.phoneClass .width-in{
	width:100% !important;
}

.absolute_mf_navbar{
	position:fixed;
	width:100%;
	z-index:1000;
	@media screen and (max-width:$tablet){
		position:relative;
	}
}

.mf_navbar{
	width:fit-content !important;
	right:1rem;
	top:1rem;
	position: relative;
	z-index:1000;
	@media screen and (max-width:$tablet){
		width:100% !important;
		top:0rem;
		right:0rem;
		border-radius:0rem !important;
		box-shadow:none;
		height:60px !important;
	}
}

.navbar_extra{
    height:50px;
	gap:0.75rem;
    border-radius: 0.25rem;
    margin-left:auto;
    margin-bottom: 0rem !important;
    flex-wrap: nowrap;
    padding:0px;
	border:none !important;
}

.button_bar_my_class{
	overflow:hidden;
	border-radius:0.25rem 0.25rem 0rem 0rem;
	background-color: $back-grey; //d4d4d4;
	gap:1rem !important;
	border-bottom:1px solid #eaeaea;
	@media screen and (max-width:$desktop){
		background-color:$back-grey; //#d1d3d8;
		box-shadow:$common-box-shadow-mobile;
		gap:0.5rem !important;
    }
}

.logocopy{
	width:80px;
	margin-left:0.2rem;

}

.logo_group{
	width:fit-content;
	margin-top:2px;
	margin-left:1.25rem;

}


.notif_menu_group{
	margin-right:1.25rem;
}

.search_button{
	margin-left: auto;
    background-color: white;
    // border: 1px solid #dbdbdb;
	// display:none !important;
    width: 35px;
    height: 35px;
    border-radius: 2rem;
    margin-top: auto;
    margin-bottom: auto;
	display:flex;
	// -webkit-appearance: none !important;
	// -moz-appearance: none !important;
	@media screen and (max-width:$desktop){
		width:34px;
		height:34px;
    }
}

.search_button:hover{
	transform: scale(0.95);
	cursor:pointer;
	background-color:#f6f6f6;
}

.search_img{
	margin-left:auto;
	margin-right:auto;
	margin-top:auto;
	margin-bottom:auto;
	width:22px;
	@media screen and (max-width:$desktop){
		width:22px;
    }
}

.search_top{
	padding:1.25rem;
	padding-left:1.5rem;
	padding-right:1.5rem;
}

.search_card{
	display:block;
	padding:1.25rem;
	padding-left:1.5rem;
	padding-right:1.5rem;
	border-top:1px solid #eaeaea;
	color:$darkest-dark;
	background-color:white;
}

.search_card b{
	color:$link-blue !important;
}

.search_bottom_row .big_wrapper{
	margin-top:0rem !important;
	margin-left:0rem !important;
	margin-right:10px !important;
}

.search_bar{
	background-color:#f4f4f4;
	border-radius:1rem;
	padding: 4px;
    padding-left: 12px;
    padding-right: 12px;
	display:flex;
}

.search_input{
	border:none;
	margin-right:5px;
	padding: 4px;
    padding-left: 8px;
	width:85%;
	outline:none !important;
	background-color:#00000000;
}

.search_input:focus-visible{
	outline:none !important;
}

.search_search_btn{
	border:none;
	width:auto;
	float:right;
	background-color:#00000000;
	margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
}



.new_post_button{
	@extend .search_button;
	width:auto !important;
	padding-left: 0.5rem;
    padding-right: 0.75rem;
}

.new_post_button .search_img{
	width: 18px;
}

.switchFeedRow{
	width:fit-content;
	// margin-left:0.5rem;
}

.light-grey-card{
	background-color:#eaeaea !important;
}

.see_as_floating_button{
	// background-color: #eaeaea;
    padding: 0.5rem;
	padding-left:0.75rem;
	padding-right:0.75rem;
    border-radius: 2rem;
    margin-top: auto;
    margin-bottom: auto;
	border:none;
}

.feed_floating_button{
	// background-color: #eaeaea;
    padding: 0.3rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
	padding-right:0.5rem;
    border-radius: 2rem;
    margin-top: auto;
    margin-bottom: auto;
	border:none;
}

.feed_floating_button:hover{
	background-color: #f1f1f1 !important;
    transform: scale(0.96);
	cursor:pointer;
}

.see_in_topic_feed{
	border:none;
	background-color: white;
	padding-left:0rem;
	padding-right:0rem;
}

.see_as_floating_button:hover{
	background-color: #f1f1f1 !important;
    transform: scale(0.96);
	cursor:pointer;
}

.see_in_topic_feed:hover{
	transform: scale(0.96);
	cursor:pointer;
}

.feedIcon{
	padding-bottom:1px !important;
}

.toTextIcon{
	width: 20px;
    height: 20px;
    max-width: 20px;
    margin-top: auto;
    margin-bottom: auto;
	filter: invert(51%) sepia(44%) saturate(2596%) hue-rotate(171deg) brightness(90%) contrast(87%);
}

.switch-back-to-topic-text-text{
	font-size:13px;
	line-height:20px;
	color: #1995d8;
}

.floating-feed-text{
	font-size:9px;
	color: #1f1f1f;
}

.search-dropdown{
	background-color:white !important;
	padding:1.5rem;
}

.navbar-brand{
    color:white;
    padding-top:0;
    padding-bottom:0;
    margin-left: 0.25rem;
    @media screen and (max-width:$desktop){
        margin-right:0rem;
        margin-left:0.25rem;
    }
}

.title{
    color:white !important;
    font-family: poppins, noto sans, arial, sans-serif;;
    font-weight:300;
    font-size: 19px;
    @media screen and (max-width:$desktop){
        color:grey !important;
		font-size:22px;
		// display: none !important;
    }
}

.titleTwo{
    padding-left:1px;
}

.titleVersion{
	padding-left:0.5rem;
	@media screen and (max-width:$desktop){
		display:none !important;
    }
	
}

.title:hover{
    font-style: normal !important;
    text-decoration: none !important;
    color:#57b6eb !important;
}

.subtitle{
    color:$darkest-dark;
    font-family: poppins, noto sans, arial, sans-serif;
    font-weight: normal;
    font-size: 1.2rem;
    margin-top: 1.3rem;
    padding-left:3px;
}

.logo_image_container{
    width: auto;
    // min-width:35px;
    height:27px;
	margin-top:2px;
    // height:17px;
    margin-left:1rem;
    margin-right:0rem;
    align-items: center;
    display:flex;

    @media screen and (max-width:$phone){
        height:27px;
		margin-top:0px;
		margin-left:0rem;
    }
}

.logo_image{
    height:100%;
    margin-bottom:0px;
}

.logo_image:hover{
	@media screen and (min-width:$desktop){
		transform:scale(0.95) !important;
	}
}

.logo_copyWriting{
	width:auto;
	height:30px;
	margin-bottom:-2px;
	filter: invert(100%) sepia(5%) saturate(19%) hue-rotate(215deg) brightness(103%) contrast(102%);
	
}

.logo_copyWriting:hover{
	transform:scale(0.98) !important;
	// filter: invert(43%) sepia(91%) saturate(1405%) hue-rotate(182deg) brightness(97%) contrast(93%);
}


.app-wide .avatar-container.irl{
	margin-right:1rem;
}

.avatar-container.irl{
	margin-right:0.75rem;
	margin-left:0.5rem;
}

.irl .avatar_image{
	width:24px;
	height:24px;
	margin-top:4px;
	object-fit: cover;
	border-radius:0.2rem;
}


.avatar-container{
    width:auto;
    // height:14px;
    margin-right:0.25rem;
	margin-left:-0.25rem;
    align-items:center;
    @media screen and (max-width:$desktop){
        margin-left:0rem;
        margin-right:0.25rem;
        // width:3rem;
    }
}

.avatar-container:hover{
	@media screen and (min-width:$desktop){
		transform:scale(0.95) !important;
	}
}

.avatar_image{
    width:40px;
    max-width:none;
    @media screen and (max-width:$desktop){
        width:40px;
        //this will push a margin left of the body. Need more fine tuning for larger image.
    }
}

.notification_bell_btn{
	// background-color:none; 
	padding: 0rem;
    margin: 0rem;
    margin-top: auto;
    margin-bottom: auto;
    width: auto;
	@media screen and (max-width:$desktop){
        margin-right:0.2rem;
    }
}

.notification_bell_btn .my_icon{
	width:24px;
}

.notification_dot {
	height: 10px;
    width: 10px;
    right: 0%;
    top: 5%;
    background-color: $base-orange;
    border: 2px solid white;
    border-radius: 1rem;
    display: inline-block;
    @media screen and (max-width:$desktop){
       right:5%;
       top:5%;
       height:10px;
       width:10px;
    }
  }

.notification_pos_one{
    margin-right:0.6rem;
    @media screen and (max-width:$desktop){
        margin-right:0rem;
    }
}

.notification_pos_two{
    margin-right:0.4rem;
    @media screen and (max-width:$desktop){
        margin-right:0rem;
    }
}

.notification-text-container{
    width:100%;
    height:100%;
    display:flex;
}

.notification-text{
    color:$darkest-dark;
    font-size:small;
    font-weight:800;
    text-align: center;
    @media screen and (max-width:$desktop){
        font-size:10px;
    }
}

.top-border-notif{
	border-top:1px solid #787878;
}

.my-notification{
	padding-top:0.75rem !important;
	padding-bottom:0.75rem !important;
    margin-left:0px;
    margin-right:0px;
    margin-top:0px;
    margin-bottom:0.5rem;
}

.text-in-notification{
    width: fit-content;
    margin-bottom: auto !important;
    margin-top:auto;
    color:$darkest-dark !important;
}

.notification-icon{
    width:30px;
	height:30px;
    margin-right:1.25rem;
    margin-left:.5rem;
	margin-top:auto;
	margin-bottom:auto;
    @media screen and (max-width:$desktop){
        margin-right:1rem;
        margin-left:0rem;
    }
}

.notification-icon-sml{
	@extend .notification-icon;
	width: 28px;
    height: 28px;
    padding-left: 1px;
}

.notification-close{
    color:#b3b3b3;
    font-size:x-large;
    margin-top:auto;
    margin-bottom:auto;
    margin-right: 0.5rem !important;
    padding: 0px;
    padding-left: 0.75rem;
}

.notification-close:hover{
    color:#ff6557;
}

//Footer Stuff

.body-wrapper{
	background-color:$background-color;
	// height:100%; < - Breaks everything on chrome DO NOT DO THIS
	width:100%;
	@media screen and (max-width:$desktop){
        background-color:$background-color-mobile;
    }
}

.body-content{
	background: linear-gradient(180deg, #cdd6da 0px, transparent 60px);
	@media screen and (max-width:$desktop){
        background: none;
    }
}

.my-footer-is-cool{
	margin-top:auto;
	padding-bottom:1rem;
	// background-color: #d6dbdf;
	background: linear-gradient(90deg, #212425 2%, #292826 40%) !important;
    box-shadow: 1px 0px 2px 0px #4040407d;
	z-index:600;
	@media screen and (max-width:$desktop){
        background-color:#292826;
    }
}

.my-body{
	min-height:100vh;
	margin:0;
	display:flex;
	flex-direction: column;
	z-index:-2;
}

.my-white-ass-body{
	// background: linear-gradient(to bottom, #cdd6da, #e9eff1 1000px) !important;
	// background-color:#e9eff1 !important;
}

.footer_gap{
	gap:0.75rem;
	flex-wrap:wrap !important;
	@media screen and (max-width:$desktop){
        margin-right:0.75rem;
    }
}

.my-footer{
	padding-top:1rem;
	margin-left:1rem;
}

.my-footer p{
	font-size: 11px;
	color:#9e9e9e;
	@media screen and (max-width:$desktop){
        font-size: 10px;
		
    }
}

 

// Top Area ###########################################

.column-top-white{
    background-color: white;
    overflow: hidden;
}

.internal-t-img{
    width:100%;
    height:100%;
	border-radius: 0rem;
    object-fit: cover;
}

.t-img{
    // border-radius:20px / 30px;
    border-radius:0.25rem;
    margin-top: 1rem;
    margin-bottom: $margin-dt-1;
    margin-left:auto;
    margin-right:auto;
	position:relative;
	z-index:0;

	box-shadow: $cast-shadow;
	background: linear-gradient(90deg, #84b5d0 50%, #a8a8a8 70%, #d9b285 90%) !important;
    display:flex;
    align-items:center;
    justify-content:center;
}

.phoneClass .t-img{
	border-radius: 0rem;
	margin-bottom: 0rem;
	margin-top: 0rem;
	border:none !important;
	display:none !important;
}

.mountains{
	width: 100%; 
    position: absolute;
    top: 0px;
    border-radius:0px;
}

.sidebar_mountains{
	width:calc(100% - 260px) !important;
	left: 260px !important;
	@media screen and (max-width:$small-desktop){
		display:none;
	}
}

.mountains + .contain-container{
	margin-top:3rem;
}

.mountains .spaces-container{
	margin-top:3rem;
}

.mountains-avatar-page{
	width:100%;
	position:absolute;
	@media screen and (max-width:$tablet){
        display:none;
    }
}


.mountains_page{
	margin-top:3rem !important;
	border-radius:0.25rem 0.25rem 0rem 0rem;
	@media screen and (max-width:$tablet){
        margin-top: 0rem !important;
    }
}

.dc{
	display:contents;
}



// Because Edge browesr needs a default version before we do media query shit. 


.nav-accord{
	margin-bottom:$margin-dt-1;
}

.side-grouping-feed{
	width:100%;
	background: $col-grad;
}

.not-side-grouping-feed{
	width:100%;
	// @media screen and (max-width:$tablet){
    //     background-color:$back-grey;
    // }
}


.feedControlBack{
	width:100%;
    bottom: -1px;
}

.mobile-comment-back-grey .not-side-grouping-feed{
	background:#d6dde3;
	@media screen and (max-width:$tablet){
        background-color:$back-grey;
    }
}

.post-feed .width-in{
	width:100%;
}

.my-footer-is-cool .width-in{
	background:#00000000 !important;
	box-shadow:none;
}

.dis-notif-btn{
    padding-left:0px;
}

.nopad{
    padding-top:0 !important;
    padding-bottom:0 !important;
    padding-left: 4px;
    padding-right: 4px;
    @media screen and (max-width:$desktop){
        // padding-left: 2px;
        // padding-right: 2px;
    }
}

.seriously-nopad{
    padding:0px !important;
}

.fine-padding{
    padding-left:0.5rem;
    padding-right:0.5rem;
}

.contain-container{
    padding:0px;
    margin:0px;
    width:100%;
	z-index:1;
}

.btn:focus{
     box-shadow: none !important;
 }

.add-margins{
    margin-left:0.25rem;
    margin-right:0.25rem;
}

.no-margins{
    margin:0px !important;
}

.no-ital{
	font-style: normal !important;
}

.menu_icon{
    width:24px;
    @media screen and (max-width:$desktop){
        width:24px;
    }
}

.menu-margin-fix{
	@media screen and (max-width:$desktop){
		margin-right:-0.25rem;
	}
}

.menuNotSignedIn{
	margin-right:0.75rem !important;
}

.top_icon_color{
    filter:invert(24%) sepia(0%) saturate(1908%) hue-rotate(159deg) brightness(93%) contrast(87%);
}

.top_icon_color:hover{
    // filter: invert(43%) sepia(91%) saturate(1405%) hue-rotate(182deg) brightness(97%) contrast(93%);
	transform:scale(0.95);
}

.top_image{
    padding: 0;
    margin: 0;
    display:block;
}

.main-row {
    @extend .p-2;
    @extend .bg-dark;
}

.main-menu{
	@media screen and (max-width:$desktop){
		background-color: #6b6b6b !important;
	}
}

.main-top-menu{
	z-index:1000;
	position:relative;
	top: 2rem;
    right: 1rem;
    width: 400px;
    margin-left: auto;
	border-radius: 0.25rem;
    overflow: hidden;
	box-shadow:0px 1px 0px 0px #40404040;
	@media screen and (max-width:$tablet){
		top:0rem;
		right:0rem;
		width:100%;
		border-radius:0rem;
		box-shadow:none;
	}
}

.main-menu.my-accordion{
	background-color: #ffffff00;
	border-radius:0rem;
}

.main-menu .my-accordion{
	background-color:#00000000;
}

.main-menu-inner{
	gap:01px;
	display: flex;
    flex-direction: column;
	background-color:#eaeaea;
	@media screen and (max-width:$tablet){
		border-bottom:1px solid #eaeaea;
		border-top:1px solid #eaeaea;
	}
}

.accord-in-menu{
	gap:01px;
	display: flex;
    flex-direction: column;
}


.notif-container{
	gap:01px;
	display: flex;
    flex-direction: column;
	background-color:#eaeaea;
	@media screen and (max-width:$tablet){
		border-bottom:1px solid #eaeaea;
		border-top:1px solid #eaeaea;
	}
}

.notif-user-icon .big_wrapper{
	min-width: 50px;
    width: 50px;
    margin-right: 0.6rem;
    margin-left: -0.1rem;
	@media screen and (max-width:$desktop){
		margin-right: 0.3rem;
    	margin-left: -0.6rem;
	}
}

.feed_bar_my_class .my-accordion{
	margin-top:0px;
	margin-bottom:0px;
	border-bottom:1px solid #eaeaea;
	// @media screen and (max-width:$desktop){
	// 	margin-bottom:0rem;
	// 	margin-top:$margin-mobile-1;
	// }
}

.boop.my-accordion{
	margin-top:0rem;
	margin-bottom:0rem;
	// gap:1px;
	display:flex;
	flex-direction:column;
}

.boop.my-accordion.collapse:not(.show){
	display:none !important;
}

.my-accordion{
    border: none;
	background-color:$back-grey;
	border-radius:$common-border-radius;
}

.accordion-inner-gap-control{
	gap:0px;
	display:flex;
	flex-direction:column;
	border-radius: 0rem;
	overflow: hidden;
	box-shadow:$common-box-shadow;
	@media screen and (max-width:$desktop){
		box-shadow:$common-box-shadow-mobile;
		gap:0px;
	}
}


.no-top-border{
	border-top:none !important;
	// border-bottom:1px solid #eaeaea !important;
}


.my-accordion-button{
    display:block !important;
    border:none;
    @extend .accordion-button;
}

.my-accordion-button::after{
    @media screen and (max-width:$desktop){
        width:0.75rem;
        height:0.75rem;
        background-size:0.75rem;
    }
}

.push-left{
    margin-left:0px;
}

.menu-indent{
    margin-left: 2rem !important;
    width: calc(100% - 2rem) !important;
    @media screen and (max-width:$desktop){
        margin-left: 2rem !important;
        width: calc(100% - 2rem) !important;
        border-radius:0.1rem 0rem 0rem 0.1rem !important;
    }
}


//Format dropdown options from hamburger menu
.my-container{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left:1rem;
    padding-right:1rem;
    margin-left:0rem;
    margin-right: 0rem;
    width: 100%;
    margin-bottom: 0rem;
    background:white !important;
    color:$darkest-dark !important;
    border-radius: 0.1rem;

    @media screen and (max-width:$desktop){
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-left:1rem;
        padding-right:1rem;
        margin-bottom: 0rem;
        border-radius:0rem;
        box-shadow:none;
    }
}

.my-container.collapsed + .accordion{
	display:none;
}

.my-container:hover{
    text-decoration: none !important;
    // color: $base-blue !important;
    background-color:#e8e8e8 !important;
    box-shadow: none !important;
	transform:scale(0.99);
	@media screen and (max-width:$desktop){
		background-color:white !important;
	}
}

.menuWriting{
	color:$darkest-dark !important;
	font-size:16px;
	margin-bottom:0rem;
}

.lil_think_tank{
	position:absolute;
	display:flex;
	width: 16px;
    height: 16px;
    padding: 2px;
    padding-bottom: 4px;
    background-color: white;
    align-content: center;
    border-radius: 10px;
	bottom: -3px;
    right: 8px;
	@media screen and (max-width:$desktop){
		right:2px;
	}
}

.splitdropdown{
	padding:0rem !important;
}

.splitdropdown-left{
	width:calc(100% - 10rem);
	padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
	@media screen and (max-width:$desktop){
		width:calc(100% - 6rem);
	}
}

.splitdropdown-left:only-child{
	width:100%;
}

.splitdropdown-right{
	width:10rem;
	padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
	display:flex;
	@media screen and (max-width:$desktop){
		width:6rem;
	}
}

.splitdropdown-right:hover .filter-gray{
	filter: invert(42%) sepia(87%) saturate(1378%) hue-rotate(182deg) brightness(98%) contrast(90%) !important;
}

.splitdropdown-right:hover{
	transform: scale(0.97);
}

.splitdropdown-left:hover .menuWriting{
	color:whtie!important;
}


.col-menu-text{
    font-size: 15px;
    margin-right:1rem;
    font-weight: 600 !important;
	color:black;
    @media screen and (max-width:$desktop){
        font-size: 14px;
		font-weight: 600 !important;
    }
}

.hidden-text{
    margin-right:1.5rem;
    display:none;
}

.feed-buttons:hover .hidden-text{
    display:flex;
}



// Color Filters #############################################

.filter-green{
    filter: invert(51%) sepia(60%) saturate(3485%) hue-rotate(135deg) brightness(96%) contrast(101%);
}

.filter-red{
    filter: invert(50%) sepia(97%) saturate(2076%) hue-rotate(328deg) brightness(85%) contrast(97%);
    //1.4 loss
}

.filter-bright-blue{
	// filter:url(#texting-svg-gradient);
	filter: invert(42%) sepia(87%) saturate(1378%) hue-rotate(182deg) brightness(98%) contrast(90%);
}

// #testing-svg-gradient{
// 	color-interpolation-filters: sRGB;
//     background-image: radial-gradient(orange, red);
// }

.filter-blue{
    filter: invert(43%) sepia(91%) saturate(1405%) hue-rotate(182deg) brightness(97%) contrast(93%);

}

.filter-light-blue{
	filter:invert(78%) sepia(55%) saturate(4895%) hue-rotate(182deg) brightness(107%) contrast(91%);
}

.filter-dirty-blue{
    filter: invert(74%) sepia(50%) saturate(262%) hue-rotate(159deg) brightness(90%) contrast(87%);
}

.filter-dark-blue{
    filter: invert(11%) sepia(87%) saturate(1786%) hue-rotate(186deg) brightness(99%) contrast(99%);
}

.filter-orange{
    filter: invert(70%) sepia(76%) saturate(1833%) hue-rotate(341deg) brightness(98%) contrast(94%);

}

.filter-dirty-orange{
    filter: invert(76%) sepia(63%) saturate(286%) hue-rotate(339deg) brightness(94%) contrast(93%);
}

.filter-dark-orange{
	filter:  invert(12%) sepia(35%) saturate(4506%) hue-rotate(32deg) brightness(100%) contrast(103%);
}

.filter-purple{
    filter: invert(56%) sepia(99%) saturate(3122%) hue-rotate(226deg) brightness(105%) contrast(92%);
}

.filter-gray{
    filter: invert(55%) sepia(0%) saturate(0%) hue-rotate(252deg) brightness(100%) contrast(92%); //invert(54%) sepia(33%) saturate(289%) hue-rotate(160deg) brightness(90%) contrast(84%);
}

.filter-med-gray{
	filter: invert(62%) sepia(0%) saturate(3%) hue-rotate(175deg) brightness(93%) contrast(79%);
}

.filter-gray-icon{
	filter: invert(51%) sepia(0%) saturate(1%) hue-rotate(174deg) brightness(91%) contrast(90%);
}

.filter-light-gray{
    filter: invert(96%) sepia(2%) saturate(0%) hue-rotate(181deg) brightness(87%) contrast(88%);
}

.filter-blue-gray{
	filter: brightness(0) saturate(100%) invert(64%) sepia(16%) saturate(312%) hue-rotate(168deg) brightness(96%) contrast(96%);
}

.filter-white{
    filter: invert(99%) sepia(4%) saturate(1024%) hue-rotate(336deg) brightness(117%) contrast(100%);
}

.filter-dark-gray{
	filter: invert(24%) sepia(0%) saturate(1908%) hue-rotate(159deg) brightness(93%) contrast(87%);
}

.filter-see-topics-gray{
	filter:invert(56%) sepia(0%) saturate(1481%) hue-rotate(224deg) brightness(70%) contrast(67%);
}


.filter-darkest-dark{
	filter: invert(20%) sepia(15%) saturate(0%) hue-rotate(162deg) brightness(104%) contrast(101%);
}

.filter-light-red{
	filter:invert(61%) sepia(89%) saturate(4390%) hue-rotate(329deg) brightness(112%) contrast(104%);
}

.filter-light-orange{
	filter: invert(85%) sepia(37%) saturate(1246%) hue-rotate(317deg) brightness(102%) contrast(101%);
}

// .activeAccord{
// 	background-color:white !important;
// 	border-left: 2px solid #50a6e4 !important;
// 	border-right: 2px solid #50a6e4 !important;
//     padding-left: calc(1rem - 2px) !important;
// }

.activeAccord:hover{
	transform:scale(0.99) !important;
	cursor: default !important;
	border-radius:0rem !important;
}

// Feed Menu ###################################################


.continue-feed-button{
	@extend .feed-buttons;
	margin-left:auto;
	width:40% !important;
	display:flex;
	margin-top:apx;
	@media screen and (max-width:$desktop){
        border-radius:0.1rem 0rem 0rem 0.1rem !important;
    }
}

.checkbox-on-expandable{
	width:auto;
	height:inherit;
	display:flex;
	// padding-right:0.5rem;
}

.expanding-the-checkbox{
	padding-left:4rem;
	padding-right:0.5rem;
	@media screen and (max-width:$desktop){
		padding-left:3rem;
		padding-right:0.25rem;
    }
}

.more-options-menu{
	margin-right:auto;
	padding:0.5rem;
	width:100%;
	@media screen and (max-width:$desktop){
        padding-top:0.5rem;
		padding-bottom:0.5rem;
		padding-left:1rem;
		padding-right:0rem;
    }

}


.more-options-menu::before{
	content: "";
	display: inline-block;
	height:min-content;
	width:min-content;
	margin-top: auto;
    margin-bottom: auto;
    margin-left: 01rem;
	border-top: 0.3em solid #4d4d4d;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;

	vertical-align: 0.255em;
	margin-right: 0rem;

	transform: rotate(0deg);
	transition: transform 0.3s ease; /* Add animation */
	@media screen and (max-width:$desktop){
		margin-left: 0rem;
		margin-right:1rem;
	}
}

.collapsed.more-options-menu::before {
	transform: rotate(-90deg); /* Reset rotation when dropdown is open */
}

.menu-checkbox-container{
	width:max-content;
	margin-left:auto;
	margin-right:1rem;
	cursor:pointer;
}

.ms-auto + .menu-checkbox-container{
	margin-left:0px !important;
}

.menu-card{
    height:auto;
    width:100%;
    color: $darkest-dark !important;
    background-color: white;
	// box-shadow: -1px 0px 1px 0px #dcdcdc;
    border-radius: 0.1rem;
	display:flex !important;
    margin-left:0rem;
    margin-right: 0rem;
    margin-bottom: 0rem;
    justify-content: center;
    text-align: center;
	-webkit-appearance: none !important;
    @media screen and (max-width:$desktop){
        margin-bottom: 0rem;
        border-radius:0.1rem;
        padding-left:0px;
        padding-right:0px;
        box-shadow:none !important;
    }
}

.keep-in-line{
	margin-top: auto;
	margin-bottom:auto;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.menu-writing{
    align-content:center;
    font-size: 13px;
	z-index:3;
    font-weight: 400;
    font-family: poppins, noto sans, arial, sans-serif;
    margin-top:1.25rem;
    margin-bottom:0.75rem !important;
    @media screen and (max-width:$phone){
        font-size: 11px;
        margin-top:1.25rem;
        margin-bottom:0.5rem !important;
    }
}

.menu-writing-underline{
	height:0.27rem;
	width:65%;
	max-width:80px;
	background-color: #ababab;
	margin-bottom:1.25rem;
	margin-left:auto;
	margin-right:auto;
	border-radius:0.5rem;
	@media screen and (max-width:$desktop){
        height:0.25rem;
    }
}

.comment-feed-row .menu-writing-underline{
	margin-bottom:0rem;
	margin-top:0.75rem;
	max-width: 110px;
}

.menu-svg{
    width:60%;
    margin-top:0rem;
    padding:1rem;
    max-width:85px;
	min-height:60px;
	@media screen and (max-width:$desktop) and (min-width:$phone){
		width:70%;
	}
    @media screen and (max-width:$desktop){
        padding:0.2rem;
        margin-top:0.5rem;
		min-height:40px;
		width:50%;
    }
}

.menu-avatar{
	@extend .menu-svg;
}

.accordion-button.collapsed{
    background-color: white;
}


.button_bar_my_class .accordion-button.collapsed{
    @media screen and (max-width:$desktop){
		background-color: white;
	}
}

.accordion-button::after{
    display:none;
    position:absolute;
    top:6px;
    left:6px;
}

.accordion-button:hover{
	transform: scale(0.97);
	// border-radius: 1rem;
    @media screen and (max-width:$desktop){
		transform: scale(1);
        // background-color: $hover-grey !important;
        box-shadow:none !important;
		// border-radius:0rem;
    }
}

.accordion-button:focus{
    // border:none !important;
    background-color: white !important;
	box-shadow:none !important;

}

.accordion-button:active{
    background-color: white !important;
	box-shadow:none !important;

}

.accordion-button:not(.collapsed){
	background-color: white !important;
	box-shadow:none !important;
}

.phn-txt{
    @media screen and (max-width:$desktop){
        font-size: 14px;
    }
    // padding:0.1rem; breaks menus
}
// Feed ###################################################

.info-icon{
	width:25px;
	@media screen and (max-width:$desktop){
        width:20px;
    }
}

.accordion-info-button{
	margin-left:auto;
	display:flex;
}

.my-info-dropdown{
	box-shadow:  #a5a5a5 0px 0px 10px, inset #d0d0d0 1px -1px 4px !important;
}

.boop .feed-buttons{
	// border-radius:0.25rem 0rem 0rem 0.25rem;
	// @media screen and (max-width:$desktop){
    //     border-radius:0.25rem 0rem 0rem 0.25rem;
    // }
}

.side-item-menu-text{
	display:inline;
	font-weight:200;
}

.main-item-name{
	display:inline;
	font-weight:400;
}

.feed-menu-title-text{
	font-weight:400;
	margin-left:1rem;
	@media screen and (max-width:$desktop){
        margin-left:0rem;
    }
}

// #accordion_context .feed-menu-title-text{
// 	color: #009977; 
// }

// #filter_expand .feed-menu-title-text{
// 	color:#bf382a;
// }

// #accordion_categories .feed-menu-title-text{
// 	color:#1170b7;
// }

// #type_expand .feed-menu-title-text{
// 	color:#c77713;
// }

.feed-buttons{
    margin-bottom: 0rem;
    padding:0.5rem;
    color:black !important;
    background-color: white;
    text-align:start !important;
	min-height:50px;
    // border-color:#D1D1D1 !important;
    // border-width: 1px;
    // border-style: solid;
    border: none;
    border-radius: 0rem;
	cursor:pointer;
    width:100%;
    @media screen and (max-width:$desktop){
        margin-bottom:0rem;
        border-radius: 0rem;
        padding-top:0.5rem;
        padding-bottom:0.5rem;
        padding-left:1rem;
        padding-right:0.25rem;
        // border-top: $grey-border 1px solid;
    }
}

.feed-button-parent{
    margin-left:0.5rem;
    margin-right: 0.5rem;
}

.button-icons{
    width:20px;
	min-height:20px;
    height:auto;
    padding-top:2px;
    padding-bottom: 2px;
    margin-left: 15px;
    margin-right:15px;
    @media screen and (max-width:$desktop){
        width:20px;
		min-height:20px;
        height:auto;
        padding-top:4px;
        padding-bottom: 4px;
        margin-right:9px;
		margin-left:0px;
    }
}

.activeAccordCheckContainer{
	padding:0px;
	border-radius:1rem;
	height: 21px;
    width: 21px;
    margin-top: auto;
    margin-bottom: auto;
	display:flex;
	margin-left:auto;
	align-content:center;
	margin-right:0.5rem;
	@media screen and (max-width:$desktop){

	}
}

.activeAccordCheck{
	width:21px;
	height:21px;
	margin-left:auto;
	margin-right:auto;
	margin-top:auto;
	margin-bottom:auto;
	@media screen and (max-width:$desktop){
		padding-bottom:0px;
	}
}


#accordion_context .activeAccordCheckContainer{
	background-color: #00b58d; 
}

#filter_expand .activeAccordCheckContainer{
	background-color:#d65447;
}

#accordion_categories .activeAccordCheckContainer{
	background-color:$base-blue;
}

#type_expand .activeAccordCheckContainer{
	background-color:$base-orange;
}


.button-icons-large{
	@extend .button-icons;
	width:28px;
}

.dropdown-item-bottom-create{
	@extend .dropdown-item;
	padding:0.5rem 1rem;
}


@media screen and (min-width:$tablet){
	.fb-no-hover:hover{
		transform: scale(1) !important;
		cursor:default !important;
		border-radius:$common-border-radius !important;
	}
}

.fb-no-hover:active{
	transform: scale(1) !important;
}

@media screen and (min-width:$desktop){
	.feed-buttons:hover{
		transform: scale(0.98);
		border-radius:0.75rem;

	}
}

.feed-buttons:active{
	@media screen and (min-width:$desktop){
		transform:scale(0.98) !important;
	}
}

.lookAtMe{
	border: 5px solid yellow;
}

.feed-button-expanding-adjustment{
	padding:0rem !important;
}

.my-outer-post{
	background-color:white;
	border-radius: 0rem;
    margin-bottom:0px;
	border-bottom:1px solid #eaeaea;
    padding-left:0rem;
    padding-right:0rem;
	box-shadow:$common-box-shadow;
    width:auto;
    border:none;
}


.fixed-notice-area{
    z-index:650;
    position: fixed;
    margin-bottom:2rem;
    margin-right:2rem;
    bottom: 0px;
    right: 0px;
    width: 350px;
	border-radius:1rem;
	// box-shadow:#dfdfdf inset 2px -2px 5px 0px, #838383 -2px 2px 10px;
	@media screen and (max-width:$desktop){
        margin-right:0.5rem;
		margin-left:0.5rem;
		margin-bottom:0.5rem;
		width: calc(100% - 1rem) !important;
    }
}

.fixed-notice-area-two{
    @extend .fixed-notice-area;
    width:250px;
	height:auto;
}

.product-notice-close-button{
	background-color:$base-orange;
	margin-top:0.5rem;
}

.product-notice-close-button:hover{
	background-color:$bright-orange;
}

.product_notif_question_box{
	width:150px;
	height:150px;
	margin-left:auto;
	margin-right:auto;
}

.product_space{
	box-shadow:#dddddd -1px 1px 1px 1px;
}

.product-stage{
	width:100%;
	height:100%;
	display:flex;
}

.product-notif-stage-one{
	margin-top:auto;
	margin-bottom:auto;
}

.product-notif-stage-one:hover{
	cursor:pointer;
	transform:scale(0.97);
}

.product-notif-stage-two{
	display:none;
	margin-top:auto;
	margin-bottom:auto;
}

.imgNotif-stage-two .product-notif-stage-one{
	display:none !important;
}

.imgNotif-stage-two .product-notif-stage-two{
	display:flex !important;
}

// Problem with box shadows is that they show up on sign in process as well, and do not look good there. 

.alert-success{
    background-color:#43ceae;
    border:none;
    // box-shadow:#878787 -2px 2px 10px 3px, inset #87878738 1px -1px 2px 3px;
    padding: 1rem;
    color: $darkest-dark;
}

.alert-danger{
    background-color:#ff6557;
    border:none;
    // box-shadow:#878787 -2px 2px 10px 3px, inset #87878738 1px -1px 2px 3px;
    padding: 1rem;
    color: $darkest-dark;
}

.alert-neutral{
    background-color:#ffffff;
    border:none;
    border-radius:1rem;
    // box-shadow:#878787 -2px 2px 10px 3px, inset #87878738 1px -1px 2px 3px;
    padding: 2rem;
    color: $darkest-dark;

}

.notice_area{
    // padding:0.5rem; when empty allows you to see notice area. 
}

.w-3rem{
    width:3em;
}

.center-text{
    text-align:center;
}
//##########################################################################################################
// #########################################################################################################
//###########################################################################################################

.icon-color-no-hover{
	filter:invert(54%) sepia(0%) saturate(1%) hue-rotate(196deg) brightness(93%) contrast(101%);
}

.icon-color{
	filter:invert(31%) sepia(0%) saturate(1760%) hue-rotate(139deg) brightness(102%) contrast(85%);
}

.icon-color-two{
    filter: invert(12%) sepia(54%) saturate(0%) hue-rotate(291deg) brightness(91%) contrast(90%);
}

.icon-color:hover{
	cursor:pointer;
	transform: scale(0.95);
	filter: invert(19%) sepia(1%) saturate(0%) hue-rotate(14deg) brightness(95%) contrast(92%);
}


.dark-icon-color{
    filter: invert(21%) sepia(0%) saturate(102%) hue-rotate(163deg) brightness(95%) contrast(84%);
}

$base-gray:#93aebd;
$base-gray-grad:  #90ACBB;
$base-blue-grad:  #84BAD7;
$base-orange-grad:  #E7BA84;
$light-blue: #ecf4f8; //#e6f0f5; // #eaf1f4;
$light-orange: #fff8f0; //#f5efe7; // #F4F1ED;
$light-grey: #fcfcfc; //#f1f5f6;
$base-red: #d5958d;

//##########################################################################################################

.grey-text{
	color:#7f7f7f !important;
}

// .selected-box-champ .comp-vote-container{
// 	background-color:$base-orange
// }

// .selected-box-rival .comp-vote-container{
// 	background-color:$base-blue
// }

.comp-vote-container{
	// background-color:white;
	border-radius:1rem;
	width:12px;
	height:12px;
	display:flex;
	margin-top:auto;
	margin-bottom:auto;
	margin-right: 5px;
    margin-left: -5px;
	@media screen and (max-width:$desktop){
		width:12px;
		height:12px;
		margin-left:-2px;
	}
}

.comp-vote-img{
	width:12px;
	height:12px;
	margin-top:auto;
	margin-bottom:auto;
	margin-left:auto;
	margin-right:auto;
	@media screen and (max-width:$desktop){
		width:12px;
		height:12px;
	}

}

.comp-vote-container-rival{
	@extend .comp-vote-container;
	display:none;
	margin-right:0px;
}

.comp-vote-container-champ{
	@extend .comp-vote-container;
	display:none;
	margin-right:0px;
}

.for_vote .comp-vote-container-rival{
	display:flex !important;
}

.against_vote .comp-vote-container-champ{
	display:flex !important;
}

.comp-vote-text{
    // color: #e9cdaa;
    color: $darkest-dark;
    // margin-top:-2px;
    margin-bottom:0px !important;
    text-align:center;
	vertical-align: middle;
    font-size: 9px;
    font-weight: 400;
    cursor: default;
	line-height:16px;
	@media screen and (max-width:$desktop){
		font-size: 9px;
	}

}

.post-style{
	padding:0rem;
    @media screen and (max-width:$desktop){
        padding:0rem;
        border-radius: 0.5rem !important;
    }
}

.post-header-row{
	padding-right:0rem;
	padding-left:0rem;
	padding-top:1.25rem;
	padding-bottom: 0.5rem;
	@media screen and (max-width:$desktop){
        padding:0rem;
		padding-left:0rem;
		padding-top:1.25rem;
		padding-bottom: 0.25rem;
		// padding-right:0.5rem;
    }
}



.post-footer-row{
	padding-right:1.5rem;
	padding-left:1.5rem;
	padding-top:0.5rem;
	padding-bottom:1.25rem;
	border-bottom: 1px solid #eaeaea;
	margin-top:0px !important;
	@media screen and (max-width:$desktop){
        padding:1.25rem;
		padding-left:1.25rem;
		padding-right:1.1rem;
		padding-top:0.25rem;
    }
}

.is_clickable{
    text-decoration: none !important;
    color:$darkest-dark !important;
    width: 100%;
    height:fit-content;
    display:block;
    padding-left:1.5rem;
    padding-right:1.5rem;
    position:relative;
	margin-top:-0rem;
    padding-top: 0rem;
    padding-bottom: 0.25rem;
	margin-bottom:0rem;
	@media screen and (max-width:$desktop){
        padding:1.25rem;
		padding-top:0rem;
		padding-bottom:0rem;
		left:0;
		width:100%;
    }


}

.is_clickable:hover{
	cursor: pointer;
	transform:scale(0.999);
}

///////////////// SYYYYKE
/// 
/// 

.sideBySide{
	display:flex;
	flex-direction:row-reverse;
	flex-wrap: nowrap;
}


.sideBySideSyke{
	flex-direction:column-reverse !important;
}

.sideBySideSyke .post_card_text_short{
	width:100% !important;
}

.sideBySideSyke .post_card_text_tiny{
	width:100% !important;
}

.sideBySideSyke .post_card_img_container{
	width:100% !important;
	margin-left:0rem !important;
	height:auto !important;
	margin-top:0.75rem !important;
}

.sideBySideSyke.sideBySide .post_card_img_container{
	margin-top:1rem !important;
}


.m-sc-pc-mainSide .sideBySide{
	flex-direction: row;
}


.floating_voting_row + .post_card_img_container{
	margin-top:0rem;
}

.sideBySide .post_card_img_container{
	// background-color: blue;
	margin-top:-22px;
    width: 150px;
    height: 190px;
	margin-bottom:0rem;
	margin-left:1.5rem;
	@media screen and (max-width:$desktop) and (min-width:$tablet) {
		margin-top:-22px;
		width: 140px;
		height: 180px;
		margin-left:1rem;
		float:right;
	}
	@media screen and (max-width:$tablet) and (min-width:$phone){
		margin-top:-22px;
		width: 115px;
		height: 145px;
		margin-left:0.75rem;
		float:right;
	}
	@media screen and (max-width:$phone){
		margin-top:-22px;
		width: 115px;
		height: 135px;
		margin-left:0.75rem;
		padding-top:0px;
		float:right;
		margin-bottom:0.5rem !important;
	}
}

.hasSideImage{
	width:calc(100% - 160px);
	@media screen and (max-width:$desktop) and (min-width:$tablet) {
		width: calc(100% - 150px);
	}
	@media screen and (max-width:$tablet) and (min-width:$phone){
		width: calc(100% - 125px);
	}
	@media screen and (max-width:$phone){

		width: calc(100% - 125px);

	}
}

.sideBySide .post_card_text_short{
	width:calc(100% - 150px);
	height:min-content;
	// @media screen and (max-width:$desktop){
	// 	width:100%;
	// }
	@media screen and (max-width:$desktop) and (min-width:$tablet) {
		width:calc(100% - 140px);
	}
	@media screen and (max-width:$tablet) and (min-width:$phone){
		width:calc(100% - 115px);
	}
	@media screen and (max-width:$phone){
		width:calc(100% - 115px);
	}
}

.sideBySide .post_card_text_tiny{
	width:calc(100% - 150px);
	height:min-content;
	// @media screen and (max-width:$desktop){
	// 	width:100%;
	// }
	@media screen and (max-width:$desktop) and (min-width:$tablet) {
		width:calc(100% - 140px);
	}
	@media screen and (max-width:$tablet) and (min-width:$phone){
		width:calc(100% - 115px);
	}
	@media screen and (max-width:$phone){
		width:calc(100% - 115px);
	}
}

.sideBySide .post_card_img{
	width:100%;
	height:100%;
	object-fit: cover;
	min-height:50px !important;
}

.m-sc-pc-mainSide .sideBySide .post_card_img_container{
	height:114px;
}

.merica_img{
	height:150px !important;
}


.kitty_img{
	height:130px !important;
}

.sideBySide .post_card_video_container{
	margin-top:-20px !important;
    width: 150px;
    height: 150px;
	min-height:80px !important;
	margin-bottom:0rem !important;
	margin-left:1rem;
	@media screen and (max-width:$desktop) and (min-width:$tablet) {
		margin-top:0rem !important;
		width: 140px;
		height: 140px !important;
		margin-left:1rem;
	}
	@media screen and (max-width:$tablet) and (min-width:$phone){
		margin-top:0rem !important;
		width: 110px;
		height: 110px !important;
		margin-left:0.5rem;
	}
	@media screen and (max-width:$phone){
		margin-top:0rem !important;
		width: 110px;
		height: 110px !important;
		margin-left:0.5rem;
	}
}

.sideBySide .post_card_video{
	width:100%;
	height:100%;
	object-fit: cover;
	min-height:50px !important;
}

.img_replaced_video_container{
	position:relative;
	display:inline-block;
}

.img_replaced_video_container::before{
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 0;
	height: 0;
	border-style: solid;
	z-index:30;
	border-radius:1px;
	border-width: 20px 0 20px 35px;
	border-color: transparent transparent transparent #f1f1f1;
	cursor: pointer;
}

.img_replaced_video_container:hover::before{
	border-width: 19px 0 19px 33px;
}

.media_expand_area{
	// width: 1000px;
    // height: 562px;
	width: calc(100vw - 12rem);
    height: calc(100vh - 12rem);
    overflow-y: clip;
    background-color: #1c1c1c;
    border-radius: 1rem;
	@media screen and (max-width:$desktop){
		width: calc(100vw - 6rem);
    	height: calc(100vh - 6rem);
	}
}

.media_expand_area .post_video_container{
	width:100% !important;
	height:100% !important;
	max-height:none !important;
	margin-top:0rem !important;
	margin-bottom:0rem !important;
}

.media_expand_area .post_video{
	width:100% !important;
	height:100% !important;
	max-height:none !important;
	margin-top:0rem !important;
	margin-bottom:0rem !important;
}

.ytp-large-play-button{
	@media screen and (max-width:$phone){
		width:38px !important;
		height:30px !important;
	}
}

.side-col{
    width:45px;
	padding-left:0.333rem;
	height:auto;
	min-height:130px;
	border-radius: 0rem !important;
    @media screen and (max-width:$desktop){
		display:none;
        width:40px;
		padding-left:0.2rem;
		height:auto;
        // border-bottom: $mid-grey-border 1px solid !important;
        // border-top: $mid-grey-border 1px solid !important;
    }
}

.desktop .side-col{
	@media screen and (max-width:$desktop){
        width:45px;
		height:100%;
		// margin-right:1px;
		// margin-left:0rem !important;
		border-radius: 0.1rem 0rem 0rem 0.1rem !important;
    }
}

.desktop + .main-col{
	@media screen and (max-width:$desktop){
		border-radius: 0rem 0.1rem 0.1rem 0rem !important;
    }
}

.total_votes{
	color: $darkest-dark;
    font-size: 10px;
    font-weight: 400 !important;
}

.side-col .total_votes{
    margin-top: 0px;
    margin-bottom: 0px;
	color: #5f5f5f;
	
}

.side-col-two{
    width:50px;
    margin-left:0.5rem;
}

.side-col-two .total_votes{
    margin-top: 0px;
    margin-bottom: -2px;
}


.main-col{
    width: 100% !important;
	// box-shadow: -1px 0px 1px 0px #dbdbdb;
    // border-radius:$common-border-radius-right !important;
    @media screen and (max-width:$desktop){
		width: calc(100%)!important;
		// border-radius:$common-border-radius-left-mobile!important;
        // border-bottom: $grey-border 1px solid !important;
        // border-top: $grey-border 1px solid !important;
    }
}

.side-col .big_wrapper{
	width:48px;
	min-width:48px;
	margin-left:-1px;
	margin-top:0.6rem;
	 @media screen and (max-width:$desktop){
		width: 40px;
		min-width:40px;
		margin-left:3px;
		margin-top:0.4rem;
    }
}

.center-stuff{
    text-align: center;
	margin-top: auto;
    margin-bottom: auto;
	margin-left:auto;
	margin-right:auto;
}

.visOnVert{
	display:block;
}

.visOnHorz{
	display:none;
}

.horz-vote-area .visOnHorz{
	display:flex;
}

.horz-vote-area .visOnVert{
	display:none;
}

.liquid-wave{
	width:20px;
	z-index:2;
	@media screen and (max-width:$desktop){
		width:18px;
	}
}

.liquid-wave-back{
	position:absolute;
	width:20px;
	z-index:1;
	@media screen and (max-width:$desktop){
		width:18px;
	}
}

.liquid_drops{
	max-width:400px;
}

.liquid_content_col{
	width:calc(100% - 50px);
}

.liquid_user_col{
	width:50px;
}

.liquid_user_col .big_wrapper{
	height: 51px;
    width: 51px;
    margin-left: -10px;
    margin-right: 5px;
    margin-bottom: auto;
    margin-top: -5px;
}

.liquid_user_col .irl.big_wrapper{
	height:30px;
	width:30px;
	margin-left: 0px;
    margin-bottom: auto;
    margin-top: 5px;
}

.liquidBtn{
	margin-top:1rem;
	width: fit-content;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    margin-left: auto;
    background-color: white;
}

.liquidBtn.noRep{
	border: 1px solid $base-blue;
	border-radius:0.5rem;
}

.liquidBtn.rep{
	border: 1px solid $base-orange;
	border-radius:0.5rem;
}

.liquid_check{
	width:20px;
}

.liquid_text{
	text-align: start;
}

.col_ddd{
	margin-left:0rem !important;
}

.heart-pos{
	width: 20px;
    // margin-left: -3px;
    // margin-top: -8px;
}

.poop-pos{
	width: 20px;
    // margin-left: -3px;
    // margin-top: -10px;
}



.heart-icon-top{
	position:relative;
	z-index:2;
}



.heart-icon-bottom{
	position: absolute;
	top:50%;
	transform: translate(-50%, -50%);
    z-index: 1;
	width: 100%;
    left: 50%;
}

.horz-vote-area .hp-icons{
	width:17px;
	@media screen and (max-width:$desktop){
		width:15px;
	}
}

.floating-bottom-container .hp-icons{
	width:18px;
}

.hp-icons-side{
	width:22px;
	height:22px;
}

.hp-icons-side .heart-icon-bottom{
	top:12px;
}

.hp-dsa-btn{
	width:max-content;
	padding:0rem;
}

.hp-row{
	justify-content: space-between;
}

.horz-vote-area .center-stuff{
	margin-right:0.2rem;
	margin-top:1px;
}

.horz-vote-area .my-flex-col{
	flex-direction: row !important;
}

.horz-vote-area .heart-pos{
	width:auto;
}

.horz-vote-area .poop-pos{
	width:auto;
}

.vote_top{
	// gap:0.3rem !important;
	border-radius:1rem;
    margin-left: auto;
    margin-right: auto;
	margin-top:1.25rem;
	padding:0.5rem;
	padding-top:0.1rem;
	padding-bottom:0.1rem;
}

.vote_top:hover{
	background-color: #f8f8f8;
	transform: scale(0.97);
}

.floating-bottom-container .vote_top{
	padding-top:0.15rem;
	padding-bottom:0.15rem;
}


.horz-vote-area{
	position: relative;
	margin-top: auto;
    margin-bottom: 0px;
}

.horz-vote-area .vote_top{
    margin-top: auto;
    margin-bottom: auto;
}


.vote_top .icon:hover{
	cursor:pointer;
}

.comment-icon{
    padding-top:0px;
    padding-bottom:0px;
    padding-left:0px;
    padding-right:0px;
    width:25px;
	margin-right:auto;
	margin-left:auto;
	@media screen and (max-width:$desktop){
		width:26px;
		}
}

.comment .comment-icon{
    padding-top:0.8rem;
}

.sticky-create-post-row{
	position:sticky;
	pointer-events:none;
	bottom:0rem;
	justify-content: end;
	z-index:999;
	margin-bottom: 1px;
}

.sticky-create-post{
	background-color: white;
	pointer-events:all;
	width: 55px;
	height:55px;
	padding:0rem;
	border-radius:4rem;
	margin-left:auto;
	margin-right:1rem;
	margin-top:1rem;
	margin-bottom:1rem;
	display:flex;
	box-shadow: -1px 1px 2px 0px #949494;
}

.sticky-create-post:hover{
	transform:scale(0.94);
	box-shadow: -1px 1px 4px 0px #b1b1b1;
	// background-color:#eaeaea;
	cursor: pointer;
}

.sticky-create-post-row .tiny-comment-container{
	margin-top: -0.25rem;
    border: solid 1px #959595;
    border-radius: 1rem;
}

.sticky-create-post-row .ccc-row{
	border-radius: 1rem 1rem 0rem 0rem;
}

.sticky-create-post-row .mf_editor{
	border-radius: 0rem 0rem 1rem 1rem;
}

// .sticky-create-post:hover .create-post-btn{
// 	@extend .filter-orange;
// }

.create-post-btn{
	width:35px;
	height:35px;
}

.create-post-dropdown-mf{
	background-color:white;
	pointer-events: all;
	border-radius: 1rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    box-shadow: -1px 1px 2px 0px #949494;
}

///// animate votes
/// 
///  transform: translate(-50%, -50%);


@keyframes heart-animation-upvote{
	0%   {opacity: 100%; left:50%; bottom:0px; transform: translate(-50%, 0%);}
	75%  {opacity: 100%; left:50%; bottom:-5px; transform: translate(-50%, 0%);}
	100% {opacity: 100%; left:50%; bottom:0px; transform: translate(-50%, 0%);}
}

@keyframes poop-animation-downvote{
	0%   {opacity: 100%; left:50%; bottom:0px; transform: translate(-50%, 0%);}
	25%   {opacity: 100%; left:50%; bottom:0px; transform: translate(-50%, 0%);}
	75%  {opacity: 100%; left:50%; bottom:5px; transform: translate(-50%, 0%);}
	100% {opacity: 100%; left:50%; bottom:0px; transform: translate(-50%, 0%);}
}

@keyframes heart-animation-reset{
	0%   {opacity: 100%; left:50%; bottom:0px; transform: translate(-50%, 0%);}
	100% {opacity: 100%; left:50%; bottom:0px; transform: translate(-50%, 0%);}
}

@keyframes heart-animation-upvote-horz{
	0%   {opacity: 100%; transform: translateX(0px);}
	75%  {opacity: 100%; transform: translateX(-5px);}
	100% {opacity: 100%; transform: translateX(0px);}
}

@keyframes poop-animation-downvote-horz{
	0%   {opacity: 100%; transform: translateX(0px);}
	25%   {opacity: 100%; transform: translateX(0px);}
	75%  {opacity: 100%; transform: translateX(-5px);}
	100% {opacity: 100%; transform: translateX(0px);}
}

@keyframes heart-animation-reset-horz{
	0%   {opacity: 100%; transform: translateX(0px);}
	100% {opacity: 100%; transform: translateX(0px);}
}

@keyframes vote-animation-up {
	0%   {opacity: 100%; left:50%; bottom:0px; transform: translate(-50%, 0%);}
	100% {opacity: 0%; left:50%; bottom:200px; transform: translate(-50%, 0%);}
}

@keyframes vote-animation-down {
	0%   {opacity: 100%; left:50%; top:0px; transform: translate(-50%, 0%);}
	100% {opacity: 0%; left:50%; top:200px; transform: translate(-50%, 0%);}
}

@keyframes vote-animation-up-horz {
	0%   {opacity: 100%; left:30px; bottom:30px; transform: translate(-50%, 0%);}
	100% {opacity: 0%; left:30px; bottom:200px; transform: translate(-50%, 0%);}
}

@keyframes vote-animation-down-horz {
	0%   {opacity: 100%; left:-15px; top:25px; transform: translate(-50%, 0%);}
	100% {opacity: 0%; left:-15px; top:200px; transform: translate(-50%, 0%);}
}

.heart-upvote{
	animation-name: heart-animation-upvote;
	animation-duration:0.3s;
}


.poop-downvote{
	animation-name: poop-animation-downvote;
	animation-duration:0.3s;
	animation-timing-function: ease-in;
}

.heart-reset{
	animation-name: heart-animation-reset;
	animation-duration:0.3s;
	animation-timing-function: ease-in-out;
}

.horz-vote-area .heart-upvote{
	animation-name: heart-animation-upvote-horz;
	animation-duration:0.3s;
	animation-timing-function: ease-in-out;
}

.horz-vote-area .poop-downvote{
	animation-name: poop-animation-downvote-horz;
	animation-duration:0.3s;
	animation-timing-function:linear;
}

.horz-vote-area .heart-reset{
	animation-name: heart-animation-reset-horz;
	animation-duration:0.3s;
	animation-timing-function: ease-in-out;
}

.animate-start{
	// width:100%;
}

.prev-num .delayAndDisappear{
	display:flex;
}

.prev-num .delayAndAppear{
	display:none;
}

.next-num .delayAndDisappear{
	display:none
}

.next-num .delayAndAppear{
	display:flex;
}
// .ddd-accord-row .animate-start{
// 	width:0%;
// }

.vote-animate-container-up{
	background:white;
	box-shadow:-2px 2px 1px 0px #40404056;
	border-radius:2rem;
	padding: 0.5rem;
	padding-left:0.75rem;
	padding-right:0.75rem;
    z-index: 10;
	width:max-content;
	opacity:0%;

	animation-name: vote-animation-up;
	animation-duration:4s;
	animation-delay:0.5s;
}

.vote-animate-container-down{
	@extend .vote-animate-container-up;
	animation-name: vote-animation-down;
}

.horz-vote-area .vote-animate-container-up{
	animation-name: vote-animation-up-horz;
}

.horz-vote-area .vote-animate-container-down{
	animation-name: vote-animation-down-horz;
}

.animate-star{
	margin-left:0.25rem;
	width:20px;
	height:20px;
}

.bottom-set{
	gap:0.3rem
}

.product_notification{
	margin-top:auto;
	margin-bottom:auto;
}

// inner post summary #######################################

.justify-content-right{
	justify-content: end;
}

.side-col-champ-rival-container{
	display:flex;
	margin-top: auto;
    margin-bottom: auto;
}


.floating-bottom-row .votes-column{
	padding-top:0px;

}

.votes-column{

	margin-top:auto;
	margin-bottom:-5px;
	gap:0.25rem;
	// margin-right:0.5rem;
}

.post-card-vote-capsule{
	margin-right:0.5rem;
}

.post-card-vote-capsule .vote-capsule{
	padding-top:0rem;
	padding-bottom:0rem;
}

.vote-capsule{
	margin-left:auto;
	margin-right:auto;
	border-radius:1rem;
	overflow:hidden;
	padding-top:0.25rem;
	padding-bottom:0.25rem;
	padding-left:0.75rem;
	padding-right:0.75rem;
	@media screen and (max-width:$desktop){
        padding-left:0.5rem;
		padding-right:0.5rem;
    }
}


.vote-capsule-no{
	border:1px solid #bdbdbd;
	gap:0.25rem;
}

.vote-capsule-yes{
	border:1px solid #bdbdbd;
	gap:0.25rem;
}

.against_vote .vote-capsule-no{
	// background-color:#ffefdd;
	border: 1px solid #ed9f40;
	
}

.for_vote .vote-capsule-yes{
	// background-color:#ddefff;
	border: 1px solid $base-blue;
}

.vote-capsule .voteDot{
	margin-top: auto;
  	margin-bottom: auto;
}

.for_vote .voteDot{
	display:none;
}

.for_vote .voteDotChamp{
	display:inline-block;
}

.against_vote .voteDot{
	display:inline-block;
}

.against_vote .voteDotChamp{
	display:none;
}

// .against_vote .vote-capsule-yes{
	
// }

// .for_vote .vote-capsule-no{
	
	
// }

// .vote-capsule-no{
// 	background-color:#ffd099;
// 	gap:0.25rem;
// }

// .vote-capsule-yes{
// 	background-color:#a3d8ff;
// 	gap:0.25rem;
// }

// .against_vote .vote-capsule-no{
// 	outline: 2px solid #ed9f40;
// }

// .for_vote .vote-capsule-yes{
// 	outline:2px solid #50a6e4;
// }

// is blue now lol
.green-bar{
	background-color:#b0d8f0;
	padding-top:0.05rem;
	padding-bottom:0.05rem;
	padding-left:0.5rem;
	padding-right:0.75rem;
}
// is orange now lol
.red-bar{
	background-color:#f5d4ac;
	padding-top:0.05rem;
	padding-bottom:0.05rem;
	padding-left:0.5rem;
	padding-right:0.75rem;
}

.gray-bar{
	background-color:#eaeaea;
	padding-top:0.05rem;
	padding-bottom:0.05rem;
	padding-left:0.75rem;
	padding-right:0.5rem;
}

.votes-column.selectable:hover{
	cursor:pointer !important;
}

.votes-column.selectable:hover .comp-vote-text{
	color:$darkest-dark;
}

.selectable .comp-vote-text:hover{
	cursor:pointer !important;
}

.votes-row{
	width:auto;
	gap:0.5rem;
	margin-left:0rem;
	margin-top: auto;
    margin-bottom: auto;
}

.post-type-text{
    text-align: center;
    font-size: 11px;
    margin-top:0px;
    font-weight:600;
	margin-bottom:0px;
    @media screen and (max-width:$desktop){
        font-size:11px;
        margin-top:0px;
    }
}

.link-option-card{
	padding:1rem;
	width:100%;
	padding-left:1.5rem;
	padding-right:1.5rem;
	background-color:white;
	border-radius:0rem;
	align-content: left;
	margin-top:2px;
	text-align:start;
	color: $darkest-dark !important;
	@media screen and (max-width:$desktop){
        border-radius:0.1rem;
    }
}

.link-option-card:first-child{
	margin-top:0rem;
}

.link-option-card:hover{
	@media screen and (min-width:$desktop){
		transform:scale(0.98) !important;
	}
}

.follow-text{
    font-size: 11px;
    font-weight: 400;
    margin-top: auto;
    margin-bottom: auto;
    padding:0px;
    @media screen and (max-width:$desktop){
        font-size:11px;
        // padding-left:3px;
		// margin-right:5px;
    }
}

.follow-text-right{
	padding-left: 2px !important;
}

.follow-text-left{
	padding-right: 2px !important;
}

.light-orange-text{
    color:$base-orange !important;
}

.lil_wrapper .follow-icon{
	width:auto !important;
}


.follow-icon{
    width:18px;
    padding-left:2px;
    padding-right:2px;
    margin-top: auto;
    margin-bottom: auto;
    @media screen and (max-width:$desktop){
        width:20px;
        padding-left:3px;
    }
}

.topic-follow-icon{
	width:24px;
	margin-left:0.5rem;
	padding-left:2px;
    padding-right:2px;
    margin-top: auto;
    margin-bottom: auto;
}

.user-name-post-page{
	margin-left:1.5rem !important;
}

.user-name-post-page-actual{
	font-size:16px;
}

.user-name-post-page .follow-button{
	padding-left:4px;
	margin-left:-4px;
}

.user-name-post-page p{
	font-size: 11px !important;
}

.follow-button{
    text-align: start imp !important;
    float:left;
    padding:0px;
	margin-top:0px;
    border:none;
    display:flex;
    padding-bottom:1px;
}

/// Follow button animation
/// 

@keyframes follow-animationy {
	0%   {transform: scale(1) rotate(0deg);}
	25%  {transform: scale(1.05) rotate(5deg);}
	50%  {transform: scale(1.05)  rotate(-5deg);}
	75%  {transform: scale(1.05) rotate(5deg);}
	100% {transform: scale(1) rotate(0deg);}
	
}

@keyframes unfollow-animationy {
	0%   {transform: scale(1);}
	25%  {transform: scale(0.9);}
	50%  {transform: scale(0.9);}
	75%  {transform: scale(0.9);}
	100% {transform: scale(1);}
}

@keyframes small-icon-follow-animationy {
	0%   {transform: scale(1) rotate(0deg);}
	25%  {transform: scale(1.3) rotate(10deg);}
	50%  {transform: scale(1.3)  rotate(-10deg);}
	75%  {transform: scale(1.3) rotate(10deg);}
	100% {transform: scale(1) rotate(0deg);}
	
}

@keyframes small-icon-unfollow-animationy {
	0%   {transform: scale(1);}
	25%  {transform: scale(0.8);}
	50%  {transform: scale(0.8);}
	75%  {transform: scale(0.8);}
	100% {transform: scale(1);}
}

.follow-animation{
	animation-name: follow-animationy;
	animation-duration:1s;
}

.unfollow-animation{
	animation-name: unfollow-animationy;
	animation-duration:0.75s;
}

.follow-animation1{
	animation-name: small-icon-follow-animationy;
	animation-duration:1s;
}

.unfollow-animation1{
	animation-name: small-icon-unfollow-animationy;
	animation-duration:0.75s;
}


.front-view-title-2 .follow-button{
	float: inherit!important;
	// display: inline-block !important;
	// margin-left:0px !important;
	height:min-content;
	padding:0.25rem !important;
	margin-left:-0.25rem;
	margin-right:auto;
	width:min-content;
}

.front-postcard-gray-box{
	padding:0px;
	gap:1rem;
	min-height:18px;
	margin-right:auto;
	margin-top:auto;
	margin-left:0.2rem;
	margin-bottom:0.2rem;
	width:auto;
	border-radius: 2rem;
}

.phoneVersion .front-postcard-gray-box{
	bottom:0;
	left:0;
	margin-right: auto;
	margin-left:0rem;
	box-shadow:none;
}


.front-postcard-numbers{
	color:#767676;
	margin-bottom:0rem !important;
	font-size: 11px;
	line-height:1.7;
}

.front-postcard-text{
	margin-bottom:0.5rem;
}

.front-postcard-infogroup{
	gap:0.25rem;
}

.front-postcard-image{
	width:auto;
	height:13px;
	margin-top:auto;
	margin-bottom:auto;
}

.follow-button:hover{
    background-color: $hover-grey;
	@media screen and (max-width:$desktop){
        background-color:#00000000;
    }
}

.post-title{
    // margin-top: 0.5rem;
	// font-size:24px !important;
    margin-bottom: 0.25rem;
	line-height:1.3;
	letter-spacing: -0.25px;
    font-weight: 600;
	color:#404040 !important;
	text-transform: capitalize;
	// font-family: "Playfair", "Helvetica";
    @media screen and (max-width:$desktop){
        // font-size:18px;
        font-weight:600;
        margin-bottom: 0.1rem;
    }
}

.v-string{
	font-weight:200 !important;
	font-size: 15px !important;
	margin-left:0.25rem;
	@media screen and (max-width:$desktop){
	    font-size:14px;
	}
}


.link-text{
    font-size: 10px;
	font-weight:400;
	// font-style:italic;
    line-height: 5px !important;
	margin-right:4px;
	margin-top:auto;
	margin-bottom:auto;
	display:inline !important;
	@media screen and (max-width:$desktop){
	    font-size:10px;
		margin-top:auto;
		margin-bottom:auto;
	}
}

b.link-text{
	font-weight:600;
}

.weird-link-text-bug-fix .link-text{
	margin-right:0rem;
}

.post-page-post .link-text{
	font-size: 11px;
	font-weight:400;
	font-style:normal;
}


.pr-2px{
	padding-right: 2px;
}

.mobile-post-back .link-text-container{
	margin-top:3rem !important;
}

.post-page-post .link-text-container{
	line-height:1.2rem !important;
	padding:1rem;
	background-color: #f1f1f1;
	border-radius:0.5rem;
	width:100%;
	margin-left:0px;
	display:block;
	height:auto;
}

.post-page-post .link-text-container:hover{
	background-color: #eaeaea;
	transform: scale(0.97);
	@media screen and (max-width:$desktop){
		background-color: #00000000;
	}	
}

.ml-neg{
	margin-left:-0.25rem;
}

.mr-0{
	margin-right:0rem !important;
}

.pc-br-icon{
	width:15px;
	height:15px;
	margin-bottom:0px;
	@media screen and (max-width:$desktop){
		width:14px;
		height:14px;
	}
}

.pc-br-group{
	display:flex;
	margin-top:auto;
	margin-bottom:1.5px;
	margin-left:3px;
}

.pc-br-group .link-text{
	margin-bottom:4px;
	color:#6d6d6d;
}

.pc-br-group-left-adjust{
	margin-left:10px;
}

.comment-icon-adjustment{
	margin-bottom:-1px;
}

.link-text-container{
    margin-top:auto;
	border-radius:10px;
    margin-right:10px;
    margin-left:-10px;
    padding-left: 10px;
    padding-right:0px;
	font-size:0px;
    padding-top:0px;
    padding-bottom:0rem;
	height:26px;
    line-height: 13px !important;
    width:auto;
    text-align: left;
	display:flex;
    @media screen and (max-width:$desktop){
        margin-left:-5px;
        padding-left: 5px;
		padding-right:5px;
		padding-top:0px;
		margin-bottom:auto;
		display:flex;
    }
}

.link-text-container .big_wrapper.irl{
	margin-left:0px;
	margin-top:2px;
	width: 20px;
    height: 20px;
    min-width: 5px;
	margin-right:8px;
	filter: grayscale(0.1) brightness(1.1);
}


.link-text-container .big_wrapper{
	margin-top:-6px;
	width:37px;
	min-width:37px;
	display:inline-block;
	margin-left:-9px;
	margin-right:0px;
	filter: grayscale(0.1) brightness(1.1);
}

.link-text-container .lil_icon{
	display:inline-block;
	margin-right:5px;
	margin-left:0px;
	width:16px;
	height:16px;
	margin-top:auto;

}

.post_card_text_tiny .link-text{
	font-size:15px;
	@media screen and (max-width:$desktop){
        font-size:13px;
		color: #484848;
		margin-right:-3px;

    }
	
}

.post_card_text_short .link-text{
	font-size:15px;
	@media screen and (max-width:$desktop){
        font-size:13px;
		color: #484848;
		margin-right:-3px;

    }
}

.post-card-text-cont .link-text{
	font-size:15px;
	@media screen and (max-width:$desktop){
        font-size:13px;
		color: #484848;
		margin-right:-3px;

    }
}

.comment_count_text{
	font-size:13px;
	margin-left:4px;
}

.booksIcon{
	width:16px;
	height:16px;
	margin-top: -3px;
    margin-right: 7px;
}

.libraryContainer{
	margin-top:0.5rem;
	@media screen and (max-width:$desktop){
        margin-bottom:0.5rem;

    }
}

.inTextIcon{
	width:11px;
	height:11px;
	margin-right:0.25rem;
	margin-top:1px;
}

.inTextIcon2{
	width:14px;
	height:14px;
	margin-right:0.25rem;
	margin-left:0.25rem;
	margin-bottom:2px;
}

.inTextIconComment{
	@extend .inTextIcon;
	width: 13px;
    height: 13px;
    margin-right: 0rem;
    margin-top: -3px;
    margin-left: 0px;
	filter: brightness(0) saturate(100%) invert(24%) sepia(50%) saturate(0%) hue-rotate(240deg) brightness(98%) contrast(89%);
	@media screen and (max-width:$desktop){
		width: 12px;
    	height: 12px;
        margin-top: -1px;
		margin-left:5px;
    }
}

.author-promote-small .big_wrapper{
	margin-top:-7px;
	left:3px;
}

.author-promote-small .big_wrapper.irl{
	margin-top:3px;
	margin-left: 15px;
    left: 0px;
}


.author-promote-small{
	width:max-content;
	margin-left:-0.5rem;
	height:33px;
}

.display-none{
	display:none !important;
}

.vis-none{
	visibility: hidden;
}

.linear-tidbits .tidbit-area{
	flex-direction:row;
	gap:1.5rem;
	@media screen and (max-width:$desktop){
		margin-top:0rem;
		flex-direction: column;
		align-items: start;
		gap:0.5rem;
	}
}

.tidbit-text-user-page{
	@media screen and (max-width:$desktop){
		margin-left:0rem !important;
	}
}

.user-info-n-share{
	border-radius:0rem !important;
	justify-content:flex-start !important;
	padding-top:1.25rem !important;
	padding-bottom:1.25rem !important;
}

.user-info-n-share.small_auth_pic{
	padding-top:0.75rem !important;
	padding-bottom:0.75rem !important;
	padding-left:1.25rem;
	padding-right:1.25rem;
	@media screen and (max-width:$desktop){
       padding-left:0.5rem !important;
	   padding-right:0.5rem !important;
    }
}


.user-page-top .user-info-n-share{
	padding-top:0rem !important;
	padding-bottom:0rem !important;
}


.user-name-post-page.small_auth_pic{
	margin-left:-3px !important;
	margin-top:auto !important;
}

.user-image-container-circle-container.small_auth_pic{
	display:flex;
	flex-direction: row;
	left:-6px;
	@media screen and (max-width:$desktop){
        margin-left:-6px;
    }
}


.user-image-container-circle .big_wrapper.irl{
	width: 100px;
    height: 100px;
    margin-left: 0px;
    margin-top: 0px;
}

.user-image-container-circle .big_wrapper{
	width: 180px;
    height: 180px;
    margin-left: -40px;
    margin-top: -40px;
}



.user-image-container-circle{
	width:100px;
	height:100px;
	border-radius:50px;
}

.user-image-container-circle.small_auth_pic{
	display:none;
}

.user-icon-on-circle{
	right:-40px;
	top:-15px;
// 	background-color:white;
// 	padding:5px;
}

.user-icon-on-circle.small_auth_pic{
	position:relative !important;
	top:auto;
	right:auto;
	margin-top:-12px;
	height:50px;
}

.share-row.small_auth_pic{
	margin-bottom:auto !important;
}

.whiteBack{
	position: absolute;
    background-color: white;
    width: 40px;
    height: 40px;
	border-radius:0.5rem;
	top:50%;
	left:50%;
	transform: translate(-50%, -50%);
}

.user-image-container-circle-container{
	width:fit-content;
	min-height:110px;
	margin-left:0px;
}

.share-row-topic{
	padding:0rem !important;
	gap: 4px;
}

.share-row-topic:hover p{
	color:$link-blue;
}

.share-row{
	bottom:1rem;
	right:1.5rem;
	@media screen and (max-width:$desktop){
        right:1.1rem;
		bottom:0.95rem;
		// position:relative !important;
		// margin-top:0.75rem;

    }
}

.z-img{
	z-index:1;
	//z-index:5;  <-- used to be this BUT avatar icons would go OVER create comment dropdown 
}

.link-text-container:hover{
    background-color: #f8f8f8;
	@media screen and (max-width:$desktop){
		background-color: #00000000;
	}
}

.dotdotdot-container.share-dotdotdot{
	margin-left:0px !important;
}
 
.dotdotdot-container{
    flex:none;
    width:auto;
    position:relative;
	display:flex;
	margin-left:auto;
	margin-right:auto;
}

.summary_vote_top + .dotdotdot-container{
	margin-left:0.5rem;
}

.post-footer-row .dotdotdot-container{
	top:1px;
}

.post-footer-row .horz-vote-area{
	margin-right:0rem;
	margin-top:auto;
	margin-bottom:1px;
}

.option-container .horz-vote-area{
	margin-bottom:auto;
}

.dotdotdot{
    // position:absolute;
    // bottom: 0px;
    // right:0px;
	width:20px;
	margin:0rem !important;
	@media screen and (max-width:$desktop){
		width:18px;
		}
}

.dotdotdot_btn{
	padding:0.1rem;
	border-radius:1rem;
	height: min-content;
    margin-top: auto;
    margin-bottom: 0px;
}

.dotdotdot_btn:hover{
	transform:scale(0.96);
	background-color:#f8f8f8;
}

.dotdotdot:hover{
    // background-color: $hover-grey;
    // border-radius:5px;
}

.dotdotdot_dropdown{
	// transform: translate3d(0px, 35px, 0px) !important;
	z-index:10;
	margin-top:0.5rem !important;
	border-radius:0.5rem;
	box-shadow: #2c2c2c21 -2px 2px 4px 2px;
	width:max-content;
	min-width:90%;
	padding-left:0rem !important;
	@media screen and (max-width:$desktop){
        margin-top:1rem !important;
		max-width:90vw;
		// min-width:90vw	
    }
}

.fixed-height-dot{
	height:44px;
	display: flex;
}

.share-icon-drop{
	width:25px;
	margin:5px
}

.twitter-share{
	filter: invert(20%) sepia(15%) saturate(0%) hue-rotate(162deg) brightness(104%) contrast(101%);
	padding:2px;
}

.facebook-share{
	filter: invert(37%) sepia(43%) saturate(711%) hue-rotate(182deg) brightness(98%) contrast(98%);
}

.linkedin-share{
	filter: invert(28%) sepia(86%) saturate(1286%) hue-rotate(175deg) brightness(101%) contrast(101%);
}

.reddit-share{
	filter: invert(41%) sepia(63%) saturate(5720%) hue-rotate(357deg) brightness(100%) contrast(105%);
}

.twitter-share:hover{
	// #1DA1F2
	filter: invert(62%) sepia(0%) saturate(3%) hue-rotate(175deg) brightness(93%) contrast(79%);
	// filter: none;
}

.facebook-share:hover{
	// #4267B2
	filter: invert(59%) sepia(77%) saturate(420%) hue-rotate(189deg) brightness(91%) contrast(97%);
}

.linkedin-share:hover{
	// 0077B5
	filter: invert(76%) sepia(9%) saturate(5239%) hue-rotate(171deg) brightness(95%) contrast(91%);
}

.reddit-share:hover{
	// #FF4301
	filter: invert(71%) sepia(38%) saturate(2825%) hue-rotate(319deg) brightness(106%) contrast(101%);
}

.hover-twitter-share:hover{
	filter: invert(43%) sepia(95%) saturate(778%) hue-rotate(172deg) brightness(101%) contrast(90%);
}

.hover-facebook-share:hover{
	filter: invert(37%) sepia(43%) saturate(711%) hue-rotate(182deg) brightness(98%) contrast(98%);
}

.hover-linkedin-share:hover{
	filter: invert(28%) sepia(86%) saturate(1286%) hue-rotate(175deg) brightness(101%) contrast(101%);
}

.hover-reddit-share:hover{
	filter: invert(41%) sepia(63%) saturate(5720%) hue-rotate(357deg) brightness(100%) contrast(105%);
}

.posted-in-title{
    margin-bottom:0px;
    margin-left:0em;
    font-size: 13px;
    font-weight:500;
    width:50%;
	color:$darkest-dark;
	@media screen and (max-width:$desktop){
        font-size: 13px;
    }
}

.close-btn-axil{
	margin-left:auto;
	background-color:white;
	border:none;
}

.log-side .close-btn{
	@media screen and (max-width:$desktop){
        background-color:#00000000;
    }
}

.log-side .close-icon{
	@media screen and (max-width:$desktop){
        filter: invert(100%) sepia(5%) saturate(19%) hue-rotate(215deg) brightness(103%) contrast(102%);
    }
}

.close-btn{
    width:10%;
    position:relative;
}

.close-pic{
    position:absolute;
    width:20px;
    max-width: none;
    top:-0px;
}

.disReport_container .close-pic{
	top:-2px;
}

.front-link-close{
    top:-3px !important;
    right: 1px !important;
}

.posted-in-dropdown-menu{
    padding:1rem;
	background-color:rgba(255, 255, 255, 0) !important;
	// margin-bottom:1px;
	border-radius: $common-border-radius-up !important;
	padding-bottom:0.5rem;
	padding-top:0.75rem;
	@media screen and (max-width:$desktop){
		margin-bottom:1px;
        border-radius:0.1rem 0.1rem 0rem 0rem !important;
    }
}

.front-view-title{
	margin-bottom:0.75rem;
}

.username-posted-in{
	
	@media screen and (max-width:$phone){
        margin-left:0rem;
		margin-right:1rem;
    }
}



.dropdown-front-links{
	background-color: #eaeaea00;
	@media screen and (max-width:$desktop){
        margin-bottom:0rem !important;
		margin-top:0rem !important;
    }
}

.downRight{
	width:16px;
	height:16px;
	margin-right:0.25rem;
}

.link-group{
    padding:0px;
	border-radius: 0.25rem;
	width:calc(50% - 0.5rem);
    flex-direction: column;
    overflow: hidden;
	box-shadow:1px 1px 1px 0px #4040401f;
	@media screen and (max-width:$desktop){
        width:100%;
    }
}

.internal-front-link{
	background-color: white;
    border-radius: 0.1rem;
	@media screen and (max-width:$desktop){
        border-radius: 0rem;
    }
}

.internal-front-link .front-link-text{
	font-size: 13px;
	line-height:0.5rem;
	font-weight:500;
	height:min-content;
	@media screen and (max-width:$desktop){
	    font-size:13px;
	}
}

.external-front-link{
    border-radius: $common-border-radius;
	overflow:hidden;
	background-color:white;
	padding:0rem;
	min-height:120px;
	box-shadow:$common-box-shadow;
	// height:150px;
	width:100%;
	margin-bottom:$margin-dt-1;
}

.external-front-link:hover{
	transform: scale(0.99);
	cursor:pointer;
	@media screen and (max-width:$desktop){
        transform: none;
    }
}

.overflow-front-view{
	// overflow:hidden;
	display:flex;
	flex-direction:column;
}

.link-group:hover{
	cursor:pointer;
	@media screen and (min-width:$phone){
		transform:scale(0.97) !important;
	}
}


.external-front-link .link-group-internal-padding{
	padding:1rem;
	padding-left:1.5rem;
	padding-right:1.5rem;
	width:calc(80% - 0.1rem);
	@media screen and (max-width:$desktop){
        padding:1rem;
		width:calc(75% + 0.2rem);
		border-radius: 0rem 0.1rem 0.1rem 0rem;
    }
}


.link-group-internal-padding{
    padding:1rem;
    width:75%;
	border-radius:0.25rem;
	background-color:white;
	@media screen and (max-width:$desktop){
        padding:1rem;
		width:75%;
		border-radius: 0rem 0.1rem 0.1rem 0rem;
    }
}

.link-group-internal-padding .follow-button{
	padding:0.25rem;
	padding-left:0.25rem;
	margin-left:-0.27rem;
	margin-top:0rem;
	width:auto;
}

.link-group-internal-padding .follow-text{
	font-size: 13px;
	font-weight:400;
	@media screen and (max-width:$desktop){
	    font-size:13px;
	}
}

.front-link-icon-container{
	// Front feed 
    width:calc(20% + 0.1rem);
	margin-right:0rem;
	max-height:350px;
    background-color:#a3bbd0;
    align-items:center;
    justify-content: center;
	height:auto;
    display: flex;
	border-radius: $common-border-radius-left;
	// overflow:hidden;
	@media screen and (max-width:$desktop){
		border:none !important;
		width:calc(25% - 0.2rem);
		margin-right:0rem;
		max-height:300px;
        border-radius: 0rem;
		box-shadow:-1px 0px 2px 0px #40404036;
    }
}

.front-link-icon-container-2{
	// Posted in area
	@extend .front-link-icon-container;
	width:25%;
	margin-right:0rem;
	height:100%;
	max-height:140px;
	min-height:100px;
	border-radius: 0.1rem 0rem 0rem 0.1rem;
	@media screen and (max-width:$desktop){
        border-radius: 0rem;
    }
}


.user-icon-posted-in .big_wrapper{
	width:85px;
	min-width:85px;
	margin-right:-1rem;
}


.user-icon-posted-in .big_wrapper.irl{
	width:85px;
	min-width:85px;
	height:85px;
	padding:1rem;
	padding-top:0rem;
	padding-bottom:2rem;
	margin-right:-1rem;
	margin-left:2px;
}

.youtube_pic{
	height:135% !important;
	width:auto;
}

.front-link-icon{
    width:100%;
    height:100%;
    object-fit:cover;
	border-radius: 0rem;
    // margin-bottom:0.5rem;
	@media screen and (max-width:$desktop){
        border-radius: 0rem;
		width:100%;
   		height:100%;
    }
}

.front-link-text{
    color:$darkest-dark!important;
    font-weight:600;
	line-height:1.5rem;
    text-align: left;
	margin-bottom:0.2rem;
	font-size:15px;
	// padding-right:6px;
	@media screen and (max-width:$desktop){
        font-size: 14px;
		line-height:1.5rem;
    }
}

.front-postcard-follow-button{
	// bottom:0.75rem;
	// right:0.75rem;
	display:flex;
    backdrop-filter: blur(2px);
	background-color:rgba(255, 255, 255, 0.9);
	// padding:0.5rem;
	// padding-left:0.75rem;
	// padding-right:0.75rem;
	border-radius:3rem;

	bottom:1rem !important;
	right:1rem !important;
	left:auto !important;
	// height:35px;

	box-shadow:-1px 1px 1px 1px #4040403d;

	width:34px;
	height:34px;

}

.phoneVersion .front-postcard-follow-button{
	bottom:1rem !important;
	right:1rem !important;
	left:auto !important;
	// height:35px;

	box-shadow:-1px 1px 1px 1px #4040403d;

	width:34px;
	height:34px;
}

// .desktop .front-postcard-follow-button{
// 	@media screen and (max-width:$desktop){
// 		bottom:0rem;
// 		height:auto;
// 		right:0rem;
// 		padding:0rem;
//     }
// }

.front-postcard-follow-button:hover{
	width:auto !important;
	@media screen and (min-width:$desktop){
		transform:scale(0.90) !important;
	}

}

.front-postcard-follow-button .follow-button{
	margin-left:auto;
	margin-top:auto;
	margin-bottom:auto;
	margin-right:auto;

}

.front-postcard-follow-button:hover .follow-button{
	background-color: #00000000;
	padding-right:0.75rem;
	padding-left:0.75rem;
}


.front-postcard-follow-button .follow-icon{
	padding:0rem !important;
	width:18px;
	@media screen and (max-width:$desktop){
		width:16px;
	}
}

.front-postcard-follow-button .follow-text{
	display:none !important;
}

.front-postcard-follow-button:hover .follow-text{
	display:block!important;
}

// #################################################################################################
// #########################################################################                ########
//#######################################################################   ################   ##### 
//####################     FeedBack Reactions      #####################   ####  ######  ####   ####
// #####################################################################   ####  ######  ####   ####
// #####################################################################   ##################   ####
// ######################################################################  ###  ########  ###   ####
//#######################################################################   ###         #####   #####
// #######################################################################   ###############   #####
// ##########################################################################               #########
// ##################################################################################################


.width-auto{
    width:auto;
}

.h-auto{
    height:auto;
}

.width-max-content{
	width:max-content !important;
}


.posted-in-dropdown-container{
	// background-color:#eaeaea;
	margin-top:-5px;
	border-radius:0rem;
}

.posted-in-dropdown-container .close-btn.btn{
	background-color:#00000000 !important;
}

.post-page-dropdown .posted-in-dropdown-container{
	@media screen and (max-width:$desktop){
        margin-bottom:$margin-mobile-1;
    }
}

.posted-in-dropdown-container .gap-half{
	gap:1rem;
	padding-left:1rem;
	padding-right:1rem;
	padding-bottom:1.25rem;
	@media screen and (max-width:$desktop){
        gap:0.5rem;
    }
}

.fbr-mid{
    margin-top:auto;
    margin-bottom:auto;
    margin-right:0.5rem;
    width:auto;
    font-size:18px;
	font-weight:600;
	color: $darkest-dark;
    @media screen and (max-width:$desktop){
        font-size: 14px;
        margin-right:0.25rem;
        margin-left:0.25rem;
    }
}

// Distributed Moderation #############################################################
// ########################################################   #########################
// ########################################                                    ########
// ########################################  ##############   ###############  ########
// ########################################  ##############   ###############  ########
// #######################################    #############   ##############    #######
// #####################################   ##   ###########   ############   ##   #####
// ####################################   ####   ##########   ###########   ####   ####
// ####################################          ##########   ###########          ####
// ########################################################   #########################
// ########################################################   #########################
// #############################################                         ##############
// ####################################################################################


.mod_outer_window{
	max-height:250px;
	overflow:hidden;
	transition: max-height 0.8s;
	border-radius:1rem;
}

.mod_inner_window{
	height:100%;
}

.mod-expandable-button{
	@extend .front-expandable-button;
	bottom:0rem !important;
	border-radius:0rem 0rem 1rem 1rem !important;
	background:linear-gradient(180deg, #eaeaea00 0%, #eaeaea 55%) !important;
}

.mod-expandable-button:hover{
	background:linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 55%) !important;
}


.mod-expandable-button-close{
	@extend .front-expandable-button-close;
	margin-top:0rem !important;
	width:100%;
	border-radius:0rem 0rem 1rem 1rem !important;
	background:linear-gradient(180deg, #eaeaea00 0%, #eaeaea 55%) !important;
}

.mod-expandable-button-close:hover{
	background:linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 55%) !important;
}

.comment-report-accord.collapse:not(.show){
	display:block;
	height:0px;
	overflow:hidden;
}

.mod-comment-expandable-button-close{
	@extend .mod-expandable-button-close;
	bottom:-1rem !important;
	width:calc(100% + 2rem) !important;
	left:-1rem !important;
}

.disReport_container{
    margin-bottom: $margin-dt-1;
    padding:1rem;
    border-radius: $common-border-radius;
    background-color:white !important;
    @media screen and (max-width:$desktop){
		margin-bottom:0rem;
		margin-top:$margin-mobile-1;
        padding:1rem;
		padding-top:1rem;
		padding-bottom:1rem;
        border-radius:$common-border-radius-mobile;
        border-top: none !important;
        border-bottom: none !important;
    }
}

.disReport_container .my-outer-post{
    margin-bottom:0rem;
	margin-top:0rem;
    // padding-left:0.8rem;
    // padding-right: 0.8rem;
}

.disReport_container .my-inner-post{
    // border:#bdbdbd solid 1px;
    // border-radius: 1.5rem;
    // padding-top:0.5rem;

	background-color: #ededed;
	border-radius:1rem !important;
	width:100% !important;
	box-shadow:-2px 2px 2px 0px #2020204f
    // border: #bdbdbd solid 1px;
    // border-radius:0px;
}

.disReport_container .comment_rest{
	background-color: #ededed;
	border-radius:0.5rem !important;
	margin-left:0rem !important;
	margin-right:0rem !important;
	margin-bottom:0rem !important;
}

.disReport_container .post-style{
    padding: 1rem;
    @media screen and (max-width:$desktop){
        padding-bottom: 0.5rem;
    }
}

.disReport_container .fbr-mid{
    margin-right:1.5rem;
    margin-top:0px;
    @media screen and (max-width:$desktop){
        margin-right:1rem;

    }
}

.disReport_container .is_clickable:hover{
	background-color: $hover-grey !important;
}

.my-flex-row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.my-flex-col{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.wrap-ok{
	flex-wrap:wrap !important;
}

.justify-around{
    justify-content: space-between;
}

.dis-bottom-padding{
    padding-top:0rem;
    padding-bottom:0rem;
	margin-bottom:1.5rem;
    @media screen and (max-width:$desktop){
        padding-bottom:0rem;
        padding-top:0rem;
		margin-bottom:1.5rem;
    }
}

.mb-2-seriously{
	margin-bottom:2rem !important;
}

.mb-3-seriously{
	margin-bottom:3rem !important;
}

.my-flex-column{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.close-btn.btn{
    padding-left:8px;
    padding-right:8px;
	background-color:white;
}

.disReport_Area{
    padding-bottom: 1rem;
}

.disReport_Area .my-accordion{
	padding:1rem !important;
	margin-top:0.5rem;
}

.disReport_sidenote{
    font-weight:200;
	font-size: 11px;
	color:grey;
    float: right;
    margin-bottom: 1rem;
    @media screen and (max-width:$desktop){
        float:none;
        // margin-right:auto;
        // margin-left:auto;
        font-size:10px;
        font-weight: 200;
    }
}

.my-text-area{
    width:100%;
    min-height:75px;
    padding:1rem;
    border-radius: 2rem;
    border:#bdbdbd solid 1px;
    @media screen and (max-width:$desktop){
        border-radius: 1rem;
    }
}

.my-text-area:focus-visible{
    border:#bdbdbd solid 1px !important;
    outline: none !important;
}

.mobile-disreport-range{
	padding:0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    // background-color: #e4e4e4;
    border-radius:0.5rem;
    margin-top: auto;
    margin-bottom: auto;
	@media screen and (max-width:$desktop){
        max-width: 70px;
		border-radius:0.5rem !important;
		text-align:center;
		padding:0.25rem;
    }
}

.radio-container-container{
	margin-top:1rem;
	padding-top:1rem;
	padding-bottom:1rem;
	border-radius:1rem;
	background-color:$light-grey-background;
	justify-content: space-around;
}

.radio-container{
	position:relative;
	margin-top: auto;
    margin-bottom: auto;
    margin-left:auto;
    margin-right:auto;
	height:20px;
}

.radio-button{
    padding:0.5rem;
    border:0px;
    height:20px;
    width:20px;
    @media screen and (max-width:$desktop){
        height:16px;
        width: 16px;
    }
}

.radio-button:hover{
	cursor:pointer;
	opacity:0;
}

.radio-button:checked{
	opacity:0;
}


.radio-button + label{
	display:none;
	pointer-events: none;
}

.radio-button:checked + label{
	font-size:24px;
	display:block;
	position:absolute;
	top:50%;
	left:50%;
	transform: translate(-50%, -50%);
}

.radio-container:hover{
	cursor:pointer;
}

.radio-container:hover label{
	font-size:22px;
	display:block;
	cursor:pointer;
	position:absolute;
	top:50%;
	left:50%;
	transform: translate(-50%, -50%);
}

.radio-side-text{
	font-weight:200;
	font-size: 11px;
	text-align: center;
	color:grey;
	margin-bottom:0.2rem;
	@media screen and (max-width:$desktop){
		font-size:10px;
    }
}

.radio-side-text-container{
	justify-content: space-evenly;
	padding-top:1rem;
	padding-bottom: 1rem;
}

.discontextheader{
    margin-top: 0rem;
    // margin-left:1rem;
	margin-bottom:1rem;
    font-weight: 600;
    @media screen and (max-width:$desktop){
        margin-top:0rem;
    }
}

.disprogbtn{
    margin-left:1rem;
    min-width:100px;
    background-color:#1F97DB;
    color:white;
}

.disprevbtn{
    min-width:100px;
    color:#1F97DB;
    background-color: #eaeaea;
    font-weight: 600;
}

.cdm_comment_ml_0{
	margin-left:0rem;
}

.cdm_comment_ml_1{
	margin-left:1rem;
}

.cdm_comment_ml_2{
	margin-left:2rem;
}

.cdm_comment_ml_3{
	margin-left:3rem;
}

.cdm_comment_ml_4{
	margin-left:4rem;
}

.cdm_comment_ml_5{
	margin-left:5rem;
}

.cdm_comment_ml_6{
	margin-left:6rem;
}

.cdm_comment_ml_7{
	margin-left:7rem;
}

// Post Page @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@                                     @@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@       @@@@@@@@@@@@@@@@@@@@@@@@      @@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   @    @@@@@@@@@@@@@@@@@@@@@   @    @@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   @@@    @@@@@@@@@@@@@@@@    @@@    @@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   @@@@@@    @@@@@@@@@@    @@@@@@    @@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   @@@@@@@@    @@@@@@     @@@@@@@    @@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   @@@@@@@@@@@         @@@@@@@@@@    @@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@                                     @@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

//Table of Contents

.edit-btn-text{
	padding-right:0.4rem;
	padding-left:0.4rem;
}

.floating-icon{
	width:15px;
	height:15px;
	max-width:15px;
	// padding:1px;
	margin-top: auto;
    margin-bottom: auto;
    margin-right: 0rem;
}

.toc-icon{
	width:20px;
	height:20px;
	max-width:20px;
	// padding:1px;
	margin-top: auto;
    margin-bottom: auto;
    margin-right: 0rem;
}

.tableOfContentsContainer-rel .close-btn{
	display:none;
}

.tableOfContentsContainerContainer{
	position:sticky;
	z-index:10;
	top:1rem;
	width: 100%;
	min-height:0px;
	margin-bottom:0rem;
	@media screen and (max-width:$phone){
		margin-bottom:0rem;
	 }
}

.tableOfContentsContainerContainer.has_pd{
	min-height:28px;
	margin-bottom:0.5rem;
	@media screen and (max-width:$phone){
		margin-bottom:1rem;
	}
}

.tableOfContentsContainer-abs{
	position: absolute;
	width:100%;
}

.tableOfContentsContainerContainer + h2 {
	margin-top:0rem;
}

.tableOfContentsContainer-rel{
	position: relative;
	width:100%;
	// min-height:600px;
}

.tableOfContentsButtonRow{
	gap:2px;
	justify-content: right;
	@media screen and (max-width:$desktop){
       gap:2px;
    }
}


.tableOfContentsButton{
	width: auto;
    padding: calc(0.2rem);
	height:fit-content;
	// background-color: #eaeaea;
	margin-right:0.5rem;
	// box-shadow:-1px 1px 3px 0px rgb(64 64 64 / 9%);
    border-radius: 2rem;
	font-size: 11px;
	margin-top:auto;
	margin-bottom:auto;
	@media screen and (max-width:$desktop){
        margin-right:0.5rem;
    }
}

.tableOfContentsButton:last-child{
	margin-right:0rem;
	@media screen and (max-width:$desktop){
		margin-right:0rem;
    }
}

.edit-btn-text{
	font-size:11px;
}

.toc-edit-button{
	width: auto;
	height:fit-content;
	padding: 0.35rem;
	border-radius: 2rem;
	background-color: #eaeaea;
	border:none;
	margin-right:0rem;
	margin-left:0rem;
	margin-top:auto;
	margin-bottom:auto;
	@media screen and (max-width:$desktop){
        margin-right:0rem;
		margin-left:0.5rem;
    }
}

.toc-edit-button:hover{
	background-color: #f1f1f1 !important;
    transform: scale(0.96);
}

.tableOfContentsButton + .toc-edit-button{
	margin-left:0rem;
	@media screen and (max-width:$desktop){
		margin-left:0rem;
    }
}

.tableOfContents{
	padding:1.5rem;
	background-color: rgb(255 255 255 / 90%);
    // border: 2px solid #cce8f7;
	box-shadow: 0px 1px 3px 1px rgb(64 64 64 / 49%);
    backdrop-filter: blur(2px);
	margin-top:0.5rem;
	margin-bottom:0.5rem;
	border-radius: 0.5rem;
	max-height:85vh;
	overflow-y:auto;
}

.tableOfContentsButton p{
	font-size: 11px !important;
}

.tableOfContentsButton:hover{
	cursor:pointer;
	background-color: #f1f1f1 !important;
	transform: scale(0.96);
}

.TOC-header{
	color:#404040 !important;
	font-weight:500;
	margin-bottom:0.5rem;	
}

.TOC-header:hover{
	color:#0d63d4 !important;
}

.TOC-header.ms-1{
	margin-left:1rem !important;
}

.TOC-header.ms-2{
	margin-left:2rem !important;
}

.TOC-header.ms-3{
	margin-left:3rem !important;
}

.TOC-header.ms-4{
	margin-left:4rem !important;
}

// Table of contents innnnnn rival

.mobile-post-back{
	border-radius: .25rem 0.25rem 0rem 0rem;
    background-color: #d9e1e4;
	@media screen and (max-width:$desktop){
        // background-color:$back-grey;
		border-radius:0.25rem !important;
		margin-top:0px;
    }
}


.post-page-post{
    padding: 3rem;
	padding-bottom:1.5rem !important;
	margin-bottom: 0px !important;
    // border-bottom: 1px solid #eaeaea !important;
	z-index:3;
	position: relative;
    margin:0rem;
	border-radius: 0.25rem !important;
	// box-shadow: -1px 1px 1px 1px #00000004;
    @media screen and (max-width:$desktop){
        padding-top: 2rem;
        padding-bottom: 0.25rem !important;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        border-radius: 0rem 0rem 0.25rem 0.25rem !important;
    }
}

.champ_rival_top .post-page-post{
	padding-top:0rem;
	padding-left:0rem;
	padding-right:0rem;
	padding-bottom:0rem !important;
	background-color:#00000000 !important;
	margin-bottom:1px;
}

.post-page-top-controls{
	border-radius: 0.25rem 0.25rem 0rem 0rem;
	padding-top: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
	background-color:#ffffff !important;
    @media screen and (max-width:$desktop){
		border-radius:0rem;
        padding-top: 1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.experiment{
	width:100px;
	float:right;
}

.experi-text{
	font-size:15px;
}
.post-page-post-text{
	border-radius:0rem;
}

// .post-page-post-text h3:not(:first-child){
// 	padding-top:2rem;
// 	position:relative;
// }

// .post-page-post-text h3:not(:first-child)::before{
// 	content: "";
// 	position: absolute;
// 	top: 0;
// 	max-width:600px;
// 	left: -2rem;
// 	height: 1px;
// 	background-color: #eaeaea; 
// 	@media screen and (min-width:$xxx-desktop){
//         // width:calc(1600px * 0.8);
// 		width:calc(var(--large) * 0.90);
//     }
//     @media screen and (max-width:$xxx-desktop) and (min-width:$xx-desktop){
//         // width:calc(1540px * 0.8);
// 		width:calc(var(--large) * 0.90);
//     }
//     @media screen and (max-width:$xx-desktop) and (min-width:$x-desktop){
//         // width:calc(1480px * 0.8);
// 		width:calc(var(--med-large) * 0.80);
//     }
//     @media screen and (max-width:$x-desktop) and (min-width:$huge-desktop) {
//         width:calc(var(--med-large) * 0.80);
//     }
//     @media screen and (max-width:$huge-desktop) and (min-width:$large-desktop){
// 		width:calc(var(--med) * 0.85);
//     }
//     @media screen and (max-width:$large-desktop) and (min-width:$big-desktop){
//         width:calc(var(--med) * 0.85);
//     }
//     @media screen and (max-width:$big-desktop) and (min-width:$desktop){
//         width:calc(var(--med-small) * 0.80);
//     }
//     @media screen and (max-width:$desktop) and (min-width:$small-desktop) {
//         width:calc(var(--small) * 0.70);
// 		left:-1rem;
//     }
//     @media screen and (max-width:$small-desktop) and (min-width:$tablet){
//         width:calc(var(--x-small) * 0.70);
// 		left:-1rem;
//     }
//     @media screen and (max-width:$tablet) and (min-width:$phone){
//         width:calc(100vw );
// 		left:-1rem;
//     }
//     @media screen and (max-width:$phone){
//         width:calc(100vw);
// 		left:-1rem;
//         // min-width:none;
//     }
// 	width:calc(var(--med-large) * 0.80);
// }

// .post-page-post-text h2:not(:first-child){
// 	padding-top:2rem;
// 	position:relative;
// }

// .post-page-post-text h2:not(:first-child)::before{
// 	content: "";
// 	position: absolute;
// 	max-width:600px;
// 	top: 0;
// 	left: -2rem;
// 	height: 1px;
// 	background-color: #eaeaea; 
// 	@media screen and (min-width:$xxx-desktop){
//         // width:calc(1600px * 0.8);
// 		width:calc(var(--large) * 0.90);
//     }
//     @media screen and (max-width:$xxx-desktop) and (min-width:$xx-desktop){
//         // width:calc(1540px * 0.8);
// 		width:calc(var(--large) * 0.90);
//     }
//     @media screen and (max-width:$xx-desktop) and (min-width:$x-desktop){
//         // width:calc(1480px * 0.8);
// 		width:calc(var(--med-large) * 0.80);
//     }
//     @media screen and (max-width:$x-desktop) and (min-width:$huge-desktop) {
//         width:calc(var(--med-large) * 0.80);
//     }
//     @media screen and (max-width:$huge-desktop) and (min-width:$large-desktop){
// 		width:calc(var(--med) * 0.85);
//     }
//     @media screen and (max-width:$large-desktop) and (min-width:$big-desktop){
//         width:calc(var(--med) * 0.85);
//     }
//     @media screen and (max-width:$big-desktop) and (min-width:$desktop){
//         width:calc(var(--med-small) * 0.80);
//     }
//     @media screen and (max-width:$desktop) and (min-width:$small-desktop) {
//         width:calc(var(--small) * 0.70);
// 		left:-1rem;
//     }
//     @media screen and (max-width:$small-desktop) and (min-width:$tablet){
//         width:calc(var(--x-small) * 0.70);
// 		left:-1rem;
//     }
//     @media screen and (max-width:$tablet) and (min-width:$phone){
//         width:calc(100vw );
// 		left:-1rem;
//     }
//     @media screen and (max-width:$phone){
//         width:calc(100vw);
// 		left:-1rem;
//         // min-width:none;
//     }
// 	width:calc(var(--med-large) * 0.80);
// }

// .post-page-post-text h2.diff_removed::before{
// 	content:none !important;
// }

// .post-page-post-text h2.diff_removed{
// 	padding-top:0rem !important;
// 	margin-top:0rem !important;
// }

// .post-page-post-text h3.diff_removed::before{
// 	content:none !important;
// }

// .post-page-post-text h3.diff_removed{
// 	padding-top:0rem !important;
// 	margin-top:0rem !important;
// }

// .create-post-page .mf_editor{
// 	@media screen and (max-width:$desktop){
// 		padding-left:0rem !important;
// 		padding-right:0rem !important;
// 	}
// }


// .create-post-page .mf_editor h3:not(:first-child){
// 	padding-top:2rem;
// 	position:relative;
// }

// .create-post-page .mf_editor h3:not(:first-child)::before{
// 	content: "";
// 	position: absolute;
// 	top: 0;
// 	max-width:600px;
// 	left: -3.5rem;
// 	height: 1px;
// 	background-color: #eaeaea; 
// 	@media screen and (min-width:$xxx-desktop){
//         // width:calc(1600px * 0.8);
// 		width:calc(var(--large) * 0.90);
//     }
//     @media screen and (max-width:$xxx-desktop) and (min-width:$xx-desktop){
//         // width:calc(1540px * 0.8);
// 		width:calc(var(--large) * 0.90);
//     }
//     @media screen and (max-width:$xx-desktop) and (min-width:$x-desktop){
//         // width:calc(1480px * 0.8);
// 		width:calc(var(--med-large) * 0.80);
//     }
//     @media screen and (max-width:$x-desktop) and (min-width:$huge-desktop) {
//         width:calc(var(--med-large) * 0.80);
//     }
//     @media screen and (max-width:$huge-desktop) and (min-width:$large-desktop){
// 		width:calc(var(--med) * 0.85);
//     }
//     @media screen and (max-width:$large-desktop) and (min-width:$big-desktop){
//         width:calc(var(--med) * 0.85);
//     }
//     @media screen and (max-width:$big-desktop) and (min-width:$desktop){
//         width:calc(var(--med-small) * 0.80);
//     }
//     @media screen and (max-width:$desktop) and (min-width:$small-desktop) {
//         width:calc(var(--small) * 0.70);
// 		left:-2rem;
//     }
//     @media screen and (max-width:$small-desktop) and (min-width:$tablet){
//         width:calc(100vw );
// 		left:-1rem;
//     }
//     @media screen and (max-width:$tablet) and (min-width:$phone){
//         width:calc(100vw );
// 		left:-1rem;
//     }
//     @media screen and (max-width:$phone){
//         width:calc(100vw);
// 		left:-1rem;
//         // min-width:none;
//     }
// 	width:calc(var(--med-large) * 0.80);
// }

// .create-post-page .mf_editor h2:not(:first-child){
// 	padding-top:2rem;
// 	position:relative;
// }

// .create-post-page .mf_editor h2:not(:first-child)::before{
// 	content: "";
// 	position: absolute;
// 	top: 0;
// 	max-width:600px;
// 	left: -3.5rem;
// 	height: 1px;
// 	background-color: #eaeaea; 
// 	@media screen and (min-width:$xxx-desktop){
//         // width:calc(1600px * 0.8);
// 		width:calc(var(--large) * 0.90);
//     }
//     @media screen and (max-width:$xxx-desktop) and (min-width:$xx-desktop){
//         // width:calc(1540px * 0.8);
// 		width:calc(var(--large) * 0.90);
//     }
//     @media screen and (max-width:$xx-desktop) and (min-width:$x-desktop){
//         // width:calc(1480px * 0.8);
// 		width:calc(var(--med-large) * 0.80);
//     }
//     @media screen and (max-width:$x-desktop) and (min-width:$huge-desktop) {
//         width:calc(var(--med-large) * 0.80);
//     }
//     @media screen and (max-width:$huge-desktop) and (min-width:$large-desktop){
// 		width:calc(var(--med) * 0.85);
//     }
//     @media screen and (max-width:$large-desktop) and (min-width:$big-desktop){
//         width:calc(var(--med) * 0.85);
//     }
//     @media screen and (max-width:$big-desktop) and (min-width:$desktop){
//         width:calc(var(--med-small) * 0.80);
//     }
//     @media screen and (max-width:$desktop) and (min-width:$small-desktop) {
//         width:calc(var(--small) * 0.70);
// 		left:-2rem;
//     }
//     @media screen and (max-width:$small-desktop) and (min-width:$tablet){
//         width:calc(100vw );
// 		left:-1rem;
//     }
//     @media screen and (max-width:$tablet) and (min-width:$phone){
//         width:calc(100vw );
// 		left:-1rem;
//     }
//     @media screen and (max-width:$phone){
//         width:calc(100vw);
// 		left:-1rem;
//         // min-width:none;
//     }
// 	width:calc(var(--med-large) * 0.80);
// }

.floating_voting_row + h2::before{
	content:none !important;
}

.floating_voting_row + h3::before{
	content:none !important;
}

.floating_voting_row + h2{
	padding-top:0rem !important;
}

.floating_voting_row + h3{
	padding-top:0rem !important;
}

.champ_rival_top .post-page-post-text{
	padding-bottom: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
	background-color:#ffffff !important;
	border-radius: 0rem 0rem 0.25rem 0.25rem;
	@media screen and (max-width:$desktop){
        padding-bottom: 1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
		border-radius: 0rem;
    }
}

.onlyImageTopMargin .post_card_img_container{
	margin-top: 0rem !important;
}

.onlyImageTopMargin .post_img_container{
	margin-top:0.25rem !important;
	margin-bottom:0.25rem !important;
}

// .post-page-post .post-title{
//     margin-bottom: 0.25rem !important;
// }

.post-page-post-text .post-title{
	margin-bottom: 1rem !important;
	@media screen and (max-width:$phone){
		margin-bottom: 1rem !important;
	}
}

.mobile-post-back .post-title{
	margin-bottom: 0rem;
	@media screen and (max-width:$phone){
		margin-bottom: 0.5rem;
	}
}

.main-col .post-title{
	margin-bottom: 0.5rem;
}

.ps-content{
	display:none;
}

.ps-content.active{
	display:block;
}





.long-post-controls{
	padding-top:1.25rem;
	padding-left:0rem;
	padding-right:0rem;
	padding-bottom:1rem;
    // padding-top:0.75rem !important;
	// padding-bottom:0.75rem !important;
    // margin-top:0.4rem;
    
	border-radius: $common-border-radius-down !important;
    background-color:$white !important;
	// border-top: solid 1px #e9ebef !important;
	// box-shadow:0px 0.1rem 0.2rem 0px #3543561f;
    @media screen and (max-width:$desktop){
		// padding-top:1rem;
		padding-left:0rem;
		padding-right:0rem;
        border-radius:$common-border-radius-down-mobile !important;
		background-color:$white !important;
		// box-shadow:0px 0.1rem 0.2rem 0px #3543561f;
    }
}

.long-post-controls.rival-page{
    height:45px;
}

.create-rival-title{
	font-size:28px;
	padding:1rem;
	padding-right:1.5rem;
	padding-left:1.5rem;
	border-bottom:1px solid #eaeaea;
	margin-bottom:1rem;
	margin-top:0rem;
	@media screen and (max-width:$desktop){
		border-bottom:1px solid #cccccc;
		margin-bottom:0rem;
    }
}

.vote-area{
    margin-top: auto;
    margin-bottom: auto;
}

.floating-bottom-row{
	position:sticky;
	width:100%; 
	height:0px;
	bottom:0rem;
	justify-content: flex-end;
	display:flex;
}

.width-lpc-on-champ + .floating-bottom-row .floating-bottom-container{
	margin-top:-2.65rem;
}


.floating-bottom-container{
	height: fit-content;
    padding: 0.25rem;
	// padding-right:0.5rem;
	// padding-left:0.5rem;
    margin-right: -10px;
    margin-top: -3.25rem;
    background-color: white;
	border-radius:2rem;
	position:relative;
}

.floating-vote-tip{
	position:absolute;
	bottom:3rem;
	background-color:white;
	
	border-radius:0.5rem;
	box-shadow: #4040402e -1px 1px 1px 0px;
	padding:4px;
	// border:1px solid #404040;
}

.floating-vote-tip-inner{
	border:1px solid #d1d1d1;
	padding:1.25rem;
	border-radius:0.5rem;
}

.vote-tip-close-btn{
	position:absolute;
	right:-16px;
	top:-12px;
	background-color:white!important;

}

.floating-tip-text{
	font-weight:400;
	font-size:13px;
}

.vote-tip-close-btn .close-pic{
	width:18px;
}

.vote-tip-close-btn .close-pic:hover{
	filter:invert(20%) sepia(15%) saturate(0%) hue-rotate(162deg) brightness(104%) contrast(101%);
}

.option-container{
    width:fit-content;
    @media screen and (max-width:$desktop){
        margin-right: 0rem;
    }
}

.option-container .dotdotdot-container{
	width:auto;
}

.option-container .dotdotdot_btn{
	margin-top:0px;
	margin-left:0px;
	margin-right:0px;
	padding:0.25rem;
}

.option-container .dotdotdot{
	width:20px;
}

.cc_height{
    height: 100%;
}

.cc-button{
	width: 32px;
    height: 32px;
	padding:4px !important;
	margin-right:3px;
	margin-top: 3px;
    margin-bottom: -3px;
	margin-left:auto;
    border-radius: 2rem;
    background-color: white;
	display:flex;

	@media screen and (max-width:$desktop){
        padding:0;
    }
}

.cc-button-container{
	pointer-events:all;
	margin-left:auto;
}

.cc_icon{
    width:22px;
	@media screen and (max-width:$desktop){
        padding:0;
    }
}

.cc_icon_close{
	@extend .cc_icon;
	display:block !important;
}

.cc-button .cc_icon{
	display:none;
}


.cc-button.collapsed .cc_icon{
	display:block;
}

.cc-button.collapsed .cc_icon_close{
	display:none !important;
}

.cc-button:hover{
	transform:scale(0.97) !important;
	background-color:#eaeaea;
}

.cc-element{
	display:none !important;
}

.comment-feed-controls{
	background-color:white;
	margin-top:$cardGap;
	margin-bottom:$cardGap;
	border-radius: 0.25rem;
	padding:1rem;
	padding-bottom:1.25rem;
	padding-left:2rem;
	padding-right:2rem;
	overflow:hidden;
	// gap:0.25rem;
	width:100%;
	gap:0.5rem;
	@media screen and (max-width:$desktop){
		width:100%;
        margin-left:0rem;
		margin-right:0rem;
		padding-left:1rem !important;
		padding-right:1rem !important;
    }
}

.comment-feed-row .menu-writing{
    @media screen and (max-width:$desktop){
        margin-right:0rem
    }
}

.comment-feed-row .menu-avatar{
    display:none;
}

.comment-page-main{
	background-color: #d9e1e4;
}

.comment-page-top{
	padding: 1rem !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    background-color: #ffffff !important;
	margin-bottom:5px !important;
	border-bottom:1px solid #eaeaea;
	border-radius:0.25rem !important ;
	margin-top:3rem !important;
	@media screen and (max-width:$tablet){
        margin-top:0rem !important;
    }
}

.comment-page-top-icon{
	width:20px;
	margin-right:0.5rem;
}

.comment-page-top-icon:hover{
	@extend .filter-blue;
	transform: scale(0.98);
}

.post-feed .menu-card{
    // height:60px;
    justify-content:flex-start;
	// padding-top: 1rem !important;
	// padding-bottom:1.1rem !important;
    margin-right:0.4rem;
    margin-bottom:0px;
	width:calc(50% - 0.1rem);
	border-radius: $arch-border-radius !important;
    @media screen and (max-width:$desktop){
        height:auto;
		// min-height:60px;
        padding-left:0rem;
        margin-left:0rem;
        border-radius: $arch-border-radius !important;
    }
}

.comment-feed-row .menu-card:last-child{
    @media screen and (max-width:$desktop){
        border-radius:0.25rem 0rem 0rem 0.25rem;
    }
}

.post-feed .menu-avatar{
    width:auto;
    height:100%;
    margin-top:0px;
    padding:0.5rem;
    margin-left:0px;
    margin-right:0rem;
    @media screen and (max-width:$desktop){
        width:44px;
        padding-top:0.5rem;
        padding-bottom:0.5rem;
        padding-left:0.5rem;
        padding-right:0.5rem;
    }
}

.post-feed .menu-writing{
    margin-bottom:auto !important;
	//needs to be important to center comment filter text e.g."Hot Comments about anything"
    // margin-left:0.5rem;
	font-weight:400;
	font-size: 13px;
    @media screen and (max-width:$desktop){
        margin-left:auto;
        margin-right:auto;
        margin-top:0px;
        text-align: center;
		font-weight:400;
    }
}

.post-feed .my-accordion-button{
    display: flex !important;
}

.post-feed .my-accordion-button:hover{
	@media screen and (min-width:$desktop){
		transform:scale(0.97);
	}
}


.post-feed .accordion-button::after{
    top:14px;
    left:20px;
    @media screen and (max-width:$desktop){
        top:10px;
        right:10px;
    }
}

.post-feed .feed-buttons{
    margin-bottom:0px;
    @media screen and (max-width:$desktop){
        margin-top:0rem;
    }
}

.change-top-image-radio{
	width:20px;
}

.pick_top_image_image{
	width:calc(100% - 20px);
	padding:1rem;
	padding-left:2rem;
}

.pick_top_image_image_2{
	width:100%;
	padding:1rem;
}

.change-top-image-accord-btn{
	background-color:#0d63d4;
	color:white;
	padding:1rem;
	margin-left:auto;
	border-radius:0.25rem;
	margin-top:1rem;
	width:max-content;
	padding-top:0.5rem;
	padding-bottom:0.5rem;
}

.edit-text-text{
    margin-top:auto;
    margin-bottom:auto;
    margin-right:0.4rem;
    color:$darkest-dark;
}

// Create Post Page /////////////////////////////////////
// Post Page @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@   @@@@@@@@@@@@@@@@@@@@@@@@                                     @@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@   @@@@@@@@@@@@@@@@@@@@@@@@       @@@@@@@@@@@@@@@@@@@@@@@@      @@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@   @@@@@@@@@@@@@@@@@@@@@@@@   @    @@@@@@@@@@@@@@@@@@@@@   @    @@@@@@@@@@
// @@@@@@@@@                        @@@@@@@@@@@@@   @@@    @@@@@@@@@@@@@@@@    @@@    @@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@   @@@@@@@@@@@@@@@@@@@@@@@@   @@@@@@    @@@@@@@@@@    @@@@@@    @@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@   @@@@@@@@@@@@@@@@@@@@@@@@   @@@@@@@@    @@@@@@     @@@@@@@    @@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@   @@@@@@@@@@@@@@@@@@@@@@@@   @@@@@@@@@@@         @@@@@@@@@@    @@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@                                     @@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

.topic-icon-not-a-cat{
	margin-left:auto
}

.minor-cats-talking{
	color:gray;
	font-size:11px;
}

.minor-cats-in-a-box{
	margin-top:5px;
	margin-bottom:5px;
	margin-left:2rem;
}

.minor-cat-row{
	margin-left:1rem;
}

.minor-cat-row .white-circle .follow-icon{
	width:14px;
}

.minor-cat-row .white-circle{
	margin-right:5px;
}

.my-awesome-search-box{
	border: $grey-border 1px solid !important;
}

.select-topics .abs-card{
	padding:0rem !important;
}

.user-select-top .abs-card{
	padding:1.5rem !important;
}

.topic-find-one{
	border-radius:0.1rem 0.1rem 0.1rem 0.1rem;
	margin-bottom:0.2rem !important
}

.find-topic-search{
	display:flex;
	margin-left:1rem;
}

.find-topic-search .search_img{
	width:28px;
}

.settings-main-card .mf_editor{
	border: 1px solid #eaeaea;
	border-radius:0.25rem;
}

.phn-text-adj{
    @media screen and (max-width:$phone){
        font-size: 11px !important;
    }
}

.phn-text-adj.text-lrg{
    @media screen and (max-width:$desktop){
        font-weight:600;
    }
}

.new-area-header{
    background-color: white !important;
    color:$darkest-dark !important;
	margin-bottom:5px !important;
	border-radius:0.25rem;
	padding-left: 1.5rem !important;
}

.new-area-header p{
	font-weight:600;
	font-size:22px;
}

.new-area-header-icon{
    width:50px;
    margin-right:1rem;
    @media screen and (max-width:$desktop){
        width:35px;
        margin-right:0.75rem;
    }
}

.create-post-page{
	width:100%;
	min-height:100vh;
	// background-color:white;
	display:flex;
}

.centered-notification{
	position:relative;
	margin-left:auto;
	margin-right:auto;
	background-color:white;
	margin-top:2rem;
	z-index:5;
	padding: 2rem;
    border-radius: 0.1rem;
	width:60%;
	max-width: 800px;
	@media screen and (max-width:$large-desktop) and (min-width:$small-desktop){
		width: 75%;
	}
	@media screen and (max-width:$small-desktop) and (min-width:$phone){
		width: calc(100% - 2rem);
	}
	@media screen and (max-width:$phone){
        width:100%;
		// margin-left:1rem;
		// margin-right:1rem;
		margin-top:0rem;
    }
}

.centered-notification + .centered-notification{
	margin-top:1px;
}

.centered-notification + .post-page-center{
	margin-top:1px;
}

.centered-notification-cross{
	width: 20px;
    right: 1rem;
    top: 1rem;
    position: absolute;
}


.post-page-center{
	width:60%;
	max-width: 600px;
	margin-left:auto;
	margin-right:auto;
	margin-top:2rem;
	background-color: white;
    z-index: 4;
    padding: 2rem;
    border-radius: 0.25rem;
	@media screen and (min-width:$xxx-desktop){
        // width:calc(1600px * 0.8);
		width:calc(var(--large) * 0.90);
    }
    @media screen and (max-width:$xxx-desktop) and (min-width:$xx-desktop){
        // width:calc(1540px * 0.8);
		width:calc(var(--large) * 0.90);
    }
    @media screen and (max-width:$xx-desktop) and (min-width:$x-desktop){
        // width:calc(1480px * 0.8);
		width:calc(var(--med-large) * 0.80);
    }
    @media screen and (max-width:$x-desktop) and (min-width:$huge-desktop) {
        width:calc(var(--med-large) * 0.80);
    }
    @media screen and (max-width:$huge-desktop) and (min-width:$large-desktop){
		width:calc(var(--med) * 0.85);
    }
    @media screen and (max-width:$large-desktop) and (min-width:$big-desktop){
        width:calc(var(--med) * 0.85);
    }
    @media screen and (max-width:$big-desktop) and (min-width:$desktop){
        width:calc(var(--med-small) * 0.80);
    }
    @media screen and (max-width:$desktop) and (min-width:$small-desktop) {
        width:calc(var(--small) * 0.70);
    }
    @media screen and (max-width:$small-desktop) and (min-width:$tablet){
		margin-top:0rem;
		padding: 1rem;
		width:calc(100vw );
    }
    @media screen and (max-width:$tablet) and (min-width:$phone){
		margin-top:0rem;
		padding: 1rem;
        width:calc(100vw );
    }
    @media screen and (max-width:$phone){
		margin-top:0rem;
		padding: 1rem;
        width:calc(100vw);
        // min-width:none;
    }
}

.create-post-btn-row{
	gap:0.5rem;
	margin-bottom:2rem;
	justify-content: end;
}

.submit-post-btn{
	border: solid 2px #eaeaea;
	border-radius:2rem;
	background-color: white;
    margin-left: 2rem;
	min-width:40px;
	min-height:40px;
	@media screen and (max-width:$desktop){
        margin-left:auto;
    }
}

.submit-post-btn:hover{
	background-color:#eaeaea;
	transform: scale(0.97);
	cursor:pointer;
}

.createBtnContainer{
	@media screen and (max-width:$desktop){
        margin-left:auto;
    }
}

.create-post-title{
	font-size:26px; 
	font-weight:600;
	margin-bottom:0.5rem;
}

.create-post-accord-btn{
	display:flex;
	padding:0.5rem;
	padding-left:1rem;
	padding-right:1rem;
	background-color:$bright-blue;
	background-color: #48a0cf;
	color:white;
	border-radius:0.5rem;
}

.create-post-accord-btn.collapsed{
	background-color:#eaeaea;
	color:black
}

.create-post-accord-btn:hover{
	transform: scale(0.97);
	cursor:pointer;
}

.stickyWord{
	position:sticky;
	bottom:1rem;
}

.wordCount{
	padding:0.5rem;
	padding-left:1rem;
	padding-right:1rem;
	background-color:#eaeaea;
	border-radius:2rem;
	margin-left: auto;
}

.wordCountCount.over-eight-hundred{
	background-color:#eaeaea;
}

.wordCountCount.at-eight-hundred{
	background-color:#eaeaea;
}

.over-eight-hundred{
	background-color:#ff6464;
}

.at-eight-hundred{
	background-color:orange;
}

.back-create-post h1{
	font-size:1.5rem;
}

.over9000{
	display:none;
}

.wordCountCount.over-eight-hundred .over9000{
	display:inline-block;
	margin-left:5px;
}

.create-post-title-input{
	@extend .abs-card;
	padding-top:0rem !important;
	padding-bottom:0rem !important;
	padding-left:1.5rem !important;
	padding-right:1.5rem !important;
	position:relative;
	// border-bottom: 1px solid #eaeaea;
	@media screen and (max-width:$desktop){
        padding-left:0rem !important;
		padding-right:0rem !important;
		padding-top: 0rem !important;
    }
}

// .create-post-title-input::after{
// 	content: "";
//     position: absolute;
//     bottom: 0rem;
//     left: -2rem;
//     height: 1px;
//     background-color: #eaeaea;
// 	width:calc(100% + 4rem);
// }

.title-form{
	font-size:28px !important;
	color: #282828 !important;
	font-weight:600;
	padding-left:0rem !important;
	padding-right:0rem !important;
	padding-top:0rem !important;
	padding-bottom:0rem !important;
}

.back-grey{
	background-color:$back-grey;
	border-radius:0.25rem;
}

.my-end-of-page-button{
	margin-top:5px;
	background-color:white !important;
	padding: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin-left: auto;
}

.my-end-of-page-button:hover{
	transform: scale(0.97);
	cursor:pointer;
}

.floating-article-info{
	position: absolute;
    background-color: rgb(255, 255, 255);
	border:1px solid #d0d0d0;
	border-radius: 0.5rem;
    padding: 1rem;
    bottom: 4rem;
    right: 0.5rem;
	max-width:300px;
}

.article-info-btn{
	padding:0.1rem;
}

.article-info-btn .inTextIcon2{
	width:18px;
	height:18px;
	margin-right:0px;
	margin-bottom:3px;
}

.academic-box{
	border-top:1px solid gray;
	border-bottom:1px solid gray;
	padding-top:1rem;
	padding-bottom:1rem;
	margin-bottom:1.5rem;
}

.post-title.post-article{
	font-size: 42px;
    text-align: center;
    font-family: "Times New Roman", Times, serif;
}

.academic-view-text{
	font-weight:300;
	line-height: 1.1 !important;
    margin-bottom: 0.25rem !important;
    font-size: 15px;
    text-align: center;
	font-family: "Times New Roman", Times, serif;
}

.form-control{
	border:none;
	border-radius:0.25rem;
}

.form-control-adjust{
    border-radius: 0.25rem;
    border:none;
    padding-top:1rem;
    padding-bottom:1rem;
    padding-left:1rem;
    padding-right:1rem;
    height:inherit;
    background-color: white !important;
    font-size:16px;
    @media screen and (max-width:$desktop){
        padding-top:1rem;
        padding-bottom:1rem;
        padding-left:1rem;
        padding-right:1rem;
        font-size: 15px;
        border-radius: 0rem 0.1rem 0.1rem 0rem;
    }
}

.new-front-cat-row{
	padding:0.5rem;
	margin-left:2rem;
}

.new-front-cat-row:hover{
	background-color:#eaeaea;
	cursor:pointer;
}

.new-front-cat-container{
	padding-top:0.5rem;
	padding-bottom:0.5rem;
}

.new-front-cat-icon{
	height:25px;
	width:auto;
	margin-right:1rem;
}

.form-control:focus{
    box-shadow:none !important;
    // border: $base-blue 1px solid;
}


// in conversations
.btn-card{
    margin-left:auto;
    border-radius: 0.25rem;
    border:none;
    padding-top:0.5rem;
    padding-bottom:0.5rem;
    padding-left:1.5rem;
    padding-right:1.5rem;
    background-color: $base-blue !important;
    font-size: 15px;
    color:$darkest-dark;
    @media screen and (max-width:$desktop){
        padding-top:0.5rem !important;
        padding-bottom:0.5rem !important;
		border-radius:0.1rem;
        padding-left:0.5rem;
        padding-right:0.5rem;
		font-size: 14px;
    }
}
.btn-card:hover{
    // background-color: #dddddd !important;
	transform: scale(0.95);
    color: $darkest-dark;
}

.btn-card-lrg{
    @extend .btn-card;
    @media screen and (max-width:$desktop){
        padding-top:0.75rem !important;
        padding-bottom:0.75rem !important;
        padding-left:1rem;
        padding-right:1rem;
    }
}

.no-select-card{
	background-color:$light-grey-background !important;
	padding:0.5rem !important;
	max-width:100%;
	display:flex;
	justify-content: left !important;
	margin-bottom: 2px;
}

.select-card{
	@extend .no-select-card;
}

.select-card:hover{
	transform:scale(0.97);
	cursor:pointer;
}


.select-text{
    display:flex;
    margin-top:auto;
    margin-bottom: auto !important;
    margin-left:0.5rem;
	width:90%;
}

.white-circle{
    display:flex;
    background-color: white !important;
    border-radius: 3rem;
    padding: 1px;
    width: fit-content;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;



}
.white-circle .follow-icon{
    padding:0px !important;
    margin:none !important;
    width:18px;
	@media screen and (max-width:$desktop){
        width:18px;
    }
}

.waveBtn{
	border:none !important;
}

.liquid-user-page-text{
	text-align: end;
	margin-bottom:0rem;
}

.liquid-user-page-text:hover{
	color:#007fc7;
}

.userPage_liquid{
	padding-top: 0rem;
    border-bottom:1px solid #eaeaea;
}

.liquid_trust_settings{
	margin-bottom:2rem;
}

.liquid-settings-trust-row{
	padding: 1rem;
    background-color: #ededed;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 2px;
    border-radius: 0.25rem;
}

.liquid-settings-text{
	margin-bottom:0rem;
	display:inline;
}

.super-sml-text{
	font-size:10px;
	margin-bottom:0rem;
}

// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$      $$$$$$$$$$$$     $$$$$$$$$$              $$$$$$$$$$$
// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$     $$$$$$$$$$     $$$$$$$$$$     $$$$$$$$$$$$$$$$$$$$$      
// Competitive Summary Area ####################      ######      ############      ###################
// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$     $$$     $$$$$$$$$$$$$$$$$          $$$$$$$$$$$$
// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$           $$$$$$$$$$$$$$$$$$$$$$$      $$$$$$$$$$$
// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$      $$$$$$$$$$$$$$$$$$$$$$$$$$     $$$$$$$$$$$
// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$   $$$$$$$$$$$$$$$$$$$            $$$$$$$$$$$$
// ###################################################################################################


//////// Bubble Feed
/// 
/// 
/// 
/// 
/// 

.feed-master{
	background-color: #e6ebec;
	border-radius:0.25rem;
	@media screen and (max-width:$desktop){
		background-color:#00000000;
	}
}

.minimum-page-height{
	min-height:100vh;
}

.bubble_feed .menu-card{
	width:auto;
}

// .bubble_feed .menu-card:first-child{
// 	margin-left:-4px;
// }
// doesn't work on main

.bubble_feed .button_bar_my_class{
	justify-content: left !important;
	background-color:white;
	padding:1.25rem;
	padding-left:1.5rem;
	padding-right:1.5rem;
	@media screen and (max-width:$desktop){
		padding:1.25rem;
        padding-left:1.25rem;
		padding-right:1.25rem;
		padding-top:0.75rem;
		border-radius:0rem;
    }
}

.bubble_feed .bubble_butt .menu-writing{
	margin:0rem !important;
	
}

.bubble_feed .menu-writing{
	@media screen and (max-width:$phone){
        font-size:14px;
    }
}

.bubble_feed .menu-writing-underline{
	display:none;
}

.bubble_feed .bubble_butt{
	padding:0.25rem;
	border-radius:0rem !important;
	padding-top:0.25rem !important;
	padding-bottom:0.25rem !important;
	margin-bottom: auto;
    margin-top: 0rem;
	overflow:hidden;
	@media screen and (max-width:$desktop){
		margin-top:0rem;
        padding-left:0rem;
		padding-right:0rem;
    }
}

.bubble_feed .bubble_butt:hover{
	padding:0.75rem;
	padding-top:0.25rem !important;
	border-radius:0.25rem 0.25rem 0rem 0rem !important;
	padding-bottom:0.25rem !important;
	margin-bottom: auto;
	overflow:hidden;
	@media screen and (max-width:$desktop){
		margin-top:0rem;
        padding-left:0.25rem;
		padding-right:0.25rem;
    }
}


.bubble_feed .bubble-red{
	border-bottom: 1.5px solid #e76558;	
	border-color: #e76558;
}

.bubble_feed .bubble-red:hover{
	// border: 2px solid #d5958d;
	background-color: #fbe3e0 !important;
	// background:linear-gradient(0deg, #efd7d3 0px, #fbe3e0 4px);
}

.bubble_feed .bubble-orange{
	border-bottom: 1.5px solid $base-orange;
	border-color: $base-orange
}

.bubble_feed .bubble-orange:hover{
	background-color: #fff0de !important;
	// background:linear-gradient(0deg, #efdfcb 0px, #fff0de 4px);
	// border: 2px solid $base-orange;
}

.bubble_feed .bubble-blue{
	border-bottom: 1.5px solid $base-blue;
	border-color: $base-blue;
}


.bubble_feed .bubble-blue:hover{
	// border: 2px solid $base-blue;
	background-color: #e5f3fb !important;
	// background:linear-gradient(0deg, #ccdce5 0px, #e5f3fb 4px);
}

.bubble_feed .bubble-green{
	border-bottom: 1.5px solid #00b58d;
	border-color: #00b58d;
}


.bubble_feed .bubble-green:hover{
	// border: 2px solid #89cbbc;
	background-color: #d8f1e5 !important;
	// background:linear-gradient(0deg, #c6e5de 0px, #d5f1eb 4px);
}


.comment-feed-row .bubble-red{
	border-bottom: 1.5px solid #e76558 !important;	
	border-color: #e76558;
}

.comment-feed-row .bubble-blue{
	border-bottom: 1.5px solid $base-blue !important;
	border-color: $base-blue;
}


@keyframes move-strip {
	0%   {transform: scale(1); left:-9rem; opacity:100%;}
	80% {transform: scale(1); left: 0rem; opacity:100%;}
	100% {transform: scale(1); left: 0rem; opacity:0%;}
}

.color-strip{
	position:absolute;
	left:-9rem;
	top:0rem;
	width:100%;
	height:100%;

	animation-name: move-strip;
 	animation-duration:0.7s;
	animation-timing-function:ease-out;
}

.strip-red{
	background-color:#e76558;
}

.strip-blue{
	background-color:$base-blue;
}

.strip-orange{
	background-color:$base-orange;
}

.strip-green{
	background-color:#1fc39e;
}

///////// TOPIC CONTExT AREA
/// 
/// 

$topic-context-color:#d1d1d1;

.horz-scroll-arrow-container{
	position: absolute;
    width: calc(2.5rem + 14px);
    height: calc(1rem + 23px);
    right: -2rem;
    bottom: calc(1rem + 2px);

	padding:0.5rem;
	padding-right:2rem;
    background-color: white;
    z-index: 60;
	@media screen and (max-width:$desktop){
       right:-2rem;
	   padding-right:1rem;
	   width: calc(3rem + 14px);
	   bottom: calc(1.5rem - 5px);
	   background: linear-gradient(90deg, transparent, white 50%);
    }
}

.horz-scroll-arrow-container .horz-scroll-arrow{
	@media screen and (max-width:$desktop){
		display:none;
	}
}

.horz-scroll-arrow-container-left{
	width: calc(2rem + 14px);
    height: calc(1rem + 23px);
	padding: 0.5rem;
	padding-left:1rem;
	padding-right:1rem;
	background-color:white;
	border-bottom: 1px solid $topic-context-color;
}

.horz-scroll-arrow-container-left:hover{
	background-color:#eaeaea;
	cursor:pointer;
}

.horz-scroll-arrow-container:hover{
	@media screen and (min-width:$desktop){
		background-color:#eaeaea;
		cursor:pointer;
	 }
}

.topic-context-bar-container-container{
	position:relative;
	margin-bottom:0rem;
}

.topic-context-bar-container{
	margin-top:3rem;
	margin-bottom:0rem;	
	-ms-overflow-style:none;
	scrollbar-width: none;
	overflow-x:scroll;
	box-shadow: 0px -1px 0px 0px inset $topic-context-color;
}

.topic-context-bar-container-container ::-webkit-scrollbar{
	display:none;
}


.post-page-post .topic-context-bar-container{
	width: calc( 100% + 6rem);
    position: relative;
    right: 3rem;
	margin-bottom:1rem;
	@media screen and (max-width:$desktop){
		width: calc( 100% + 3.5rem);
        right:2rem;
		margin-bottom:1rem;
		margin-top:2rem;
    }
}

.topic-context-bar{
	padding-left:1.5rem;
	padding-right:1.5rem;
	white-space:nowrap;
	width:max-content !important;
	@media screen and (max-width:$desktop){
        padding-left:1rem;
		padding-right:1rem;
    }
}

.version_dropdown_container{
	position:absolute;
	bottom:1rem;
	left:-1.5rem;
	z-index:105;
	background:white;
	border-top: 2px solid white;
	@media screen and (max-width:$desktop){
        left:-0.8rem;
		bottom:1rem;
    }
}

.version_dropdown_btn::after{
	content:none !important;
}

.version_dropdown_btn::before{
	color:#595959;
	display: block;
	margin-top:0.5rem;
	margin-left:0.35rem;
	content: "";
    display: inline-block;
    border-top: 0.25rem solid;
    border-right: 0.2rem solid transparent;
    border-bottom: 0;
    border-left: 0.2rem solid transparent;
    vertical-align: 0.08rem;
    margin-right: 0.5em;
    transform: rotate(-90deg);
    transition: transform 0.3s ease;
	@media screen and (max-width:$phone){
		margin-top:auto;
		margin-bottom:auto;
        margin-right: 0.3rem;
		margin-left:0rem;
    }
}

.version_dropdown_btn.show::before{
	transform: rotate(0deg);
}

.version_dropdown_btn{
	border-bottom: 1px solid $topic-context-color;
	border-left: 0px;
    border-right: 0px;
    height: inherit;
	min-height:100%;
    padding: 0.5rem;
    border-radius: 0rem;
	padding-left:20px;
	display:flex;
	flex-direction:row;
	@media screen and (max-width:$desktop){
        padding-left:0.9rem;
    }
}

.version_dropdown_btn:hover{
	background-color:#eaeaea;
}

.version_dropdown{
	left:1rem;
}
.version_dropdown p{
	font-size:11px;
}
.version_dropdown a{
	color: $darkest-dark;
	padding:1rem;
	padding-top:0.5rem;
	padding-bottom:0.5rem;
}

.version_dropdown a:hover{
	color: $link-blue-two;
}

.margin-left-25{
	margin-left:calc(3rem + 56px);
	@media screen and (max-width:$desktop){
        margin-left:calc(3rem + 45px);
    }
}

.topic-context-bar-selected{
	// margin-left:0.25rem;
	// margin-right:0.25rem;
	display:inline-block;


	width:auto;
	padding:1rem;
	padding-top:0.4rem;
	padding-bottom:calc(0.4rem + 2px);
	background-color: white;
	border-radius: 0.25rem 0.25rem 0rem 0rem;
	border-top: 1px solid $topic-context-color;
	border-left: 1px solid $topic-context-color;
	border-right: 1px solid $topic-context-color;
	border-bottom: 1px solid white;
	z-index:40;
	// @media screen and (max-width:$desktop){
    //     padding-left:0.75rem;
	// 	padding-right:0.75rem;
    // }
}

.topic-context-bar-not-selected{
	// margin-left:0.25rem;
	// margin-right:0.25rem;
	display:inline-block;
	border-bottom:1px solid $topic-context-color;
	width:auto;
	padding:1rem;
	padding-top:0.5rem;
	padding-bottom:0.5rem;
	border-radius: 0.25rem 0.25rem 0rem 0rem;
	// @media screen and (max-width:$desktop){
    //     padding-left:0.75rem;
	// 	padding-right:0.75rem;
    // }
}

.topic-context-bar-not-selected:last-child{
	margin-right:2rem;
}

.topic-context-bar-selected:hover{
	cursor:pointer;
	background-color: #eaeaea;
}

.topic-context-bar-not-selected:hover{
	cursor:pointer;
	background-color: #eaeaea;
}


.topic-context-bar-underline{
	width: 500%;
    height: 2px;
    background-color: #eaeaea;
}

.topic-context-icon{
	width:16px;
	height:16px;
	margin-top:auto;
	margin-bottom:auto;
}

.topic-context-text{
	margin-top:auto;
	margin-bottom:auto;
	padding-left:0.35rem;
	// padding-right:0.5rem;
	font-size: 11px;
	color:$darkest-dark;
	line-height:1.5 !important;
}

.rival-dropdown-area{
	padding:1rem;
	margin-bottom:1rem;
	border-left: 2px solid #d5958d;
}

.rival-dropdown-area ul{
	margin-bottom:0.5rem;
	padding-left:1rem !important;
	
}

.champ-dropdown-area{
	@extend .rival-dropdown-area;
	border-left: 2px solid $base-orange;
}

.grey-back-rival .rival-dropdown-area{
	margin-bottom:1.5rem;
}

.rival-dropdown-area ul:last-child{
	margin-bottom:0rem !important;
}

///////////////////////////////////////////////////////
/// 

//// Topic Nudge to Email follow & Share


.nudgeAlert{
	width:80%;
	max-width:800px;
	position:fixed;
	top:50%;
    left:50%;
    transform: translate(-50%, -50%);
	background-color:white;
	z-index: 600;
    padding: 2rem;
	box-shadow: -2px 2px 20px 2px #40404040;
}


.nudgeAlert-image{
	max-width:100%;
	width:400px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:1rem;
}

.nudgeAlert-btn{
	width:fit-content;
	min-width:155px;
	background-color:$link-blue;
	color:white;
	border:none !important;
	padding-left:0.75rem;
	padding-right:0.75rem;
	padding-top:0.25rem;
	padding-bottom:0.25rem;
	border-radius:0.25rem;
}

.nudgeAlert-resend{
	@extend .nudgeAlert-btn;
	margin-top:1rem;
	margin-left:auto;
}


.nudgeAlert-close{
	position: absolute;
    right: 1rem;
    top: 1rem;
    background-color: white;
    border: none;
}

.nudgeAlert-textbox{
	width:100%;
	padding:0.5rem;
}

.summary_vote_top{
	gap:0.5rem !important;
	@media screen and (max-width:$desktop){
        gap:0.5rem !important;
    }
}

// SPARKLESSSSS!!!!!!!! ✨✨🌟⭐⭐🌟✨✨✨⭐🌟🌟✨✨✨✨🌟⭐💫✨✨🌟💫⭐⭐💫🌟✨

.sparkle-container{
	width:20px;
	height:20px;
	z-index:100;
	// background-color:yellow;
}

@keyframes sparkle_animation{
	0%   {transform: scale(1); opacity:100%; bottom:0px; left:0px;}
	100% {transform: scale(1); opacity:0%; bottom: var(--yEnd); left: var(--xEnd)}
}

@keyframes sparkle_vote_animation{
	0%   {transform: scale(0.6); opacity:100%;}
	100% {transform: scale(1); opacity:0%;}
}

.spark{
	opacity:0%;
	position:absolute;
	bottom:0px;
	left:0px;
	animation-name: sparkle_animation;
	animation-duration:2s;
}

.spark_vote_container{
	background-color:white;
	border-radius:2rem;
	box-shadow: -2px 2px 1px 0px #40404040;
	height: 40px;
	display:flex;
    width: 40px;
	border: 1px solid #b1b1b1;
	opacity:0%;
	animation-name: sparkle_vote_animation;
	animation-duration:2s;
}

.spark_vote{
	font-size:18px;
}

// end SPARKLESSSSS!!!!!!!! ✨✨🌟⭐⭐🌟✨✨✨⭐🌟🌟✨✨✨✨🌟⭐💫✨✨🌟💫⭐⭐💫🌟✨

.scoreboard{
	border:1px solid #d1d1d1;
	padding: 1rem;
    margin-top: 1rem;
    border-radius: 0.5rem;
}


.rival-voting-accord{
	// padding:1.5rem !important;
	background-color:white;
	border-radius: 0.25rem;
	margin-top:$cardGap;
}

.rival-voting-accord-top{
	padding-top:1.5rem;
	padding-left:2rem;
	padding-right:2rem;
	// padding-bottom:1.5rem; // 6 days until votes are counted (changed to make this area work)
	@media screen and (max-width:$desktop){
		// padding-top:1rem;
        padding-left:1rem;
		padding-right:1rem;
    }
}

.rival-voting-accord-bottom{
	// border-top:1px solid #eaeaea;
	padding-left:1.5rem;
	padding-right:1.5rem;
	padding-bottom:1.5rem;
	// padding-top:1rem;
	@media screen and (max-width:$desktop){
        padding-left:1rem;
		padding-right:1rem;
		// padding-bottom:1rem;
    }
}

.grey-scoreboard-container{
	padding:1rem;
	background-color:#f1f1f1;
	margin-top:1rem;
	margin-bottom:1rem;
}

.sb-icons{
	width:16px;
	margin-right:0.5rem;
}

.rival-vote-btn-row{
	justify-content:space-between;
}

.rival-vote-btn-row p{
    font-size: 11px;
}

.rival-vote-accord-btn{
	color:#1995d8;
}

.rival-vote-accord-btn:hover{
	cursor:pointer;
	transform:scale(0.96);
}

.yes-box-score{
	padding:0.25rem;
	padding-left: 1rem;
    padding-right: 1rem;
	border-radius: 1rem;
	margin-right:0.5rem;
	// background-color:#b0d8f0;
	border:1px solid $base-blue;
}

.no-box-score{
	@extend .yes-box-score;
	border:1px solid $base-orange;
}

.yes-box-score-green{
	@extend .yes-box-score;
	border:1px solid #8f8f8f;
}

.box-score-text{
	margin-bottom:0rem;
	font-weight:500;
	color: #5f5f5f !important;
}

.your-here-arrow{
	width:12px;
	height:12px;
	margin-left:0.5rem;
}

.new-front-text{
    font-size: 15px;
    color: #767676;
    font-weight: 700;
    margin-top:auto;
    margin-bottom:auto;
    text-align:center;
    padding-left:0.5rem;
    padding-right:0.5rem;
    @media screen and (max-width:$desktop){
        font-size:9px;

    }
}

.gap{
    gap:0.25rem;
    @media screen and (max-width:$desktop){
        gap:0rem;
    }
}

.gap-half{
	gap:2px;
}

.gap-25{
	gap:0.25rem
}

.mobile-gap{
    @media screen and (max-width:$desktop){
        gap:0.5rem !important;
    }
}

.context-header{
	margin-left: -1px;
    margin-right: -1px;
    width: calc(100% + 2px);
	margin-top:3rem;
	padding: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #f1f1f1;
    border-radius: 0.5rem !important;
	@media screen and (max-width:$desktop){
		margin-top:3rem; // changes bc mobile rival page with linkpreview above.
		margin-bottom:0rem !important;
    }
}

.details-rules{
	font-size: 11px;
}

.summary-title{
    width: 100%;
    margin-left: 1rem;
    margin-top:auto;
    margin-bottom: auto;
	display:flex;
	justify-content: space-around;
	flex-direction: column;
	// padding-top:0.75rem;
	// padding-bottom:0.75rem;
	@media screen and (max-width:$desktop){
        margin-left: 0.5rem;
    }
}

.summary-title h3{
	font-size:1.2rem;
	@media screen and (max-width:$desktop){
        font-size:16px;
    }
}

.summary-title-card{
	line-height:1.3rem;
	//padding:1rem;
	// margin-bottom:1rem;
	@media screen and (max-width:$desktop){
		//padding:0.75rem;
		// margin-bottom:0.75rem;
    }
}

.summary-title-card .my-span{
	font-size: 11px !important;
	font-weight:400;
}


//  _   _ ___  ___ _ __  (_) ___ ___  _ __
// | | | / __|/ _ \ '__| | |/ __/ _ \| '_ \
// | |_| \__ \  __/ |    | | (_| (_) | | | |
//  \__,_|___/\___|_|    |_|\___\___/|_| |_|

.lil_wrapper{
	display: flex;
    width:20px;
    height:20px;
    background-color: white;
    border-radius: 1rem;
    border:none;
    position:absolute;
    bottom:6px;
    right:6px;
	z-index:5;
    @media screen and (max-width:$desktop){
        // width:22px;
        // height:22px;
        // bottom:4px;
        // right:4px;
    }
}

.lil_icon{
	width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    display:flex;
	@media screen and (max-width:$desktop){
		width: 14px;
    	height: 14px;
	}
}

// Trying to figure out a bloody abstract method of having little trophies in front of user icons etc. 

// need to go to user-post-image when changing size in order to keep picture aligned in the post context. 
.big_wrapper{
    position: relative;
    width:44px;
    min-width:44px;
    display:flex;
}

.irl.big_wrapper{
	width: 24px;
    height: 24px;
    min-width: 24px;
    margin-left: 9px;
    margin-top: 1.25rem;
}

.big_wrapper .irl{
	object-fit:cover;
	width:100%;
	height:auto;
	border-radius:0.2rem;
}

.summary_vote_top .big_wrapper.irl{
	width: 34px;
    height: 34px;
    min-width: 34px;
    margin-left: 13px;
	margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
}

.sb-col-gap{
	gap:0.5rem;
}

.sb-row p{
	color:$darkest-dark !important;
}

a .sb-row:hover p{
	color:$link-blue !important;
}

.vote-text{
    font-size: 15px;
	font-weight:400;
	color:$darkest-dark !important;
    margin-top:auto;
    margin-bottom: auto;
    margin-right:1rem;
    @media screen and (max-width:$desktop){
        font-size: 14px;
        margin-right:0rem;
    }
}

.lpc-nor:hover{
	cursor:default;
}

.with_wrap{
    flex-wrap: wrap !important;
}

.checkbox-class{
    width:24px;
	height:24px;
	@media screen and (max-width:$desktop){
        width:20px;
		height:20px;
    }
}

.checkbox-class-2{
    width:16px;
    padding:0rem;
}

.postStatusBtn{
	// background-color:#cbe4f5;
	border:1px solid #c8c8c8;
	color:#061826;
	margin-left:0.5rem;
}

.postStatusBtn:hover{
	background-color:#f1f1f1 !important;
}

.postStatusMenu{
	background-color: #ffffff;
    border: 1px solid #d1d1d1;
    padding: 0.5rem !important;
	gap:0.25rem;
}

.postStatusMenu.show{
	display:flex;
	flex-direction:column;
}

.postStatusBtn:first-child{
	margin-left:0rem;
}

.tableOfContentsButton + .postStatusBtn{
	margin-left:0rem;
}

.dropdown-toggle.postStatusBtn::after{
	display:none;
}

.dropdown-toggle.postStatusBtn::before{
	content: "";
    display: inline-block;
    border-top: 0.25rem solid;
    border-right: 0.2rem solid transparent;
    border-bottom: 0;
    border-left: 0.2rem solid transparent;
    vertical-align: 0.08rem;
    margin-right: 0.5em;
    transform: rotate(-90deg);
    transition: transform 0.3s ease;
}

.show.dropdown-toggle.postStatusBtn::before {
	////
	transform: rotate(0deg); /* Reset rotation when dropdown is open */
}



.display-none.active{
	display:block !important;
}

.display-none.activeChanges{
	display:block !important;
}

.display-none.activeChanges.inactiveEditor{
	display:none !important;
}

.submit-edit-on-post-page{
	background-color:#348CCC;
	margin-left:0rem !important;
}

.submit-edit-on-post-page:hover{
	background-color:#0070c1 !important;
}

.postStatusItem.active{
	background-color:#eaeaea !important;
	border-radius:0.25rem;
}

.postStatusItem .floating-icon{
	margin-right:0.75rem;
}

.postStatusItem:hover{
	cursor:pointer;
	background-color:#eaeaea !important;
	border-radius:0.25rem;
}


.see-changes-icon{
	width:16px;
	height:16px;
	max-width:16px;
	margin-top:auto;
	margin-bottom:auto;
}

.mini-arrow-changes{
	width:10px;
	height:10px;
	margin-top:auto;
	margin-bottom:auto;
	margin-right:5px;
}
.see_changes_fvr{
	// margin-left:0.5rem;
	display:flex;
	@media screen and (max-width:$desktop){
        // margin-left:0.25rem;
    }
}

.floating_voting_row{
	position:sticky;
	top:1rem;
	width:100%;
	margin-top:0.4rem;
	z-index:100;
	margin-bottom:3rem;
	justify-content: flex-end;
	line-height:1;
	@media screen and (max-width:$tablet){
		margin-top:0rem;
	}
}

.floating_voting_row + h2{
	margin-top:0rem;
}

@keyframes vote-done-btn-dis {
	0%   {width:100%; height:100%; opacity:1; visibility: visible;}
	100% {width:0; height:0; opacity:0; padding:0; border:none; visibility: hidden;}
}


.vote_done_btn_fvr{
	border: 1px solid #b8b8b8;
	padding:1rem;
	padding-top:0.25rem;
	padding-bottom:0.25rem;
	margin-left:0.5rem;
	border-radius:1rem;
	display:none;
	width:max-content;

	@media screen and (max-width:$desktop){
        padding:0.75rem;
		padding-top:0.25rem;
		padding-bottom:0.25rem;
		margin-right:0.25rem;
    }
	
}

.exp-toggle{
	animation-name: vote-done-btn-dis;
	animation-delay:3s;
	animation-duration:2s;
	animation-fill-mode: forwards;
}

.exp-toggle .vote_done_btn_fvr{
	display:flex;
}

.vote_done_btn_fvr_2{
	@extend .vote_done_btn_fvr;
	display:flex;
}

.exp-toggle .vote_done_btn_fvr_2{
	display:none;
}

.vote_done_btn_fvr p{
	font-size:11px;
	margin-top: auto;
    margin-bottom: auto !important;
	opacity:1;
	transition: opacity 0.5s ease;
	text-wrap:nowrap;
}

.fog-padding{
	background-color: white;
    // backdrop-filter: blur(2px);
	padding:0.25rem;
	// padding-left:0.5rem;
	// padding-right:0.5rem;
	margin-top:-0.25rem;
	margin-bottom:-0.25rem;
	justify-content: start;
	border-radius:2rem;
	width:auto;
	transition: width 0.5s ease;
	display:inline-flex;
	// gap:0.5rem;
	// margin-left:auto;
}

.fog-padding .icon-color:hover{
	transform:none;
}

.yesChangesBtn{
	border: 1px solid #c8c8c8;
	background-color:white;
}

.noChangesBtn{
	border: 1px solid #c8c8c8;
	background-color:white;
}

.yesChangesBtn.voted{
	border: 1px solid #c8c8c8;
}

.noChangesBtn.voted{
	border: 1px solid #c8c8c8;
}

.voteDot{
	width: 4px;
    height: 4px;
    background-color: #1c95ef;
    border-radius: 1rem;
    display: inline-block;
    margin-right: 2px;
    vertical-align: middle;
}

.voteDotChamp{
	@extend .voteDot;
	background-color: $bright-orange;
}

.summary_vote_top .voteDot{
	margin-right:5px;
}

.not-selected-box{
	width:auto;
	// display:inline-block;
	padding:1rem;
	padding-top:0rem;
	padding-bottom:0rem;
	// border: 1px solid #dfdfdf;
	border-radius:1rem;
	@media screen and (max-width:$desktop){
        padding:0.75rem;
		padding-top:0rem;
		padding-bottom:0rem;
    }
}
// background-color:#ffc279;
// background-color:#63bdff;


.selected-box-rival{
	@extend .not-selected-box;
	border: 1.5px solid $base-blue;
	background-color:#e5f5ff;;
}

.selected-box-champ{
	@extend .not-selected-box;
	border: 1.5px solid $base-orange;
	background-color:#ffefdd;
}

// .summary_vote_top .truBlue{
// 	border: 1.5px solid $base-blue !important;
// 	background-color:#d7e6f5 !important;
// }

// .against_vote .vote-capsule-no.truBlue{
// 	border: 1.5px solid $base-blue !important;
// 	background-color:#d7e6f5 !important;
// }

// .for_vote .vote-capsule-yes.truBlue{
// 	border: 1.5px solid $base-blue !important;
// 	background-color:#d7e6f5 !important;
// }


.not-selected-box .user-icon-on-circle{
	bottom:-13px;
	top:auto;
}

.selected-box-rival .user-icon-on-circle{
	bottom:-13px;
	top:auto;
}

.selected-box-champ .user-icon-on-circle{
	bottom:-13px;
	top:auto;
}

.author-vote-text{
	text-align:start;
	font-weight:400;
	color:$darkest-dark !important;
	font-size:11px;
}

.post-page-post.rival-page{
    @media screen and (max-width:$desktop){
        padding-top:0.75rem;
        padding-bottom:0.75rem;
        margin-top:0rem;
        border-top:$grey-border 1px solid;
        border-bottom: $grey-border 1px solid;
    }
}

.mb-vote{
	font-size:22px;
	margin-bottom:0.5rem;
	@media screen and (max-width:$desktop){
		margin-bottom:0.5rem;
    }
}

.lh-15{
	line-height:1.5;
}


.lh-125{
	line-height:1.25;
}

.lh-11{
	line-height: 1.1;
}

.lh-1{
	line-height: 1;
}


.text-align-end{
	text-align: end;
}

// .scroll{
//     width:100%;
//     margin-top: 0.5rem;
//     margin-bottom: 0.5rem;
// }

.accordion-item{
    background-color:inherit;
    border:none;
}

.accordion-expand{
    transition-delay:  100ms;
    width: calc(100% - 45px - 0.4rem) !important;
    background-color: transparent !important;
    border-radius:0.25rem !important;
    margin:0rem !important;
    cursor:default;
    padding:0px !important;
    @media screen and (max-width:$desktop){
        width: calc(100% - 40px - 0.4rem) !important;
    }
}

.accordion-small{
    transition-delay:  100ms;
    width: 45px; 
    padding:0px;
    border-radius:0.25rem !important;
    cursor:pointer;
    overflow:hidden;
    @media screen and (max-width:$desktop){
        border-radius:0rem !important;
		width: 50px; 
    }
}

.putInCorner{
	right:0.5rem;
	top:0.5rem;
}

.grey-box{
    background-color: #ededed;
    border-radius: 0.25rem;
    margin-top:0.5rem;
    margin-bottom:0.5rem;
    padding-left:1rem;
    padding-right:1rem;
    padding-top:0.5rem;
    padding-bottom:0.5rem;
}

.checkbox{
    margin-top: auto;
    margin-bottom: auto;
}

.stars-width-in .gap-1rem{
	@media screen and (max-width:$desktop){
        gap:0.25rem;
    }
}

.stars-width-in.gap-1rem{
	@media screen and (max-width:$desktop){
        gap:0.25rem;
    }
}

.gap-1rem{
	gap:1rem;
}

//
// Diffy Buttons
//

.add-changes-btn{
	background-color:#eaeaea;
	padding:0.3rem;
	border-radius:2rem;
	margin-top: auto;
    margin-bottom: auto;
}

.add-changes-btn:hover{
	@media screen and (min-width:$desktop){
		background-color: #f1f1f1 !important;
    	transform: scale(0.96);
	}
}

.add-changes-btn.rc-toggle{
	background-color:#bae5ff;
}

.rc-toggle1{
	display:flex;
}

.rc-toggle2{
	display:none;
}

.rc-toggle .rc-toggle2{
	display:flex !important;
}

.rc-toggle .rc-toggle1{
	display:none !important;
}

//
//	DIFFY
//

.diff_text{overflow: auto}

.diff_removed_text{
	@extend .diff_text;
}
.diff_added_text{
	@extend .diff_text;
}

.diff_removed{
	display:none;
}

.see-removed p.diff_removed {
	display:inline-block;
	color: $diffy-remove;
	text-decoration:line-through;
	text-decoration-color: $diffy-remove-line;
	text-decoration-thickness: 1px;
	width: fit-content;
}

.see-removed span.diff_removed {
	display:inline;
	color: $diffy-remove;
	text-decoration:line-through;
	text-decoration-color: $diffy-remove-line;
	text-decoration-thickness: 1px;
	width: fit-content;
}

.see-removed ol.diff_removed{
	display:block;
	color: $diffy-remove;
	text-decoration:line-through;
	text-decoration-color: $diffy-remove-line;
	text-decoration-thickness: 1px;
	width: fit-content;
}

.see-changes h2.diff_added, .see-changes h3.diff_added{
	display:block;
	color: $diffy-add;
	width: fit-content;
}

.see-removed h2.diff_removed, .see-removed h3.diff_removed{
	display:block;
	color: $diffy-remove;
	text-decoration:line-through;
	text-decoration-color: $diffy-remove-line;
	text-decoration-thickness: 1px;
	width: fit-content;
}

.see-changes p.diff_added{
	display:inline-block;
	color: $diffy-add;
	width: fit-content;
}

.see-changes span.diff_added{
	display:inline;
	color: $diffy-add;
	border-radius:0.1rem;
	width: fit-content;
}

.see-removed .diff_removed.post_img_container {
	display:flex;
	background-color: white;
	padding:0;
	width:100%;
	position: relative;
	// padding-top:0.25rem;
	// padding-bottom:0.25rem;
	border-radius:0rem;
	max-width: calc(100%);
    margin-left: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 0rem;
}
.see-changes .diff_added.post_img_container{
	background-color: white;
	padding:0;
	width:100%;
	// padding-top:0.25rem;
	// padding-bottom:0.25rem;
	border-radius:0;
	max-width: calc(100%);
    margin-left: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 0rem;
	position: relative;
}


.see-changes .diff_added.post_img_container::after{
	content: url(../images/icons/ya.svg);
	position: absolute;
	top: 10px;
    right: 10px;
    width: 40px;
    height: 22px;
    padding: 0.1rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    background-color: white;
    border-radius: 1rem;
}

.see-changes .diff_removed.post_img_container::after{
	content: url(../images/icons/na.svg);
	position: absolute;
	top: 10px;
    right: 10px;
    width: 40px;
    height: 22px;
    padding: 0.1rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    background-color: white;
    border-radius: 1rem;
}

.see-removed .diff_removed .post_img {
	display:inline-block;
	background-color: white;
	border-radius:0.25rem;
	position:relative;
}

.see-changes .diff_added .post_img{
	background-color: white;
	position:relative;
	border-radius:0.25rem;
}


.see-removed .diff_removed li{
	display:list-item;
	color: $diffy-remove;
	// padding:2px;
	border-radius:0.25rem;
	position:relative;
	text-decoration:line-through;
	text-decoration-color: $diffy-remove-line;
	text-decoration-thickness: 1px;
}

.see-changes .diff_added li{
	color: $diffy-add;
	position:relative;
	border-radius:0.25rem;
}


.see-changes .diff_added p{
	color: $diffy-add;
	position:relative;
	border-radius:0.25rem;
}

.see-removed .diff_removed p{
	color: $diffy-remove;
	position:relative;
	text-decoration:line-through;
	text-decoration-color: $diffy-remove-line;
	text-decoration-thickness: 1px;
}

.see-changes .diff_added h2{
	color: $diffy-add;
	position:relative;
	border-radius:0.25rem;
}

.see-removed .diff_removed h2{
	color: $diffy-remove;
	position:relative;
	text-decoration:line-through;
	text-decoration-color: $diffy-remove-line;
	text-decoration-thickness: 1px;
}

.see-changes .diff_added h3{
	color: $diffy-add;
	position:relative;
	border-radius:0.25rem;
}

.see-removed .diff_removed h3{
	color: $diffy-remove;
	position:relative;
	text-decoration:line-through;
	text-decoration-color: $diffy-remove-line;
	text-decoration-thickness: 1px;
}

.see-removed li.diff_removed{
	display:list-item;
	color: $diffy-remove;
	// padding:2px;
	border-radius:0.25rem;
	position:relative;
	text-decoration:line-through;
	text-decoration-color: $diffy-remove-line;
	text-decoration-thickness: 1px;
}

.see-changes li.diff_added{
	color: $diffy-add;
	position:relative;
	border-radius:0.25rem;
}

.see-removed .diff_removed.post_img_container::before {
	display:none;
	content: "";
	position: absolute;
	z-index:20;
	top: 10px;
	left: 10px;
	width: 20px;
	height: 20px;
	background-image: url(../images/icons/Auxiliaries/min.svg);
	background-size: contain;
 	background-repeat: no-repeat;
	filter: invert(76%) sepia(63%) saturate(286%) hue-rotate(339deg) brightness(94%) contrast(93%);
}

.see-removed .diff_added.post_img_container::before {
	display:none;
	content: "";
	position: absolute;
	z-index:20;
	top: 10px;
	left: -10px;
	width: 20px;
	height: 20px;
	background-image: url(../images/icons/Auxiliaries/add.svg);
	background-size: contain;
  	background-repeat: no-repeat;
	filter: invert(74%) sepia(50%) saturate(262%) hue-rotate(159deg) brightness(90%) contrast(87%);
}

.see-removed .diff_removed.post_video_container {
	display:flex;
	width:100%;
	max-width: calc(100%);
	padding:0rem !important;
	position: relative;
	margin-left: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 0rem;
}

.see-changes .diff_added.post_video_container {
	display:flex;
	width:100%;
	max-width: calc(100%);
	padding:0rem !important;
	position: relative;
	margin-left: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 0rem;
}

.see-removed .diff_removed .post_video {
	display:inline;
	background-color: white;
	border:1px solid $diffy-remove;
	padding:2px;
	position:relative;
}

.see-changes .diff_added .post_video{
	background-color: white;
	border: 1px solid $diffy-add;
	padding:2px;
	position:relative;
}

.see-removed .diff_removed.post_video_container::before {
	display:none;
	content: "";
	position: absolute;
	z-index:20;
	top: 0rem;
	left: -27px;
	width: 20px;
	height: 20px;
	background-image: url(../images/icons/Auxiliaries/min.svg);
	background-size: contain;
 	background-repeat: no-repeat;
	filter: invert(76%) sepia(63%) saturate(286%) hue-rotate(339deg) brightness(94%) contrast(93%);
}

.see-removed .diff_added.post_video_container::before {
	display:none;
	content: "";
	position: absolute;
	z-index:20;
	top: 0rem;
	left: -27px;
	width: 20px;
	height: 20px;
	background-image: url(../images/icons/Auxiliaries/add.svg);
	background-size: contain;
  	background-repeat: no-repeat;
	filter: invert(74%) sepia(50%) saturate(262%) hue-rotate(159deg) brightness(90%) contrast(87%);
}

.see-changes .diff_added .topic_preview{
	border: 1px solid $diffy-add;
}

.see-changes .diff_removed .topic_preview{
	border: 1px solid $diffy-remove;
}


.see-changes .diff_added .topic_preview .post_img{
	border:none;
}

.see-changes .diff_removed .topic_preview .post_img{
	border:none;
}

.see-changes .diff_added .topic_preview .post_video{
	border:none;
}

.see-changes .diff_removed .topic_preview .post_video{
	border:none;
}

span.diff_removed{
	@extend .diff_removed_text;
}
span.diff_added{
	@extend .diff_added_text;
}
em.diff_added{
	@extend .diff_added_text;
}
em.diff_removed{
	@extend .diff_removed_text;
}
strong.diff_added{
	@extend .diff_added_text;
}
strong.diff_removed{
	@extend .diff_removed_text;
}
.diff_removed li{
	@extend .diff_removed;
}
.diff_added li{
	@extend .diff_added;
}

// ###################################################################################################
// ##########################################              ######################              ########
// Side area ################################              ######################              #######
// ##########################################              ######################              ########
// ##########################################              ######################              ########
// ####################################################################################################

.center-text{
	text-align: center;
}

.side{
    position:relative;
    width:100%;
    //scroll-behavior: smooth;
}

.sideTop{
    position:absolute;
    top:0px;
    left:0px;
    width:100%;
    z-index: 10;
}

.side_right{
    padding-left:1rem;
    padding-right:1rem; 
    @media screen and (max-width:$tablet){
        padding-left:0rem;
        padding-right:0rem;
    }
}

.side_left{
    padding-left:1rem;
    padding-right:1rem; 
    @media screen and (max-width:$tablet){
        padding-left:0rem;
        padding-right:0rem;
    }
}

.luke_mad{
	overflow: hidden;
}

.sideTop_right{
    position:absolute;
    top:0px;
    right:0px;
    width:100%;
    // max-width:200px;
    z-index:1;
}

.sideTop_left{
    position:absolute;
    top:0px;
    left:0px;
    width:100%;
    // max-width:200px;
    z-index:1;
}

.topic_feed .button_bar_my_class{
	padding-left: 3rem;
	padding-right:3rem;
	border-radius:0rem !important;
	@media screen and (max-width:$desktop){
        padding-left: 1rem;
		padding-right:1rem;
    }
}

.topic_feed .post-title{
	margin-bottom:0.75rem !important;
}

.overflow-sucks{
	overflow:clip;
	min-height: calc(100vh - 7rem);
}

.left-card-side-offset{
    height: calc(200px - 2rem);
    width:100%;
}


.right-card-side-offset{
    height: calc(300px - 2rem);
    width:100%;
}

.left-card-side-offset + .sideCard{
	margin-bottom:1700px;
}

.card-sidespace{
    height:2000px;
    width:100%;
}

.art-sidespace{
    height:4000px;	//josh i changed this
    width:100%;
}

.art-sidespace-two{
    height:2500px; // has to equal (sideArt height + margin-bottom) / 2
    width:100%;
}

.sidespace{
    height:2500px;
    width:100%;
}

.sidespace-two{
    height:1500px;
    width:100%;
}

.sideCard_container{
	margin-top:400vh;
}

.left-card-side-offset + .sideCard_container{
	margin-top:0px;
}

.right-card-side-offset + .sideCard_container{
	margin-top:0px;
	height:400vh;
}

.right-card-side-offset{
	height:400vh;
}

.side_right .sideCard{
	top:calc(100px - 2rem) !important
}

.side_left:nth-child(3){
	margin-top:calc(400vh - (200px - 2rem));
}

// .my-white-ass-body .sideCard_container{
// 	margin-top:400vh;
// }

// .my-white-ass-body .left-card-side-offset + .sideCard_container{
// 	margin-top:0px;
// }

// .my-white-ass-body .right-card-side-offset + .sideCard_container{
// 	margin-top:0px;
// 	height:400vh;
// }

// .my-white-ass-body .right-card-side-offset{
// 	height:400vh;
// }

// .my-white-ass-body .side_right .sideCard{
// 	top:calc(100px - 2rem) !important
// }

// .my-white-ass-body .side_left:nth-child(3){
// 	margin-top:calc(400vh - (200px - 2rem));
// }

.sideCard_container{
	height:400vh;
	position:relative;
}

// .right-card-side-offset + .sideCard_container{
// 	height:600vh;
// }

.side_left .sideCard{
	@media screen and (min-width:$x-desktop){
		margin-right:5rem;
	}
}

.side_right .sideCard{
	top: calc(200px - 2rem);
	@media screen and (min-width:$x-desktop){
		margin-left:5rem;
	}
}


.sideArt-right{
    // max-width:350px;
    float: right !important;
	height:1000px;
    visibility: visible !important;
    margin-bottom:4000px;
    margin-left:auto;
	position:relative;
    width:80%;
    max-width:300px;

	@media screen and (max-width:$big-desktop) and (min-width:$tablet){
		width:100%;
	}
	@media screen and (max-width:$tablet){
        display:none;
    }

}

.sideArt-left{
    // max-width:350px;
	height:1000px;
    float:left;
    visibility: visible !important;
    margin-right:auto;
	position:relative;
    margin-bottom:4000px;
    width:80%;
    max-width:300px;
	@media screen and (max-width:$big-desktop) and (min-width:$tablet){
		width:100%;
	}
	@media screen and (max-width:$tablet){
        display:none;
    }


}


.signup-nudge-container{
	position:fixed;
	// border: solid 1px #c1c1c1;
	width:450px;
	bottom:1rem;
	right:1rem;
	background-color:white;
	// gap:1rem;
	padding:0rem;
	box-shadow: -2px 2px 2px 0px #40404040;
	z-index:1000;
	// border:1px solid #b0b0b0;
	border-radius:0.5rem;
	overflow:hidden;
	@media screen and (max-width:$phone){
		width:calc(100vw - 1rem);
		bottom:0.5rem;
        right:0.5rem;
		box-shadow: -2px 2px 5px 0px #40404040;
		// border:1px solid #b0b0b0;
    }
}

@keyframes slide-in {
	0%   {opacity: 100%; right:-400px; bottom:0rem; }
	100% {opacity: 100%; right:1rem; bottom:1rem; }
}


@keyframes slide-in-mobile {
	0%   {opacity: 100%; right:-400px; bottom:0rem; }
	100% {opacity: 100%; right:0.5rem; bottom:0.5rem; }
}

.slide-in{
	animation-name: slide-in;
	animation-duration:4s;
	animation-timing-function:ease-in;
	@media screen and (max-width:$desktop){
        animation-name: slide-in-mobile;
		animation-duration:4s;
		animation-timing-function:ease-in;
    }
}

.signup_nudge_logo_copyWriting{
	width: 240px;
    margin-left: -1rem;
    margin-bottom: -1rem;
    margin-top: -1.5rem;
}

.signup-nudge-img{
	height:auto;
	width:auto;
	position:relative;
}

.signup-nudge-img img{
	border-radius:0rem;
}

.signup-nudge-btns{
	position:absolute;
	bottom: 0.75rem;
    right: 1rem;
	gap:0.5rem;
}

.nudge-more-info-btn{
	display:flex !important;
	padding:0.25rem;
	padding-left:0.75rem;
	padding-right:0.75rem;
	border-radius:01rem;
	border:2px solid #0076cd;
	// background-color:#1f1f1f;
	color:#1f1f1f !important;
	@media screen and (max-width:$desktop){
		padding:0.1rem;
		padding-left: 0.5rem;
    	padding-right: 0.5rem;
		border:1px solid #0076cd;
	}
}

.nudge-su-btn{
	display:flex !important;
	padding:0.25rem;
	padding-left:0.75rem;
	padding-right:0.75rem;
	border-radius:01rem;
	background-color:#0076cd;
	color:white !important;
	@media screen and (max-width:$desktop){
		padding:0.1rem;
		padding-left: 0.5rem;
    	padding-right: 0.5rem;
	}
}

.nudge-su-btn p{
	font-size: 12px !important;
	@media screen and (max-width:$desktop){
		font-size: 10px !important;
	}
}

.nudge-more-info-btn p{
	font-size:12px !important;
	@media screen and (max-width:$desktop){
		font-size: 10px !important;
	}
}

.signup-nudge-textarea{
	display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
}

.signup-nudge-close{
	width:100%;
	height:0px;
	position:relative;
}

.signupNudge-close{
	filter: invert(55%) sepia(0%) saturate(0%) hue-rotate(252deg) brightness(100%) contrast(92%);
	display:flex;
	margin-left:auto;
	position:absolute;
	right: 8px;
    top: 8px;
	z-index:50;

}

.signupNudge-close:hover{
	filter:invert(54%) sepia(0%) saturate(1%) hue-rotate(196deg) brightness(93%) contrast(101%);
	transform:scale(0.97);
	cursor:pointer;
}

.blue-text-selectable:hover{
	color:#1877F2;
	text-decoration: underline;
	transform:scale(0.95);
	cursor:pointer;
}

.emptySideCard{
	max-width:280px;
    width: auto;
    height:auto;
    min-height:100px;
    margin-left:auto;
    margin-right: auto;
	margin-bottom:280vh;
	@media screen and (max-width:$desktop){
		width: 0px;
		height:0px;
		max-width:none;
		min-height:0px;
		margin-bottom:0rem;
		
	}
}

.empty_comment_feed{
	margin-bottom:$cardGap;
	padding-bottom: 1.5rem;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
	border-radius: 0.25rem !important;
}

.empty_comment_feed p{
	font-size:13px;
	font-weight:300;
}

.end-o-content{
	// height:1rem;
	width:100%;
	background-color:#89bdd9;
}

.exp-cc{
	position:relative;
	width:100%;
}

.exp-cc-2{
	position:absolute;
	height:calc( 55px + 2rem);
	width:100%;
	// background-color:#b2e4ff;
}

.more-to-explore{
	font-size:17px;
	margin-bottom:1rem;
	font-weight:600;
}

.bottom-o-feed{
	margin-top:5px;
	@media screen and (max-width:$desktop){
		border-radius: 0.25rem;
		margin-bottom: 5px;
	}
}

.bottom-o-feed .topic_preview h1{
	font-size:15px;
}

.bottom-o-feed .topic_preview .post_card_img{
	max-height:600px;
}
.selectable:hover{
	cursor:pointer;
	transform:scale(0.97) !important;
}

.selectable-2:hover{
	cursor:pointer;
	transform:scale(0.99) !important;
}

/// /////////////////////////////////////////////////
/// ////////////// ////////////////// ///////////// /
/// /////// LOL FUCK LOL FUCK LOL FUCK LOL ///// ////
/// ////////// Lets change the side card ///////////
/// /////////////////////////////////////////////////
/// ////////////////////////////////////////////////
.sidebar_dark{
	background-color:#282828 !important;
	font-weight: 300;
}

.sidebar_dark .accordion-button{
	background-color:#00000000 !important;
	font-weight: 300;
}

.sidebar_dark .accordion-button:not(.collapsed){
	background-color:#00000000 !important;
	font-weight: 300;
}

.sidebar_dark .sidebar_part{
	border-top:1px solid #404040 !important;
	font-weight: 300;
}

.sidebar_dark .sideCard-TitleTextTwo{
	color: #c1c1c1!important;
	font-weight: 400;
}

.sidebar_dark .sideCard-dark-link-text{
	color:#c1c1c1 !important;
	font-weight: 300;
}

.sidebar_dark .sidebar_top .sideCard-dark-link-text{
	font-weight: 300;
}

.sidebar_dark .side_cat_parent{
	color:#c1c1c1 !important;
	font-weight: 300;
}

.sidebar_dark .side_cat_more_options::before{
	border-top:0.3rem solid #a2a2a2 !important;
}

.sidebar_dark li{
	color:#c1c1c1 !important;
	font-weight: 300;
}

.sidebar_dark a{
	color:#c1c1c1 !important;
	font-weight: 300;
}

.sidebar_dark .competition-time-text{
	color:#c1c1c1;
	font-weight: 500 !important;
}

.sidebar_dark .signup-here-btn{
	color:#c1c1c1 !important;
	border: 1px solid #1c95ef;
	font-weight: 300;
}

.sidebar_dark .signup-here-btn:hover{
	color:#c1c1c1 !important;
	border: 1px solid #034d85;
	font-weight: 300;
}

.sidebar_dark .sideCard-explainer{
	border: none;
	background-color: white;
	font-weight: 400;
}

.sidebar_dark .explainer-text{
	color:$darkest-dark
}

.sidebar_dark li{
	color:#c1c1c1 !important;
	font-weight: 300;
}

.sidebar li{
	color:#505050 !important;
	font-weight: 400;
}

.sidebar_dark .follow-button:hover{
	background-color:#00000000 !important;
	font-weight: 300;
}

.sidebar_dark .follow-text{
	color:#c1c1c1 !important;
	font-weight: 300 !important;
}

.sidebar_dark .friend-button-request{
	background-color:#00000000 !important;
	font-weight: 300;
}

.sidebar .friend-button-request{
	padding-left:0px;
}

.sidebar_dark .icon-color{
	filter: brightness(0) saturate(100%) invert(93%) sepia(0%) saturate(519%) hue-rotate(215deg) brightness(85%) contrast(89%) !important;
}


.sidebar{
	z-index:1000;
	width:270px;
	position: sticky;
	top:0rem;
	left:0;
	height:100vh;
	overflow-y:auto;
	overflow-x: hidden;
	float: left;
	/* Hide scrollbar by default */
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE and Edge */
	@media screen and (max-width:$small-desktop){
		display:none;
	}
}

.sidebar-content{
	width: 260px;
	margin-right:10px;
	background-color:white;
	padding:2rem;
	height:6000px;
}

.sidebar_linkgroup{
	gap:0.25rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar::-webkit-scrollbar {
	display: none;
}
  
/* Custom scrollbar styles */
.sidebar.show-scrollbar::-webkit-scrollbar {
width: 10px;
display: block;
}

.sidebar.show-scrollbar::-webkit-scrollbar-track {
background: #f1f1f1;
}

.sidebar.show-scrollbar::-webkit-scrollbar-thumb {
background: #b0b0b0;
border-radius: 5px;
}

.sidebar.show-scrollbar::-webkit-scrollbar-thumb:hover {
background: #808080;
}

/* For Firefox */
.sidebar.show-scrollbar {
scrollbar-width: thin;
scrollbar-color: #b0b0b0 #f1f1f1;
}

/* Ensure smooth transition */
.sidebar {
transition: scrollbar-width 0.3s ease;
}

.sidebar_part{
	padding-top:2rem;
	padding-bottom:2rem;
	border-top:1px solid #eaeaea;
}

.sidebar_part ul {
	list-style: none; /* Remove default bullets */
	padding-left: 0; /* Remove default padding */
	gap: 0rem !important;
}

.sidebar_part li{
	background-image: url(../images/icons/Auxiliaries/dotOpen.svg);
	background-repeat: no-repeat;
	background-size: 18px 18px; /* Adjust bullet size */
	background-position: -2px 3px; /* Adjust bullet alignment */
	padding-left: 20px; /* Space for the bullet image */
	margin-bottom: 10px; /* Add space between list items */
	filter: invert(31%) sepia(0%) saturate(1760%) hue-rotate(139deg) brightness(102%) contrast(85%);
}

.sidebar_dark .sidebar_part li{
	filter: brightness(0) saturate(100%) invert(93%) sepia(0%) saturate(519%) hue-rotate(215deg) brightness(85%) contrast(89%);
}

.sidebar_top{
	padding-bottom: 2rem;
}

.sidebar_top p{
	color:#505050;
}

// .sidebar_part .filter-blue{
// 	filter:invert(31%) sepia(0%) saturate(1760%) hue-rotate(139deg) brightness(102%) contrast(85%) !important;
// }


.sidebar_part .ddd-accord-row{
	padding:0rem !important;
	background-color:#0000 !important;
}

.sidebar_part .ddd-accord-icon{
	width:18px !important;
	margin-right: 2px !important;
	padding-left: 1px;
    padding-right: 1px;
	margin-bottom: auto;
    margin-top: 4px;
}

.sidebar_part .reply_icon{
	width:18px !important;
	margin-right: 2px !important;
	padding-left: 1px;
    padding-right: 1px;
	margin-bottom: auto;
    margin-top: 4px;
}

.sidebar_part .dark-text{
	font-size: 15px;
    font-weight: 400;
    color: gray !important;
}

.sideCard{
    position: sticky;
	top:calc(100px - 2rem);
	margin-bottom: 3rem;

	display:flex;
    flex-direction:column;
    color:gray;
    background-color: white;
    border-radius: 0.25rem;
    border: none;
    // box-shadow:-20px 20px 15px 0px #26262626;
    max-width:280px;
    width: auto;
    height:auto;
    // min-height:100px;
    margin-left:auto;
    margin-right: auto;
    align-items:center;
	font-size: 15px;
	@media screen and (max-width:$tablet){
		font-size:14px;
		width: 100%;
		max-width:none;
		border-radius:0.1rem;
		margin-bottom:0rem;
		margin-top:2px;
		box-shadow: $common-box-shadow-mobile;
	}
}

.sideCard ul{
	padding-left:1rem !important;
}

.sideCard p{
	@media screen and (max-width:$desktop) and (min-width:$tablet){
		font-size:13px !important;
	}
}

.sideCard .my-span{
	@media screen and (max-width:$desktop) and (min-width:$tablet){
		font-size:13px !important;
	}
}

.sideCard-line{
	border-top:1px solid #eaeaea;
	width:100%;
}

.black-row-side-card{
	width:100%;
	padding:1rem;
	padding-bottom:0.25rem;
	background:white;
	border-radius:0.25rem 0.25rem 0rem 0rem;
	// border-bottom: 1px solid #eaeaea;
	@media screen and (max-width:$desktop) and (min-width:$tablet){
		padding:0.5rem;
		padding-bottom:0.25rem;
	}
}

.logo-side-top{
	width:35px;
	margin-left:1rem;
}

.sideCard-TitleText{
	color:$link-blue-two !important;
	margin-bottom:1rem !important;
	font-weight:500;
	font-size:16px;
	@media screen and (max-width:$tablet){
		margin-bottom:0.4rem !important;
		color:$darkest-dark !important;
		font-weight:600;
		font-size:15px;
	}
}

.sideCard-TitleTextTwo{
	color:#505050 !important;
	margin-bottom:0.5rem !important;
	font-weight:500;
	@media screen and (max-width:$tablet){
		color:#505050 !important;
		font-weight:600;
		font-size:15px;
	}
}

.sideCard-dark-link-text{
	color:#505050 !important;
}


.sideCard-dark-link-text:hover{
	color:$link-blue-two !important;
}

.side-card-info{
	width:16px;
	height:16px;
	margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
	@media screen and (max-width:$tablet){
		margin-left:5px;
		// margin-right:0.5rem;
	}
}

.signup-here-btn{
	padding: 1rem !important;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    //background: rgb(0, 130, 203) !important;
	border: 2px solid $base-blue;
    color: $darkest-dark !important;
    border-radius: 2rem;
    margin-right: auto;
	font-size:12px;
	@media screen and (max-width:$tablet){
		border: 2px solid $base-blue;
		margin-right:0rem;
		margin-top:0.5rem;
		font-size:12px;
	}

}

.signup-here-btn:hover{
	// background-color:#eaeaea;
	border:2px solid #0060a8;
}

.sideCardPadding .signup-here-btn{
	margin-top:0.25rem;
	@media screen and (max-width:$tablet){
		margin-top:1rem;
	}
}

.sideCard-linkContainer{
	margin-top:1rem;
	gap:0.25rem;
	display:flex;
}

.sidebar .follow-text{
	font-size: 15px;
	font-weight:400;
	color:#505050;
	@media screen and (max-width:$desktop){
	    font-size:14px;
	}
}

.sidebar .follow-button{
	width:max-content;
}

.sidebar .follow-button:hover{
	background-color:white;
}

.sidebar .follow-button:hover .follow-text{
	color: $link-blue !important;
}

.sidebar .follow-icon{
	width:18px;
}

.sidebar .btn{
	color:#505050;
}

.sidebar a{
	color: #505050;
}
.sidebar a:hover{
	color: $link-blue;
}

.sideCardPadding{
	padding:1rem;
	@media screen and (max-width:$desktop) and (min-width:$tablet){
		padding:0.5rem
	}
}

.sideCard-explainer{
	padding:0.75rem;
	border:1px solid #eaeaea;
	border-radius:0.25rem;
	margin-top:0.75rem;
}

.sideCard-explainer .my-flex-col{
	gap:0.5rem;
}

.explainerIcon{
	width:40px;
	height:40px;
	margin-top:auto;
	margin-bottom:auto;
	margin-right:10px;
}

.sidebar ol{
	list-style-position:inside;
	padding-left:0rem !important;
	display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.sidebar ul{
	list-style-position:inside;
	padding-left:0rem !important;
	display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.sidebar li:hover{
	transform:scale(0.97);
	cursor:pointer;
}

.trojan-edit{
	margin-top:-10px;
	margin-bottom:5px;
	@media screen and (max-width:$desktop) and (min-width:$tablet){
		margin-top:-5px;
	}
}

.oneHill{
	width:90%;
	margin-left:auto;
	margin-right:auto;
}

.sideCardImage{
	padding:0rem;
	padding-top:0rem;
	@media screen and (max-width:$desktop){
		padding-left:1.5rem;
		padding-right:1.5rem;
	}
}

.sideCard_topUsers .justify-content-right{
	justify-content: left; // Don't even with me right now. 💩💩💩

}

.sideCard_topUsers .irl.big_wrapper {
	width: 28px;
    height: 28px;
    min-width: 27px;
    margin-left: 2px;
    margin-right: 11px;
    margin-top: 7px;
    margin-bottom: 14px;
}

.sideCard_topUsers .big_wrapper{
	margin-left:-10px;
	width:52px;
	height:52px;
	margin-top:-4px;
}

.sideCard_topUsers .follow-icon{
	width:16px;
}

.sideCard_topUsers .user-icon-dropdown{
	position:absolute;
}

.sideCard_topUsers p{
	font-size:13px;
	margin-top:2px;
}

.sideCardOneLine{
	padding:1rem;
	padding-bottom: 2rem;
	padding-top:0rem;
}


.mobile-sidecard-frontview{
	width:100%;
	margin-top:2px;
}

.blank-menu-component{
	margin-top:0.25rem !important;
	margin-bottom:auto;
}

.pinned-menu-icon{
	width:15px;
	padding-right:1px;
	padding-left:1px;
	margin-right:0.3rem;
	display:none;
	@media screen and (max-width:$desktop){
		width:13px;
	}
}

.adjust-pinned-bigger{
	padding-right:0px;
	padding-left:0px;
}

.adjust-pinned-smaller{
	padding-right:2px;
	padding-left:2px;
}

.feed_bar_my_class .pinned_welcome{
	margin-bottom:0rem;
	padding-bottom:0rem;
	border-radius:0.25rem 0.25rem 0rem 0rem;
}

.pinned_welcome_container{
	background-color:$back-grey;
	padding-bottom:5px;
	@media screen and (max-width:$desktop){
		background-color:#cdd7de;
    }
}

.pinned_welcome{
	padding:1.25rem;
	padding-right:1.5rem;
	padding-left:1.5rem;
	background-color:white;
	border-radius:0.1rem;
	
	@media screen and (max-width:$desktop){
		padding:1rem;
		padding-left:1rem;
		padding-right:1rem;
		margin-top:5px;
	}
}

.pinned-text{
	border-radius:1rem;
	padding:0.75rem;
	padding-top:0.1rem;
	padding-bottom:0.1rem;
	background-color:#eaeaea;
	font-size: 13px;
	color:$darkest-dark;
	margin-bottom:0rem !important;
	@media screen and (max-width:$desktop){
		font-size:13px;
		background-color:#e7e7e7;
	}
 
}

.selectable:hover .pinned-text{
	background-color: #e6e6e6;
}

.pinned-text-container{
	border-radius:1rem;
	padding:0.75rem;
	padding-top:0.1rem;
	padding-bottom:0.1rem;
	background-color:#eaeaea;
	@media screen and (max-width:$desktop){
		font-size:13px;
		background-color:#e7e7e7;
	}
}

.pinned-text-container.selectable:hover{
	background-color: #e6e6e6;
}

.pinned-text-container p{
	font-size: 13px;
	color:$darkest-dark !important;
	margin-bottom:auto;
	margin-top:auto;
	@media screen and (max-width:$desktop){
		font-size:13px;
	}
}

.p_dot{
	width:6px;
	height:6px;
	border-radius:2rem;
	margin-right: 5px;
    margin-top: auto;
    margin-bottom: auto;
	margin-left:-3px;
}

.p_green{
	background-color:$base-green;
}

.p_blue{
	background-color:$base-blue;
}

.p_orange{
	background-color: $base-orange;
}

.pinned-flex{
	gap:0.5rem;
	column-gap: 0.5rem;
	flex-wrap:wrap;
}

.m-sc-fv-textSide{
	width:calc(75% + 0.2rem);
	padding:1rem;
	background-color:white;
	color:$darkest-dark;
}

.m-sc-fv-imgSide{
	width:calc(25% - 0.2rem);
	background-color:white;
	object-fit: cover;
	background-color:$hover-grey;
	display:flex;
}

.m-sc-fv-title{
	font-size: 15px;
	font-weight:600;
	margin-bottom:0.5rem;
	@media screen and (max-width:$desktop){
	    font-size:14px;
	}
}

.m-sc-fv-Image{
	width:auto;
	height:100%;
	object-fit: cover;
	border-radius:0rem;
}

.mobile-sidecard-postview{
	width:100%;
	margin-top:1px;
	height:auto;
	background-color:white;
	@media screen and (max-width:$desktop){
		background-color:white;
		margin-top: 0px;
		margin-bottom:0px;
		border-radius: 0rem;
		border-bottom: 1px solid #eaeaea;
	}
}

.m-sc-pc-mainSide{
	width:calc(100%);
	background-color:white;
	color:$darkest-dark;
	padding:1rem;
	display:flex;
	flex-direction: column;
	border-radius:$common-border-radius-right;
	@media screen and (max-width:$desktop){
		border-radius: 0rem;
		padding:1.25rem;
		padding-left:1.25rem;
		padding-right:1.25rem;
	}
}

.m-sc-pc-shortSide{
	width:40px;
    background-color: white;
	display:none;
	flex-direction: column;
	border-radius:$common-border-radius-left;
	justify-content: space-between;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
	@media screen and (max-width:$desktop){
		border-radius: $common-border-radius-right-mobile;
	}
}

.sideCardMainImg{
	width: 30px;
    margin-right: 1rem;
    border-radius: 0rem;
}

.m-sc-pv-Image{
	width:100%;
	max-width:400px;
	align-self:center;
	border-radius: 0rem;
	height: auto;
}

.m-sc-pv-title{
	font-size: 15px;
	font-weight:400;
	color:$link-blue-two;
	margin-bottom:0rem;
	@media screen and (max-width:$desktop){
	    font-size:14px;
	}
}

.m-sc-pv-sideIcon{
	z-index: 1;
    margin-top: -8px;
    width: 48px;
	max-width:60px;
    margin-left: 3px;
    margin-right: auto;
    display: flex;
	@media screen and (max-width:$desktop){
	    width:40px;
		margin-top:-5px;
		margin-left:5px;
	}
}

.m-sc-pc-mainSide .sideCardPadding{
	padding-left:0rem !important;
	padding-right:0rem !important;
}

.m-sc-pc-mainSide .black-row-side-card{
	padding-left:0rem !important;
	padding-right:0rem !important;
	padding-top: 0rem !important;
	padding-bottom:0rem !important;
	border-bottom:none !important;
}

.side_cat_accord .accordion-button{
	padding:0rem !important;
}

.side_cat_accord .side_cat_accord{
	margin-left:2rem;
	padding-top:0.75rem;
	padding-bottom:0.75rem;
}

.side_cat_parent{
	font-weight: 400;
    margin-bottom: 0rem;
    color: #505050 !important;
}

.side_cat_icon{
	width: 16px;
    height: 16px;
    margin-right: 0.5rem;
	@media screen and (max-width:$desktop){
		margin-bottom:3px;
	}
}

.side_cat_rotate{
	transform: scaleY(-1) rotate(-90deg);
}

.side_cat_accord.accordion{
	display: flex;
    flex-direction: column;
	gap:0.5rem;
	@media screen and (max-width:$desktop){
		margin-left:1rem;
		margin-bottom:1rem;
	}
}

.side_cat_more_options::before{
	content: "";
    display: inline-block;
    height: min-content;
    width: min-content;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0rem;
    border-top: 0.3em solid #4d4d4d;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    vertical-align: 0.255em;
    margin-right: 0.5em;
    transform: rotate(0deg);
    transition: transform 0.3s ease;
}

.collapsed.side_cat_more_options::before{
	transform: rotate(-90deg);
}



// SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS
// SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS   SSSSSSSSSSSSSSSSSSSSSSSSSSSS    SSSSSSSSSSSSSSSSSSSSSS
// Front Area  SSSSSSSSSSSSSSSSSSS   SSSSSSSSSSSSSSSSSS                SSSSSSSSSSSSSSSSSSSS
// SSSSSSSSSSSSSSSSSSSSSSSSS                SSSSSSSSSSS                   SSSSSSSSSSSSSSSSS
// SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS   SSSSSSSSSSSSSSSSSS                SSSSSSSSSSSSSSSSSSSS
// SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS   SSSSSSSSSSSSSSSSSSSSSSSSSSSS   SSSSSSSSSSSSSSSSSSSSSSS
// SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS

//  _   _                 ___
// | | | |___  ___ _ __  |_ _|___ ___  _ __  ___
// | | | / __|/ _ \ '__|  | |/ __/ _ \| '_ \/ __|
// | |_| \__ \  __/ |     | | (_| (_) | | | \__ \
//  \___/|___/\___|_|    |___\___\___/|_| |_|___/

//  ____  _                                     _
// |  _ \| | __ _  ___ ___ _ __ ___   ___ _ __ | |_
// | |_) | |/ _` |/ __/ _ \ '_ ` _ \ / _ \ '_ \| __|
// |  __/| | (_| | (_|  __/ | | | | |  __/ | | | |_
// |_|   |_|\__,_|\___\___|_| |_| |_|\___|_| |_|\__|

.champ_user_post_page{
	@extend .champ_user_side_col;
	margin-top:0px !important;
	flex-direction: row-reverse !important;
	width:min-content;
	margin-left:0px;
	@media screen and (max-width:$desktop){
		left:-8px
	}
}

.champ_user_post_page .champ_user_sc_place_1 .big_wrapper.irl{
	left:-1px;
	margin-top:10px;
}

.champ_user_post_page .champ_user_sc_place_1 .big_wrapper{
	margin-top:0px;
	left:0px;
}

.champ_user_post_page .champ_user_sc_place_2 .big_wrapper{
	left:0px;

}

.champ_user_post_page .champ_user_sc_place_3 .big_wrapper{
	left:0px;

}

.rival_user_post_page{
	@extend .rival_user_side_col;
	margin-top:0rem !important;
	width:min-content;
	height:35px;
	margin-left:0px;
	// @media screen and (max-width:$desktop){
	// 	margin-left:-7px;
	// }
}



// SIDE COLUMN CHAMP AREA

.userIcon_blank{
	width: 47px;
    height: 47px;
    margin-left: 2px;
    margin-top: 1px;
	// @media screen and (max-width:$desktop){
	// 	width: 45px;
	// 	height: 45px;
	// 	margin-left: 1px;
	// }
}

.userIcon_blank2{
	@extend .userIcon_blank;
	margin-top:2px;
}

.champ_user_side_col{
	margin-top:0.75rem;
	gap:0.5rem; 
	flex-direction: column-reverse;
	margin-left:-1px;
}

.champ_user_side_col .lil_wrapper{
	z-index:6;
	width:16px;
	height:16px;
	bottom:3px;
	right:3px;
	
}

.champ_user_side_col .lil_icon{
	width:14px;
	height:14px;
}

.champ_user_side_col .irl .lil_wrapper{
	right: -7px;
    bottom: -7px;
}

.champ_user_sc_place_1{
	width: 30px;
	height:30px;
	display: flex;
    position: relative;
	filter:grayscale(1);
	opacity:0.35;
	// @media screen and (max-width:$desktop){
	// 	width:45px;
	// }
}

.champ_user_sc_place_2{
	@extend .champ_user_sc_place_1;
	filter:grayscale(1);
	opacity:0.35;
	@media screen and (max-width:$desktop){
		display:none;
	}
}

.champ_user_sc_place_3{
	@extend .champ_user_sc_place_1;
	filter:none;
	opacity:1;
	@media screen and (max-width:$desktop){
		display:none;
	}
}

.champ_user_sc_place_1 .big_wrapper{
	margin-top:-1px;
	left:6px;
	width:40px;
	min-width:40px;
	height:40px;
	margin-left:-5px;
	// @media screen and (max-width:$desktop){
	// 	width:40px;
	// 	height:40px;
	// 	left:5px;
	// 	margin-left:0px;
	// }
}

.champ_user_sc_place_1 .big_wrapper.irl{
	margin-top:9px;
	left:5px;
	width:21px;
	min-width:21px;
	height:21px;
	margin-left: 5px;
	// @media screen and (max-width:$desktop){
	// 	width:27px;
	// 	height:27px;
	// 	left:3px
	// }
}

.champ_user_sc_place_2 .big_wrapper.irl{
	margin-top:9px;
}

.irl .lil_wrapper{
	right:-4px;
	bottom:-4px;
}


.champ_user_sc_place_1 .whiteBack{
	width:23px;
	height:23px;
	border-radius:4px;
	// @media screen and (max-width:$desktop){
	// 	width:19px;
	// 	height:19px;
	// 	left:42%;
	// }
}

/// SIDE COL RIVAL AREA 


// .rival_user_post_page .rival_user_sc_place_1 .big_wrapper{
// 	left:0px;
// 	margin-top:-5px;
// }

// .rival_user_post_page .rival_sc_vs{
// 	left:0px;
// 	top:10px;
// }

// .rival_user_post_page .rival_user_sc_place_2 .big_wrapper{
// 	left:0px;
// 	margin-top:-5px;
// }

// .rival_user_post_page .rival_user_sc_place_1 .big_wrapper.irl{
// 	margin-top:6px;
// 	left:0px;
// }

// .rival_user_post_page .rival_user_sc_place_2 .big_wrapper.irl{
// 	margin-top:6px;
// 	left:-2px;
// }


.rival_user_side_col{
	margin-top:0.75rem;
	gap:0.35rem;
	margin-left:-2px;
}

.rival_user_side_col .lil_wrapper{
	z-index:6;
	width:16px;
	height:16px;
	bottom:3px;
	right:3px;
}

.rival_user_side_col .irl .lil_wrapper{
	bottom:-6px;
	right:-6px;
}

.rival_user_side_col .lil_icon{
	width:14px;
	height:14px;
}

.rival_user_sc_place_1{
	height:30px;
	width:30px;
	display: flex;
    position: relative;
}

.rival_user_sc_place_2{
	@extend .rival_user_sc_place_1;
	display:none;
	@media screen and (max-width:$phone){
		display:none;
	}
}

.rival_user_sc_place_1 .big_wrapper.irl{
	margin-top:6px;
	left:0px;
	width:21px;
	min-width:21px;
	height:21px;
	margin-left: 4px;
}

.rival_user_sc_place_2 .big_wrapper.irl{
	margin-top:6px;
	left:-2px;
	// @media screen and (max-width:$desktop){
	// 	margin-top:14px;
	// }
}

.rival_user_sc_place_1 .big_wrapper{
	left:0px;
	margin-top:-3px;
	margin-left:-8px;
	width:40px;
	min-width:40px;
	height:40px;
	// @media screen and (max-width:$desktop){
	// 	width:40px;
	// 	height:40px;
	// 	margin-top:-1px;
	// 	margin-left:0px;

	// }
}

.rival_user_sc_place_2 .big_wrapper{
	left:0px;
	margin-top:-5px;
	margin-left:-8px;
	// @media screen and (max-width:$desktop){
	// 	left:5px;
	// }
}

.rival_user_sc_place_1 .whiteBack{
	width:19px;
	height:19px;
	border-radius:4px;
}

.rival_sc_vs{
	display:none;
	left:-2px;
	top:7px;
	position: relative;
	@media screen and (max-width:$phone){
		display:none;
	}
}


.rival_sc_vs p{
	font-size:11px;
	margin-bottom:0rem;
	margin-left:auto;
	margin-right:auto;
	width: min-content;
}

// SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS
// SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS    SSSSSSSSSSSSSSSSSSSSSS
// Front Area  SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS                SSSSSSSSSSSSSSSSSSSS
// SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS                   SSSSSSSSSSSSSSSSS
// SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS                SSSSSSSSSSSSSSSSSSSS
// SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS   SSSSSSSSSSSSSSSSSSSSSSS
// SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS
// 

.disNone{
	display:none !important;
}

.fwrit{
    display:inline-block;
    font-size:smaller;
}

.auto-margins{
    display: flex;
    margin-right:auto;
    margin-left:auto;
    margin-top:auto;
    margin-bottom:auto;
}

.auto-right-margin{
	margin-right:auto;
}

.auto-left-margin{
	margin-left:auto;
}

.auto-top-margin{
	margin-top:auto;
}

.auto-bottom-margin{
	margin-bottom:auto;
}

.auto-y-margins{
    margin-top:auto !important;
    margin-bottom:auto !important;
}

.overflow-hidden{
	overflow:hidden;
}

.exbtn-margins{
	margin-top:auto;
	margin-bottom:1.5rem;
	margin-left:1.5rem;
	margin-right:1.5rem;
	flex:auto;
	@media screen and (max-width:$desktop){
        margin-left:1rem;
		margin-right:1rem;
    }
}

.expandable-snippet-btm-fix{
	width:100%;
	height:3rem;
}

.front-expandable-button{
	background: linear-gradient(0deg, #ffffff 50%, #ffffff00 100%);
	cursor:pointer;
	// backdrop-filter: blur(2px);
	z-index:11;
	width:100%;
	height:150px;
	position: absolute;
	bottom:1px;
	border-radius: 0rem 0rem 0.25rem 0.25rem;
	@media screen and (max-width:$desktop){
        bottom:1px;
		border-radius: 0rem 0rem 0.1rem 0.1rem;
    }
}

.front-expandable-button:hover{
	background:linear-gradient(0deg, #eaeaea 50%, #eaeaea00 100%);
}

.front-expandable-button-close{
	@extend .front-expandable-button;
	background:none;
	// background:white;
	margin-top:-150px;
	height:150px;
	bottom:0rem;
	position:sticky;
}

// .width-lpc-on-champ{
// 	width:100%;
// 	margin-bottom:0rem !important;
// 	// padding-bottom:0rem;
// }

.auto-margins-custom{
	margin-right:auto;
	margin-top:auto;
	margin-bottom:auto;
	margin-left:auto;
}

.create-vs-topic-dot{
	width: 16px;
    height: 16px;
    margin-right: 0.5rem;
	margin-top:2px;
}

.rival-dropdown-area .create-vs-topic-dot{
	margin-right:0.5rem;
	margin-left:0rem;
	margin-top:auto;
	margin-bottom:auto;
}

.front-front-champ{
	width:20px;
	min-width:20px;
	margin-right:0.24rem
}

// #######################################################

// Front Front User Ranking

.user-ranking-box{
    background-color:white;
    border:none;
    border-radius: 0.1rem;
    width:auto;
    padding:1rem;
	padding-bottom:0.5rem;
    // margin-left:0.4rem;
    // margin-right:0.4rem;
    margin-bottom: $margin-dt-1;
	@media screen and (max-width:$desktop){
        margin-bottom:0rem;
		margin-top:$margin-mobile-1;
		border-radius:0.1rem;
		// border-bottom: $grey-border 1px solid;
    }
}

.user-ranking-image{
    width:30px;
	margin-right:0px;
	margin-left:0px;
	filter: brightness(1.05) contrast(0.9) grayscale(0.1);
	filter: brightness(1.15) contrast(0.8) grayscale(0.15);
    // padding-left:1.4rem;
    // padding-right:1.4rem;
	@media screen and (max-width:$desktop){
		width:28px;
	}
}

.user-ranking-image:hover{
	filter:none;
}

.user-ranking-image .user-icon-dropdown-btn{
	width:30px;
	@media screen and (max-width:$desktop){
		width:28px;
	}
}

.user-ranking-title{
    margin-bottom:0.25rem !important;
    font-weight: 600;
}

.grey-back-rival .user-ranking-row{
	margin-top:0rem;
}

.user-ranking-row{
	gap:5px;
	position:relative;
	margin-bottom:0.5rem;
	z-index:500;
	// This stops left overhangs in usericons from being clipped by overflow-hidden.
	// padding-left: 0.5rem;
    // margin-left: -0.5rem;
	@media screen and (max-width:$desktop){
		margin-bottom:-3px;
	}
}

.user-ranking-row-opac{
	position:absolute;
	right:0px;
	bottom:0px;
	height:100%;
	width:60px;
	background: linear-gradient(126deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 79%);

}

.surrounding_user_posts{
	// margin-top:1rem;
	// font-size: 11px;
	// line-height:1rem;
	// margin-left:1rem; 
	display:inline-flex;
	gap:0.4rem;
	margin-top:auto;
	margin-bottom: auto;
	@media screen and (max-width:$desktop){
		margin-left:0rem;
		margin-right:auto;
	}
}

.surrounding_user_posts_mt{
	margin-top:0.5rem !important;
	margin-bottom:0.25rem;
}

.surround_post_icon{
	width:20px;
	height:auto;
}

.surround_arrow_icon{
	padding:2px;
	width:20px;
	height:auto;
}

.surround_arrow_icon.no_hover{
	padding-top:3px;
	padding-bottom:1px;
}

.surround_arrow_icon:hover{
	transform: scale(130%);
	filter:invert(29%) sepia(16%) saturate(0%) hue-rotate(170deg) brightness(100%) contrast(83%);
}

.no_hover:hover{
	transform:scale(100%) !important;
	filter:none !important;
}

.user-ranking-row .irl.big_wrapper{
	width: 16px;
    height: 16px;
    margin-top: 1px;
    margin-left: 2px;
	min-width:14px;
	@media screen and (max-width:$desktop){
		width: 14px;
    	height: 14px;
	}

}

.user-ranking-row .big_wrapper{
	width: 26px;
    height: 26px;
    margin-top: -4px;
	margin-left: -4px;
	min-width:24px;
	@media screen and (max-width:$desktop){
		width:24px;
		height: 24px;
	}
}

// #########################################################################################
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM        MMMMMMMMMMMMMMMMMMMMM    MMMMMMMMMMMMMMMMMMMM
// Sign in / log in area MMMMMMMMMMM             MMMMMMMMMMMMMMMMM    MMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM               MMMMMMMMMMMMMMM     MMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM             MMMMMMMMMMMMMMMMM    MMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM        MMMMMMMMMMMMMMMMMMMMM    MMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// #########################################################################################

.customize-image-card{
	width: 100%;
    height: max-content;
    padding: 1.5rem;
    background-color: white;
	border-radius:0.1rem;
	@media screen and (max-width:$desktop){
		padding:1rem;
		padding-top:1.5rem;
		padding-bottom:1.5rem;
	}
}

.top-row-customize-image-card{
	margin-bottom: 2rem;
	padding-bottom:0.5rem;
}

.min-height-1000{
	min-height:1000px;
}

.customize-image-card h1{
	font-size:18px;
	margin-bottom:0rem;
}

//////// LOLOLOLOLOL

.mobile-signup-pop{
	display:none;
	@media screen and (max-width:$desktop){
		display:flex;
		margin-bottom:auto;
	}
}

.signup_pop_overlay{
	position:fixed;
	width:100vw;
	height:100vh;
	background-color:#000000a1;
	z-index:600;
	display:flex;
	backdrop-filter: blur(2px);
}
.signup_pop{
	background-color:white;
	border-radius:0.5rem;
	width:800px;
	display:flex;
	min-width:700px;
	min-height:700px;
	overflow:hidden;
	@media screen and (max-width:$desktop) and (min-width:$phone){
        width: 100%;
		max-width:370px;
		max-height:850px;
		min-width: auto;
		margin-top: auto;
		margin-bottom: auto;
		margin-left: auto;
		margin-right: auto;
		background-image: url(../images/SignInUp/stars_back_phone.jpg);
		background-repeat: no-repeat;
		background-size: calc(100% + 2px);
		background-position-x: -1px;
		background-position-y: -1px;
    }
	@media screen and (max-width:$phone){
		width: 100%;
		height:100%;
		min-width: auto;
		margin-top: auto;
		margin-bottom: auto;
		margin-left: auto;
		margin-right: auto;
		background-image: url(../images/SignInUp/stars_back_phone.jpg);
		background-repeat: no-repeat;
		background-size: calc(100% + 2px);
		background-position-x: -1px;
		background-position-y: -1px;
	}
}

.white-on-mobile p{
	@media screen and (max-width:$desktop){
		color:white !important;
	}
}

.white-on-mobile h1{
	@media screen and (max-width:$desktop){
		color:white !important;
	}
}

.white-on-mobile .blue-text{
	color: #1995d8 !important;
}

.my-phone-number-area-mine-my-own-my-precious{
	margin-top: 0.5rem;
}

.my-phone-number-area-mine-my-own-my-precious .iti{
	width: 100%;
	// margin-top: 1rem;
	margin-bottom: 0rem;
}
.my-phone-number-area-mine-my-own-my-precious input{
	border:none;
	border-bottom: 1px solid gray;
	width:100%;
	@media screen and (max-width:$desktop){
		border-top: 8px solid white;
		border-bottom: 8px solid white;
		border-left: 4px solid white;
		border-radius: 0.25rem;
    }
}

.gap-05{
	gap:0.5rem;
}

.inputSpot input{
	border: none;
    background-color: #eaeaea;
    border-radius: 0.5rem;
    padding: 0.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
    // margin-top: 0.5rem;
    width: 100%;
}

.inputSpot input:focus-visible{
	border:none !important;
	outline: none !important;
    background-color: #f3f3f3;
}

.switch-intent{
	justify-content:right;
	display:flex;
	margin-bottom:0.25rem;
}

.switch-intent p{
	margin-bottom:0rem !important;
}

.switch-intent-btn{
	border:none;
	background-color:#00000000;
	color:$link-blue;
	font-weight:500;
	@media screen and (max-width:$desktop){
		margin-top:-1px;
	}
}

.switch-intent-btn:hover{
	color:#0c53bb;
}

.selectable-checkbox:hover{
	cursor:pointer !important;
}

.greyed-continue{
	background-color:#bcbcbc !important;
	border:none !important;
}

.signUp-bottomStuff{
	margin-top:2rem;
	@media screen and (max-width:$desktop){
        margin-top:3rem;
    }
}

.signUp-bottomStuff-2{
	margin-top:2rem;
	@media screen and (max-width:$desktop){
        margin-top:4rem;
    }
}

.signUp-text{
	font-weight:400;
	color:$darkest-dark;
	@media screen and (max-width:$phone){
        color:white;
    }
}

.mini-signUp-text{
	@extend .signUp-text;
	font-size:11px;
	font-weight:400;
}

.stars-width-in-container{
	width:60%;
	z-index:1;
	max-width:600px;
	margin-left:auto;
	margin-right:auto;
	margin-top:3rem;
	padding-bottom:3rem;
	height:min-content;
	@media screen and (max-width:$desktop){
        width:90%;
    }
}

.stars-width-in{
	overflow: hidden;
	border-radius:0.1rem;
	background-color: #cdd6da;
	padding:0.5rem;
	margin-bottom:0.5;
	@media screen and (max-width:$phone){
        margin-bottom:0.5;
    }
}

.stars-width-in.gap-2{
	@media screen and (max-width:$phone){
        gap:1px !important;
    }
}


.mobile-user-icon-top .stars-width-in{
	padding:1px;
	padding-left: 0rem;
    padding-right: 0rem;
}

.user-recieved-icons{
	margin-bottom: 0.5rem !important;
    background-color: #0082cb !important;
    color: white !important;
	@media screen and (max-width:$phone){
		margin:0.25rem !important;
        margin-bottom:0rem !important;
		font-size: 11px;
		font-weight:400 !important;
    }
}

.stars-background .width-in{
	background-color: #00000000;
}

.stars-background{
    // background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(../../assets/images/SignInUp/background2.png);
    background-position: 50% 50%;
    background-attachment: fixed;
    background-size:cover !important;
    background-repeat:no-repeat;
	min-height:100vh;
	height:auto;
}

.stars-background-phone{
    // background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(../../assets/images/SignInUp/background2.png);
    background-image: url(../images/SignInUp/cityNForrest.jpg);
    // background-position: 50% 50%;
    background-attachment: fixed;
    background-size:cover !important;
	background-color: #050c14;
    background-repeat:no-repeat;
	min-height:100vh;
	height:auto;
	position:relative;
	z-index:0;
}

.stars-background-phone:before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color:rgb(16 16 16 / 22%);
	z-index: -1;
}


.waitlist-modal{
	z-index:1060;
	padding:2rem;
	text-align:left;
}

.waitlist-modal-close{
	border: none;
    background-color: white;
    margin-left: auto;
    margin-top: -5px;
}

.waitlist-modal-enter-btn{
	width: min-content;
    border: none;
    padding: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 0.25rem;
	margin-left:auto;
	margin-top:0.5rem;
	background-color:#68B2FF;
}

.waitlist-modal-enter-btn:hover{
	transform: scale(0.97);
	background-color:#86c1ff;
}

.modal-backdrop{
	display:none;
}

.modal{
	z-index:0;
	//This quickfix allows for notifications to go on top of the modal in signup, keep an eye out for errors. 
}

.modal-dialog{
    max-width: 720px;
}

.modal-dialog-centered{
    @media screen and (max-width:$desktop){
        min-height:100%;
    }
}

.modal-content{
    border:none !important;
    box-shadow: rgba(31, 31, 31, 0.502) -4px 8px 16px;
	@media screen and (max-width:$desktop){
        box-shadow:none;
    }
}

.background-cover{
	background-color:#00000000;
}

.modal-title{
    // font-size:1.5rem;
}

.city-n-forrest{
    width:100%;
	height:100%;
    overflow: hidden;
    object-fit: cover;
	object-position: top;
    padding:0;
	border-radius:0rem !important;
}

.contain-height{
    min-height:44rem;
    @media screen and (max-width:$desktop){
        max-height:100%;
        height: 100%;
    }
}

.log-img{
    width:40%;
}

.signup_pop .log-img{
	@media screen and (max-width:$desktop){
        display:none;
    }
}

.log-side{
    width:60%;
}

.signup_pop .log-side{
	@media screen and (max-width:$desktop){
        width:100%;
		display: flex;
    	flex-direction: column;
		height: 100vh;
    }
}

.log-icon{
    width:35%
}

.log-icon-side{
    width:65%;
}

.mb-4-5{
    margin-bottom:2rem !important;
}

.p-4-5{
    padding:2rem;
}

.full-width{
    width:100%;
}

.mobile-ppp-image-container{
    margin-left:auto;
    margin-right:auto;
	margin-bottom:4rem;
	@media screen and (max-width:$desktop){
        margin-bottom:3rem;
    }
}

.mobile-signin-padding{
    margin:2rem;
    margin-top:1rem;
	justify-content: space-between;
	@media screen and (max-width:$desktop){
		margin:2.5rem;
		height:calc(100vh - 5rem);
    }
}

.mobile-signin-padding p{
	color:white;
}

.mobile-signin-padding h1{
	color:white;
	font-weight:400;
}

.mobile-signin-padding h3{
	color:white;
	font-weight:400;
}

.mb-2s{
	margin-bottom:2rem;
}

.register-btn-col{
	width:fit-content;
	margin-left:auto;
	margin-top:1rem;
	// @media screen and (max-width:$phone){
	// 	width:100%;
	//  }
}

.create-account-btn{
	background-color:#007fc7;
	display:flex;
	border:none;
	color:white !important;
	border-radius:0.25rem;
	margin-bottom:1rem;
	margin-left:auto;
	width:100%;
	padding:2rem;
	padding-top:0.5rem;
	padding-bottom:0.5rem;
	@media screen and (max-width:$phone){
       width:100%;
	   justify-content: center;
	   margin-bottom:1rem;
    }
}

.create-account-btn:hover{
	transform:scale(0.99);
	background-color:#0a8bd4;
	cursor:pointer;
}

.my-google-button{
	max-height:50px !important;
}

.my-google-btn:hover{
	transform:scale(0.99);
	cursor:pointer;
}

.log-in-btn{
	@extend .create-account-btn;
	background-color:#707070;
	width:100%;

}

.log-in-btn:hover{
	background-color:#797979;
}

.login_logo_copyWriting{
	width:auto;
	height:40px;
	filter: invert(100%) sepia(5%) saturate(19%) hue-rotate(215deg) brightness(103%) contrast(102%);
	
}

.more-dropdown-la{
	width:max-content;
	margin-left:auto;
	padding:0.25rem;
	margin-top:0.5rem;
}

.phone-number-btn{
	@extend .create-account-btn;
	margin-top:0.5rem;
}

.modal-dialog{
    @media screen and (max-width:$desktop){
        margin:0rem;
    }
}

.black-logo-square{
	width:70%;
	// height:100px;
	margin-top:0rem;
	margin-right:auto;
	justify-content: center;
	margin-left:auto;
	// background-color:$darkest-dark;
	display:flex;
	flex-direction:row;
	@media screen and (max-width:$desktop){
		width:75%;
		margin-top:1.5rem;
    }
}

.signup-logo{
	width:42px;
	margin-top: -2px;
}

.signup-logoWriting{
	width: 110px;
	height:auto;
	filter:invert(100%) sepia(5%) saturate(19%) hue-rotate(215deg) brightness(103%) contrast(102%);
}

.sign-up-form-adjust{
	background-color:$light-grey-background !important;
}

.dropdown-menu-69{
	max-width:100vw;
}

.dropdown-wrap-fix{
	white-space: normal;
}

//Sign up

.sign-out-button{
	font-size:14px;
}

.product_selection_area{
	margin-top:1rem;
}

.product_to_select{
	flex:0 0 auto;
	width:20%;
	@media screen and (max-width:$desktop){
        width:33%;
    }
}

.dropdown-signup-text{
	color:$darkest-dark;
	font-weight:400;
	font-size: 15px;
	@media screen and (max-width:$desktop){
	    font-size:14px;
	}
}

.mobile-signin-padding .dropdown-signup-text{
		@media screen and (max-width:$desktop){
        // color: $bright-blue !important;
		color:white;
    }
}


.show.dropdown69::before {
	////
	transform: rotate(0deg); /* Reset rotation when dropdown is open */
	@media screen and (max-width:$phone){
		transform: rotate(180deg);
	}
}


.dropdown69::before{
	display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.2em solid transparent;
    border-bottom: 0;
    border-left: 0.2em solid transparent;

	color:$darkest-dark;
	top:13px;
	left:-14px; 
	position:absolute;
	transform: rotate(-90deg);

}

.mobile-signin-padding .dropdown69::before{
	@media screen and (max-width:$desktop){
        // color: $bright-blue !important;
		color:white;
    }
}

.dropdown69::after{
	display:none;
}
.dropdown-menu-69{
	padding:1rem;
}

.signup-dropdown-form-69{
	background-color:white;
	width:100%;
	border:none;
	margin-top:0rem;
	margin-bottom:0rem;
	text-align:left;
}

.signup-dropdown69-textinput{
	background-color: $back-grey;
	margin-bottom:0.5rem;
	margin-top:0.5rem;
}

.signup-dropdown-form-69:hover{
	color:#0c53bb;
}

.save-row-custom{
	margin-top: 1rem;
    position: sticky;
    bottom: 0px;
}

.icon-first-select-continue-button{
	margin-left:auto;
	width: auto;
	position:relative;
	border-radius:1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
	margin-bottom:0.5rem;
	background-color: $bright-orange !important;
	border:none !important;
}

.legal-check{
	width:40px;
}

.legal-check input[type=checkbox]{
	transform: scale(1.5);
	margin-left: 4px;
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ POST LINKS @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

//  _____           _        ____                 _
// |_   _|__  _ __ (_) ___  |  _ \ _ __ _____   _(_) _____      __
//   | |/ _ \| '_ \| |/ __| | |_) | '__/ _ \ \ / / |/ _ \ \ /\ / /
//   | | (_) | |_) | | (__  |  __/| | |  __/\ V /| |  __/\ V  V /
//   |_|\___/| .__/|_|\___| |_|   |_|  \___| \_/ |_|\___| \_/\_/
//           |_|

.my-inner-post .topic_preview{
	margin-top:0.75rem;
	margin-bottom:0.25rem;
}

.topic_preview{
	// border: solid 1px #e1e1e1;
	background-color:#f1f1f1;
    border-radius: 0.25rem;
    padding:1rem;
	padding-top: 0.75rem;
    padding-bottom: 0.75rem;
	margin-bottom: .75rem;
    margin-top: .25rem;
	@media screen and (max-width:$desktop){
        padding: 0.5rem;
		padding-top: 0.5rem;
    	padding-bottom: 0.5rem;
    }
}

.diff_added .topic_preview{
	margin:0rem;
	margin-top:0.1rem;
	margin-bottom:0.1rem;
}

.diff_added .topic_preview .post_img{
	padding:0px;
	background-color:white;
}

.topic_preview .topic_preview{
	margin:0.5rem;
	width:auto;
}

.topic_preview:hover{
	background-color: #f1f1f1;
	transform:scale(0.98);
}

.topic_preview .topic_preview:hover{
	background-color: #eaeaea;
}

.topic_preview h1{
	font-size:15px;
	font-weight:600;
	color:$darkest-dark !important;
	margin-bottom:0.25rem;
}

.topic_preview h2{
	font-size:15px;
	font-weight:500;
	color:$darkest-dark !important;
	margin-bottom:0.25rem;
}

.topic_preview h3{
	font-size:15px;
	font-weight:500;
	color:gray !important;
	margin-bottom:0.25rem;
}

.topic_preview .post_card_img{
	max-height:400px !important;
	width:100%;
	height:100%;
	object-fit:cover;
	min-height:40px;
}

.topic_preview .post_card_video{
	width:100%;
	height:100%;
	object-fit:cover;
	min-height:40px;
}

.topic_preview p{
	display:inline !important;
}

.topic_preview .post_card_video_container{
	margin-bottom: 0rem !important;
    margin-top: -22px !important;
    width: 50px;
    height: 50px !important;
    margin-left: auto;
    min-height: 40px;
	@media screen and (max-width:$phone){
		width: 42px;
    	height: 42px !important;
	}
}

.topic_preview .post_card_img_container{
	margin-bottom: 0rem !important;
    margin-top: -22px !important;
    width: 50px;
    height: 50px;
    margin-left: auto;
    min-height: 40px;

	@media screen and (max-width:$phone){
		width: 42px;
    	height: 42px;
	}
}

.main-col .topic_preview .post_card_img_container{
	@media screen and (max-width:$phone){
		display:none;
	}
}

.main-col .topic_preview .post_card_video_container{
	@media screen and (max-width:$phone){
		display:none;
	}
}


.topic_preview .post_card_video_container .ytp-large-play-button{
	display:none !important;
}

.preview_card_text{
	width:calc(100% - (50px));
}

.topic_preview .hasSideImage{
	width:calc(100% - (90px));
	display:block;
	@media screen and (max-width:$desktop) and (min-width:$tablet) {
		width:calc(100% - 90px);
	}
	@media screen and (max-width:$tablet) and (min-width:$phone){
		width:calc(100% - (80px));
	}
	@media screen and (max-width:$phone){
		width:calc(100% - (80px));
	}
}


.topic_preview h2{
	display:none;
}

.topic_preview h3{
	display:none;
}

.post_preview{
	@extend .topic_preview;
}

.comment_preview{
	@extend .topic_preview;
}

//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&                        &&&&&&&&&&&&&&&
//&&&&&&&&&&&&&& comments &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&                        &&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&                        &&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&                        &&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&                        &&&&&&&&&&&&&&&
// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&    &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&  &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

.comment_and_children{
	margin-top:5px;
}

.comment_and_children:last-child{
	margin-bottom:0px;
}

.convo-comment-thread .comment_and_children{
    @media screen and (max-width:$desktop){
        margin-left:0rem;
    }
}

.comment-feed-bar-top{
	// This makes short posts look good.
	background-color: #e6ebec;
	@media screen and (max-width:$phone){
        background-color:#dbe3e8;
    }
}

.comment-filter-accordion{
	// margin-top: 5px;
	margin-bottom: 5px;
}

.comment-feed-row{
	box-shadow:$common-box-shadow;
	border-radius:0.25rem;
    @media screen and (max-width:$desktop){
        margin-top:0rem;
		border-radius:0.1rem;
		gap:0.25rem;
		box-shadow:$common-box-shadow-mobile;
    }
}

.comment_top_bar .follow-text{
    display:none !important;
}


.comment_top_bar .follow-button{
    width:100%;
    height:100%;
    padding:0px !important;

}

.comment_top_bar .follow-icon{
    width:26px;
    padding-left:1px;
    padding-right:1px;
}

.comment_top_bar .mi-text{
    margin-right:2px;
    color:$darkest-dark;
}

.comment-username-container{
	width:auto;
	padding-right:0.5rem;
	display:flex;
	margin-bottom:1px;
    @media screen and (max-width:$desktop){
		padding-right:0.5rem;
	}
}

.comment-username-text{
	margin-top:auto;
	margin-bottom: auto;
    font-weight: 300;
    font-size: 11px;
}



.comment-back-color{
	border-radius:0.25rem 0rem 0rem 0.25rem;
}

.comment .side-col{
    width:0px;
	position:relative;
    //Weird difference btw side-col in main page vs. post page. side col actual width = (width - margin) on main page. Post page: width = width
    // esay fix is to set width smaller on side page, but this isnt great solution. 
	min-height:20px;
    height:100%;
	padding-left:0rem;
	display:flex;
	background-color:#00000000;
    @media screen and (max-width:$desktop){
		display:flex;
        width:0px;
		padding-left:0rem;
        border:none !important; //needs to be important for comment thread sides
    }
}

.reply_to_comment_button{
    margin-top:auto;
    margin-bottom:auto;
    padding:0px;
    font-size:small;
    @media screen and (max-width:$desktop){
        padding-left:0px;
        margin-left:-2px;
    }
}

.reply_to_comment_button-2{
	margin-top:auto;
    margin-bottom:auto;
	padding:0px;
	margin-left:3px;
}

.reply_to_comment_button-2:hover{
	transform:scale(0.97);
	color:black;
}

.reply_to_comment_button-2:hover .comment-username-text{
	color:black;
	font-weight:400;
}

.comment-back-convo .comment_top_bar .uni-icon-gap{
	gap:0rem;
}


.comment .user-post-image-container{
    width: 3rem;
    margin-top:-0.8rem;
	height:auto !important;
    margin-left:-3px;
    position:relative;
	@media screen and (max-width:$desktop){
        margin-top:-0.4rem;
    }
}

.comment .user-post-image{
	position: absolute;
    width: 65px;
    left: 0px;
    top: -24px;
    max-width: none !important;
    @media screen and (max-width:$desktop){
        width:55px;
        left:2px;
        top:-26px;
    }
}

.comment-user-icon{
	width:22px;
	height:22px;
	margin-right: 16px;
	@media screen and (max-width:$desktop){
		margin-right: 10px;
	}
}

.comment .big_wrapper{
    position:relative;
    width:40px;
    min-width: 40px;
    display:flex;
	margin-left: 0px;
	margin-top: 0px;
	top: -8px;
	left: -9px;
    // @media screen and (max-width:$phone){
    //     width:44px;
    //     min-width: 44px;
    // }
}

.comment .big_wrapper.irl{
	width: 21px;
    min-width: 20px;
    height: 21px;
    margin-left: 0px;
    margin-top: 0px;
	top: 3px;
	margin-right: 10px;
	left:1px;
	// @media screen and (max-width:$phone){
	// 	width: 23px;
	// 	height: 23px;
	// 	margin-left:13px;
    // }
}

.comment .dotdotdot-container{
    // margin-left:4px;
    margin-top:auto;
    margin-bottom:auto;
    border-radius: 1rem;
    padding: 0.1rem;
	margin-right:2px;
}

.comment .dotdotdot{
    position:relative;
	width:20px;
	display:flex;
}

.comment_top_bar {
    @extend .row;
    margin-bottom: -3px;
}

.comment_top_bar .horz-vote-area{
	margin-left:auto;
	margin-right:0rem;
	margin-bottom:auto;
    margin-top:auto;
	width:auto;
}

.actionables{
    width:auto;
    margin-bottom:auto;
    margin-top:auto;
	gap:0rem !important;
    // position:relative;
    // top:-10px;
}

.convo-comment-thread .actionables{
	gap:0rem !important;
}

.comment{
    // @extend .container;
    // @extend .row;
	margin-top:5px;
    padding:0px;
    margin-right:0px;
    margin-left:0px;
	border-radius:0.25rem;
    @media screen and (max-width:$desktop){
		border-radius:0.25rem;
    }
}

.comment_and_children:first-of-type{
	margin-top:0px;
}

.comment-back-mobile_0{
	margin-top: 0px;
	// border-top: 1px solid #eaeaea;
	border-radius: 0rem !important;
}

.comment-back-mobile_1{
	margin-left: 25px;
	border-radius: 0rem 0rem 0rem 0rem;
}

.comment-back-mobile_2{
	margin-left: 50px;
	border-radius: 0rem 0rem 0rem 0rem;
}

.comment-back-mobile_3{
	margin-left: 75px;
	border-radius: 0rem 0rem 0rem 0rem;
}

// .comment-back-mobile_0 .comment-me-timbers{
//     background-color:$base-blue;
// }

.comment-back-mobile_1 .comment-me-timbers{
	margin-top: 0px;
	// border-top: 1px solid #eaeaea;
}

.comment-back-mobile_2 .comment-me-timbers{
	margin-top: 0px;
	// border-top: 1px solid #eaeaea;
}

.comment-back-mobile_3 .comment-me-timbers{
	margin-top: 0px;
	// border-top: 1px solid #eaeaea;
}

.perfCorner_LBC{
	border-radius: 0rem !important;
}

.perfCorner_LTC{
	border-radius: 0.25rem 0rem 0rem 0.25rem !important;
}

.comment_level_1.desktop{
	@media screen and (max-width:$desktop){
		margin-left:0rem;
    }
}
.comment_level_2.desktop{
    @media screen and (max-width:$desktop){
		margin-left:0rem;
    }
}

.comment_level_3.desktop{
    @media screen and (max-width:$desktop){
		margin-left:0rem;
    }
}
.comment_level_4.desktop{
	@media screen and (max-width:$desktop){
		margin-left:0rem;
    }
}

.continue_icon{
	width:16px;
	margin-left:auto;
	margin-right:auto;
	margin-top:auto;
	margin-bottom:auto;
}

.ccc-text{
    font-size: 15px;
    margin-top:auto;
    margin-bottom:auto !important;
    margin-left:1.5rem;
    margin-right:1rem;
	@media screen and (max-width:$desktop){
       font-size: 14px;
	   margin-left:1rem;
	   margin-right:0.5rem;
	   width: auto;
    }
}

.ccc-text-two{
	@extend .ccc-text;
	padding:1rem;
	padding-left:0rem;
}

.ccc-row{
	width: 100%;
    background-color: white;
	min-height:50px;
	border-bottom: 1px solid #eaeaea;
    border-radius: $common-border-radius-up;
}

.ccc_icon{
    margin-top:auto;
    margin-bottom: auto;
    margin-left:auto;
    margin-right:auto;
    width:25px;
}

.tiny-comment-container{
	margin-bottom: 5px;
    border-radius: 0.25rem;
}

.convo-cc-accordion .tiny-comment-container{
	margin-top:0rem;
	margin-bottom: $margin-dt-1;
	@media screen and (max-width:$desktop){
		margin-bottom:0rem;
    }
}

.comment_reply_area .tiny-comment-container{
	margin-bottom:5px;
	margin-top:5px;
}

.create-comment-btn{
	background-color: white;
    border-radius: 0.25rem;
    width: 30px;
    margin-right: 1.5rem;
	margin-left:auto;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    height: 100%;
    display: flex;
	@media screen and (max-width:$desktop){
        border-radius: 0.25rem;
		margin-right:1rem;
    }
}

.create-comment-dropdown-btn{
	padding:0.5rem;
	padding-left:1rem;
	padding-right:1rem;
	margin:0.75rem;
	margin-left:1.5rem;
	border-radius:0.25rem;
	background-color:$hover-grey;
	@media screen and (max-width:$desktop){
        margin-left:1rem;
	}
}

.create-comment-dropdown-btn:hover{
	background-color:$light-grey;
	cursor:pointer;
}

.create-comment-dropdown-btn::after{
	display:none;
}

.create-comment-cat-dropdown{
	padding:0.75rem;
	border:none;
	box-shadow: -2px 2px 4px 0px #40404045;
}

.create-comment-cat{
	padding:1rem;
	margin:0.5rem;
	border:1px solid $grey-border;
	border-radius:0.25rem;
}

.create-comment-cat:hover{
	background-color:$hover-grey;
	cursor:pointer;
}

.ccc-cat-image{
	height:30px;
	margin-left:0.5rem;
	margin-right:0.5rem;
	width:auto;
}

.edit-comment{
	background-color: white;
	padding:0.75rem;
	border-radius:0.5rem;
	width:100%;
}

.edit-comment-btn{
	@extend .create-comment-btn;
	height:auto;
	min-width:40px;
	margin-right:1rem;

}

.edit-comment-btn.close-edit{
	margin-right:0.5rem;
}

.create-comment-btn:hover .ccc_icon{
    filter: invert(96%) sepia(2%) saturate(0%) hue-rotate(181deg) brightness(87%) contrast(88%);
}

.cover-input{
    height:100%;
    width:100%;
    position:absolute;
    top:0px;
    left:0px;
    background-color: transparent;
    border:none;
}

.cover-btn{
	display:none;
}

.create-comment-cover + .cover-btn{
	display:block;
	height:100%;
    width:100%;
    position:absolute;
    top:0px;
    left:0px;
    background-color: transparent;
    border:none;
	z-index:10;
}

.comment_rest {
    @extend .col;
	width:100%;
    padding-top:1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.25rem !important;
    justify-content:space-between;
    @media screen and (max-width:$desktop){
		padding-top:0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
    }
}

.comment_responding_to {
    float: right;
}
.comment_time_ago {
    display: flex;
}
.comment_text {
    margin-bottom: 0.25rem;
    // @media screen and (max-width:$desktop){
    //     margin-bottom: 0rem;
    // }
}

.reply_icon{
    width:22px;
	@media screen and (max-width:$desktop){
        width:22px;
    }
}

.comment_top_bar .lil_wrapper{
    width:20px;
    height:20px;
    bottom:7px;
    right:7px;
    @media screen and (max-width:$desktop){
        right:0px;
    }
}

.comment-page-main .comment{
    margin-top:0px !important;
}

.continue-card-two{
    width:100%;
    // border-radius: 0.5rem 0rem 0rem 0.5rem !important;
    background-color: #00000000;
    height:auto;
    margin-left:0rem !important;
	padding:0px;
}

.noCommentBorder{
	border-top:0px;
}

.maxgap{
	margin-bottom:32px;
	border-radius:0.1rem !important;
}

.more-comments-container{
	position:absolute;
	left:-23px;
	bottom:2px;
}

.minmax-container{
	position:absolute;
	bottom: -26px;
    left: 2px;
}

.minmax-button-two{
    width: 20px;
    height: 20px;
    border-radius: 2rem !important;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
	display:flex;
    margin-top: 0px;
    padding:0px !important;
}

.minmax-button-two:hover{
	transform: scale(0.97);
	cursor:pointer;
}

.minmax_icon{
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    width: 22px;
	display:none;
    @media screen and (max-width:$desktop){
        margin-left:auto;
        margin-right:auto;
		margin-top:auto;
    }
}

.minmax-button-two[aria-expanded="true"] .min_icon{
    display:flex;
}

.minmax-button-two[aria-expanded="false"] .max_icon{
    display:flex;
}


//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&                          &&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&             |            &&&&&&&&&&&&&&&
//&&&&&&&&&&&&&& conversations &&&&&&&&&&&&&&&&&&&&&&&&&&&&&         |  ||  |         &&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&        || ||| ||         &&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&        |||||||||         &&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&        |||||||||         &&&&&&&&&&&&&&&
// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&     _______________      &&&&&&&&&&&&&&&
// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&                          &&&&&&&&&&&&&&&
// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

.convo-axil{
	justify-content: end;
	gap: 5px;
    margin-top: 1rem;
}

.no-x-margins{
	margin-left:0px;
	margin-right:0px;
}

.convo-index-col{
	margin-top:2rem;
	background-color:#eaeaea;
	border-radius:0.25rem;
}

$convo-white: white;

.global-convo-title-card{
    padding-left:1.5rem;
    padding-right:1.5rem;
    padding-top:1rem;
    padding-bottom:1rem;
    background-color:white;
    border-radius: $common-border-radius;
    border:none;
    width:100%;
    display:flex;
    @media screen and (max-width:$desktop){
        padding:1rem;
        border-radius: $common-border-radius-mobile;
        border-right: none;
        border-left:none;
    }
}

.global-convo-title-card:hover p{
	color:$link-blue !important;
}

.global-convo-title-card:hover .create_convo_icon{
	transform:scale(0.95);
}

.fire_icon{
    width:40px;
    margin-top:auto;
    margin-bottom:auto;
    // filter: invert(60%) sepia(80%) saturate(318%) hue-rotate(342deg) brightness(97%) contrast(96%);
    @media screen and (max-width:$desktop){
        width:30px;
    }
}

.create_convo_icon{
    width:24px;
    display:flex;
    margin-left:auto;
    margin-right:0.75rem;
    @media screen and (max-width:$desktop){
        width:24px;
    }
}

.my-avg-card{
    padding-left:1.5rem;
    padding-right:1.5rem;
    padding-top:1rem;
    padding-bottom:1rem;
    margin-bottom:2px;
    background-color:white;
    border-radius: 0.25rem;
    @media screen and (max-width:$desktop){
        margin-bottom:0rem;
        border-radius: 0.25rem;
        padding-left:0.75rem;
        padding-right:0.75rem;
        padding-top:0.5rem;
        padding-bottom:0.5rem;
    }
}

.my-avg-container{
    margin-bottom:2px;
    background-color:white;
    border-radius: $common-border-radius;
    border:none;
    @media screen and (max-width:$desktop){
        margin-bottom:0rem;
        border-radius: 0.1rem !important;
    }
}

.padded-cell{
    padding:1rem;
    @media screen and (max-width:$desktop){
        padding:0.75rem;
    }
}

.convo-camping-image{
    object-fit: cover;
    width:100%;
    height:100%;
	border-radius:0.5rem;
}

.convo-page .black-card{
	width:auto;
	margin-right:auto;
	@media screen and (max-width:$desktop){
        margin-right:auto;
    }
}

.convo-page .black-card:hover{
	transform: scale(1) !important;
	cursor:default !important;
}

.black-card{
    background-color: $darkest-dark;
    padding:0rem;
    width:100%;
    overflow: hidden;
    // min-width:15rem;
	border-radius:0.5rem;
    display:flex;
	@media screen and (max-width:$desktop){
		max-width:600px;
		margin-left:auto;
		margin-right:auto;
    }
}

.black-card:hover{
	transform:scale(0.99);
}

.black-card.black-card-no-hover:hover{
	box-shadow: none !important;
}

.convo-top-total{
	margin-bottom:5px;
	border-radius:0.25rem;
	overflow:hidden;
}

.convo-index-card{
	width:100%;
	color:$darkest-dark;
	padding:1.5rem !important;
	margin-bottom:0px !important;
	border-radius:$common-border-radius-up !important;
	@media screen and (max-width:$desktop){
		// height:7rem;
        // border-bottom: $dark-grey-border 2px solid !important;
		border-radius: 0.1rem 0rem 0rem 0.1rem !important;
		margin-right: 0rem;
		padding:1.5rem !important;
    }
}

.convo-index-card .strongish{
	font-weight:400;
}

.convo-mobile-blue-back{
	background-color: $back-grey;
	border-radius:0.25rem;
	margin-bottom: 5px;
	border-radius: 0.25rem;
	overflow: hidden;
}

.conv_message_view .conv-mes{
	font-weight:600;
	color:#1f97db;
}

.conv_post_view .conv-pos{
	font-weight:600;
	color:#1f97db;
}

.convo-pad{
    padding:1.75rem;
	position:absolute;
	top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    align-content: center;
    vertical-align: middle;
	@media screen and (max-width:$desktop){
        padding:1rem;
    }
}

.convo-side-col{
    width: 50px;
    min-width:50px;
    border-radius: 0.5rem;
	margin-left:1px;
    background-color: white;
    border:none;
    @media screen and (max-width:$desktop){
        width:44px;
        min-width:44px;
        margin-bottom:0rem;
        border-radius: 0rem 0.1rem 0.1rem 0rem !important;
		background-color: white;
    }
}

.notification_dot.convo_dot{
	height:20px;
	width:20px;
	right:10%;
	top:8%;
	border: 2px solid white;
}

.convo-side-icon{
    margin-left:auto;
    margin-right:auto;
    width:24px;
    @media screen and (max-width:$desktop){
        width:24px;
    }
}

.convo-long-controls .convo-side-icon{
    @media screen and (max-width:$desktop){
        width:24px !important;
    }
}

.comment-icon-adjust{
    width:24px;
    @media screen and (max-width:$desktop){
        width:24px;
    }
}

.convo-side-col .top-set{
    margin-top:1rem;
    margin-bottom: auto;
    display: flex;
    @media screen and (max-width:$desktop){
        margin-top:1rem;
    }
}

.convo-side-col .bottom-set{
    margin-bottom:1rem;
    margin-top:auto;
    display: flex;
    @media screen and (max-width:$desktop){
        margin-bottom:0.5rem;
    }
}

.convo-side-col .dotdotdot-container{
    width:100%;
    display:flex;
}

.convo-comment-count{
    position:absolute;
    color:#585858;
    font-weight:600;
    font-size: 9px;
    bottom: 8px;
    right: 10px;
    @media screen and (max-width:$desktop){
		font-size: 9px;
        bottom: 8px;
        right: 9px;
    }
}

.convo-side-col .my-flex-column{
    height:100%;
    width:100%;
}

.convo_bar{
	width:45px;
	border-radius:0.5rem;
	min-height: 90px;
	height:100%;
    background-color: white;
	@media screen and (max-width:$desktop){
		// width:35px;
		min-height:70px;
		// margin-left:0rem !important;
		border:none;
        border-radius: 0rem !important;
    }
}

.convo_bar .side-col{
	margin-right: 0rem;
	padding-left:0.33rem;
	margin-left:0rem;
}

.convo_bar .side-col .big_wrapper{
	margin-top:0.4rem !important;
}

.comment-back-convo{
	background-color:$back-grey;
	border-radius:0.25rem;
	margin-bottom: 0px;
	margin-top:0px;
	// overflow:hidden;
	@media screen and (max-width:$desktop){
		margin-top:0px;
		margin-bottom:0rem;
    }
}

.comment-back-convo .comment_rest{
	border-radius: 0.25rem !important;
}


.actionables .col{
    flex:auto;
}

// Circle

@mixin on-circle($item-count, $circle-size, $item-size) {
    position: relative;
    width:  $circle-size;
    height: $circle-size;
    padding: 1rem;
    border-radius: 50%; 
    list-style: none;       
    
    > * {
      display: block;
      position: absolute;
      top:  50%; 
      left: 50%;
      width:  $item-size;
      height: $item-size;
      margin: calc(-1 * ($item-size / 2));
    
      $angle: calc(360 / $item-count);
      $rot: 0;
  
      @for $i from 1 through $item-count {
        &:nth-of-type(#{$i}) {
          transform: 
            rotate($rot * 1deg) 
            translate(($circle-size / 2) - 0.2)
            rotate($rot * -1deg);
        }
  
        $rot: $rot + $angle;
      }
    }
  }

.circle-element{
    background-color: none;
    z-index:4;
}

.irl-circle{
	padding:.6rem;
}

.irl-circle .img-fluid{
	border-radius:2rem;
}

.circle-container_7 .irl-circle, .circle-container_8 .irl-circle{
	padding:0.6rem;
}

$circle-color: #6377823d;

.circle-container{
    background-color: $dark-blue;
    margin-left:auto;
    margin-right:auto;
    box-shadow:inset $darkest-dark 0px 0px 50px;
    // box-shadow: 20px 20px 40px $circle-color, 20px -20px 40px $circle-color, -20px 20px 40px $circle-color, -20px -20px 40px $circle-color;
}

.circle-container_1 {
    @include on-circle($item-count: 1, $circle-size: 8rem, $item-size: 50px); 
    @media screen and (max-width:$desktop){
        @include on-circle($item-count: 1, $circle-size: 8rem, $item-size: 50px); 
    }
}

.circle-container_2 {
    @include on-circle($item-count: 2, $circle-size: 8rem, $item-size: 50px); 
    @media screen and (max-width:$desktop){
        @include on-circle($item-count: 2, $circle-size: 8rem, $item-size: 50px); 
    }
}

.circle-container_3 {
    @include on-circle($item-count: 3, $circle-size: 8rem, $item-size: 50px); 
    @media screen and (max-width:$desktop){
        @include on-circle($item-count: 3, $circle-size: 8rem, $item-size: 50px); 
    }
}

.circle-container_4 {
    @include on-circle($item-count: 4, $circle-size: 8rem, $item-size: 50px); 
    @media screen and (max-width:$desktop){
        @include on-circle($item-count: 4, $circle-size: 8rem, $item-size: 50px); 
    }
}

.circle-container_5 {
    @include on-circle($item-count: 5, $circle-size: 8rem, $item-size: 50px); 
    @media screen and (max-width:$desktop){
        @include on-circle($item-count: 5, $circle-size: 8rem, $item-size: 50px); 
    }
}

.circle-container_6 {
    @include on-circle($item-count: 6, $circle-size: 8em, $item-size: 50px); 
    @media screen and (max-width:$desktop){
        @include on-circle($item-count: 6, $circle-size: 8rem, $item-size: 50px); 
    }
}

.circle-container_7 {
    @include on-circle($item-count: 7, $circle-size: 8em, $item-size: 50px); 
	@media screen and (max-width:$desktop){
        @include on-circle($item-count: 7, $circle-size: 8rem, $item-size: 50px); 
    }
}

.circle-container_8 {
    @include on-circle($item-count: 8, $circle-size: 8em, $item-size: 50x); 
	@media screen and (max-width:$desktop){
        @include on-circle($item-count: 8, $circle-size: 8rem, $item-size: 50px); 
    }
}

.fire-container{
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
	@media screen and (max-width:$desktop){
        top: calc(50% - 15px);
    	left: calc(50% - 15px);
    }
}

.fire-container .fire_icon{
    width: 30px;
    z-index:2;
	@media screen and (max-width:$desktop){
        width:30px;
    }
}

.fire-light-container{
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    @media screen and (max-width:$desktop){
        top: calc(50% - 25px);
        left: calc(50% - 25px);
    }
}

$fire-color: #e9cdaa47;

.fire-light{
    width:60px;
    height: 60px;
    border-radius: 2rem;
    //background-color: $dark-blue;
    //@include: 20px 20px 40px $fire-color, 20px -20px 40px $fire-color, -20px 20px 40px $fire-color, -20px -20px 40px $fire-color;
    @media screen and (max-width:$desktop){
        width: 50px;
        height: 50px;
        // box-shadow: 10px 10px 30px $fire-color, 10px -10px 30px $fire-color, -10px 10px 30px $fire-color, -10px -10px 30px $fire-color;
    }
}
 
.convo-selected{
	font-size:22px;
	color:$base-blue !important;
}

.convo-not-selected{
	color:gray;
	margin-top:auto;
	margin-bottom:auto;
}



.convo-back{
	background-color:$back-grey;
	border-radius: 0.25rem !important;
	margin-bottom:0px;
	overflow:hidden;
}

.convo-long-controls{
	background-color:white;
    width:100%;
	padding-top:0rem !important;
	padding:1.5rem;
    border-radius: $common-border-radius-down;
    @media screen and (max-width:$desktop){
        margin-left:0rem;
        border:none !important;
		background-color:white;
    }
}

.convo-long-controls .dotdotdot-container{
    width:auto;
    margin-left:0.5rem;
}

.btn-card-adjust{
    margin-left:auto;
    margin-top:0.4rem;
    display:flex;
	@media screen and (max-width:$desktop){
        margin-top: 0.5rem;
    }
}

.convo-cc-accordion .tox-tinymce{
	margin-bottom:0rem;
}

.convo-show .convo-cc-accordion .my-accordion{
	margin-top:5px;
	border-radius:0.25rem 0.25rem 0rem 0rem;
	overflow: hidden;
}

.convo-cc-accordion{
	border-radius:0.25rem;
	overflow: hidden;
	margin-bottom:5px;
}

.convo-cc-accordion .my-accordion{
	margin-bottom:0px;
}

.convo-index-col .convo-cc-accordion{
	margin-bottom:0px;
}
//################################################################################################## 
//####################     AVATAR ICON CREATOR     ############################  ######  ###########
// ############################################################################  ######  ###########
// #################################################################################################
// ###########################################################################  ########  ##########
//#############################################################################         ############
// #################################################################################################


.smalltext-onPhone{
    @media screen and (max-width:$desktop){
        font-size: 11px;
    }
}

.avatar-selection-top-row{
	// background-color: $back-grey;
	gap:0.5rem;
	flex-wrap:wrap;
	border-radius: $common-border-radius;
	box-shadow: $common-box-shadow;
    margin-bottom: 1.5rem;
	@media screen and (max-width:$desktop){
		border-radius: $common-border-radius-mobile;
		box-shadow:$common-box-shadow-mobile;
		margin-bottom:0rem;
	}
}

.checkbox-custom-image{
	width:25px;
	margin-top: 0.15rem;
    margin-right: 0.5rem;
	height:min-content;
	@media screen and (max-width:$desktop){
		margin-top:0rem;
		margin-left:-3px;
	}
}

.checkbox-custom-image:hover{
	cursor:pointer;
	transform:scale(0.95);
}


.randomize{
	width:fit-content;
	padding: 0.5rem;
	padding-left:1.5rem;
	padding-right:1.5rem;
	margin-top:0.75rem;
	border-radius:1rem;
    background-color: #eaeaea;
    color: black;
}

.randomize .strongish{
	font-weight:500;
}

.randomize:hover{
	background-color:#0082cb;
	color:white;
}

.text12{
	font-size: 11px;
}

.small-text{
	font-size:10px;
	font-weight:300;
}

.avatar-button-card{
    cursor: pointer;
	padding:0.5rem;
	padding-left:1.5rem;
	padding-right:1.5rem;
	border-radius:1rem;
    // border-bottom: $grey-border 2px solid;
    border-top:none;
	background-color:#eaeaea;
}

.mr-space{
	margin-right:4px;
}

.card_selected{
    // background-color: #ffcb8e !important;
	background-color: #0082cb;
	color:white;
    border-radius: 5rem;
    box-shadow:none !important;
}

.avatar-icon{
    padding:5px;
    width: 100%;
}

.avatar-icon:hover{
    padding:0px;
    cursor:pointer;
}

.avatar-icon-selected{
    // padding:0px;
    // width:calc(100% + 20px);
    // margin-left:-12.5px;
    // margin-top:-12.5px;
    border: #a5cade52 3pt inset ;
    border-radius:1rem;
}

.user-image-irl-customize{
	width:86px;
	height:86px;
	margin-top: auto;
    margin-bottom: auto;
	margin-right: 8px;
	margin-left:auto;
	overflow:hidden;
	border-radius:1rem;
	@media screen and (max-width:$desktop){
        width: 70px;
        height: 70px;
        margin-right:3px;
    }
}

.user-image-irl-customize-inner{
	width:100%;
	height:auto;
}

.user-image-abc{
    width: 150px;
    height: 150px;
	margin-left:auto;
    margin-top:-24px;
	margin-right:-24px;
    margin-bottom:0px;
    font-size: 11px;
    @media screen and (max-width:$desktop){
        width: 125px;
        height: 125px;
        margin-top:-25px;
        margin-bottom:-10px;
    }
}

.color-customize-container{
    margin-bottom: 1.5rem;
    padding-left: 0rem;
    padding-right: 0rem;
	// margin-left: -6px;
    // margin-right: -6px;
	width:100%;
    // width: calc(100% + 12px);
    // max-width: calc(100% + 12px);
	@media screen and (max-width:$desktop){
        margin-top:1rem;
		margin-bottom:0rem;
    }
}

.color-badge{
    width:25px;
    height:25px;
    border-radius: 1rem;
    margin-left:10px;
    margin-right:10px;
    margin-top:10px;
    margin-bottom:10px;
    cursor: pointer;
    @media screen and (max-width:$desktop){
        width: 20px;
        height: 20px;
        margin-top:5px;
        margin-bottom:10px;
        margin-left:5px;
        margin-right:5px;
    }
}

.color-badge:hover{
    width:30px;
    height:30px;
    margin-left:7.5px;
    margin-right:7.5px;
    margin-top:7.5px;
    margin-bottom:7.5px;
	@media screen and (max-width:$desktop){
        margin-top:0px;
        margin-bottom:2.5px;
        margin-left:0px;
        margin-right:2.5px;
    }
}

.color-badge:focus{
    width:30px;
    height:30px;
    margin-left:7.5px;
    margin-right:7.5px;
    margin-top:7.5px;
    margin-bottom:7.5px;
}

.note-product-img{
    width:150px;
    margin-left:auto;
    margin-right:auto;
    margin-top:-1rem;
    margin-bottom:-1rem;
    display:flex;
}

.product-note-btn{
	font-size: 15px;
	padding:0.25rem;
	padding-left:0.5rem;
	padding-right:0.5rem;
	background-color:#eaeaea;
	@media screen and (max-width:$desktop){
		font-size: 14px;
    }
}


// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ PAYMENTS $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

.payment_page_width{
	width:fit-content;
	margin-left:auto;
	margin-right:auto;
}

.stripe_payment_container{
	width:100%;
	height:100%;
	position:relative;
}

.not_signed_in_payment_screen{
	position:absolute;
	width:inherit;
	height:inherit;
	top:0px;
	left:0px;
	z-index:10;
}

.not_signed_in_payment_screen:hover{
	cursor:pointer;
}












// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@         @@@@@@@@@@@@
// @@@@@ USER PAGE @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@  @   @  @@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@  _____/ @@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@         @@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@     @@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@           @@@@@@@@@@@

// Biz-card css is alllllll sorts of messy. Needs to be cleaned but no time 😥
// when it says is_clickableblaclass_two > two refers to the floating business cards. While (one) refers to the user page. 
// Fuck it... burn it with fire.

.user-page-container{
	background-color:$back-grey;
	border-radius:0.5rem;
	@media screen and (max-width:$desktop){
		background-color:#cdd7de;
    }
}

.user-page-adjustables-container{
	margin-top:3rem;
	background-color:$back-grey;
	border-radius:0.5rem 0.5rem 0rem 0rem;
	@media screen and (max-width:$desktop){
		margin-top:0px;
		background-color:#cdd7de;
    }
	
}

.friend-accord-controls{
	border-radius: $common-border-radius !important;
	overflow:hidden;
	margin-bottom:5px;
	@media screen and (max-width:$desktop){
		margin-bottom:$margin-mobile-1;
    }
}


.awards-gap-control{
	gap:1px;
	margin-bottom: 5px;
	display:flex;
	flex-direction: column;
	border-radius:$common-border-radius !important;
	overflow:hidden;
	@media screen and (max-width:$desktop){
		margin-bottom:$margin-mobile-1;
		margin-top:$margin-mobile-1;
    }
}

.awards-gap-control .abs-card{
	margin-bottom:0rem;
	border-radius:0rem;
}

.user-page-top{
    width:100%;
	padding:1.5rem !important;
	border-radius: 0.25rem 0.25rem 0rem 0rem !important;
}

.user-page-top .user-image-container-circle{
	width:100px;
	height:100px;
	border-radius:1rem;
}

.user-page-top .whiteBack{
	width:60px;
	height:60px;
}

.liquid_user_page_row{
	// margin-bottom:5px !important;
}


.user-image-container{
    width:40%;
    border-radius: 0.5rem 0rem 0rem 0.5rem !important;
    border-radius: 0.5rem;
    overflow: hidden;
    height:100%;
    min-height:180px;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:$dark-blue;
    @media screen and (max-width:$desktop){
        min-height:100px;
    }
}

.tidbit-area{
    display:flex;
	flex-direction: column;
	align-items:end;
	gap:0.5rem;
    margin-top:auto;
    margin-bottom:0px;
    width:100%;
}

.tidbit-text{
    color:$darkest-dark !important;
    margin-right:0.5rem;
    font-size: 11px;
    margin-bottom:0px;
}

.tidbit-text-user-page{
    @extend .tidbit-text;
    font-size: 15px;
	margin-left:auto;
    margin-top:auto;
    margin-bottom:auto !important;
	@media screen and (max-width:$desktop){
	    font-size:14px;
	}
}

.tidbit-icon-user-page{
	margin-top:auto;
	margin-bottom:auto;
    width:15px;
    height:15px;
	border-radius:0px;
    @media screen and (max-width:$desktop){
        width:15px;
        height:15px;
    }
}

.settings-page-biz-card{
	margin-top:1.5rem;
	margin-bottom:1rem;
}

.tidbit-container-dd{
	min-width:60vw;
	@media screen and (max-width:$desktop){
        min-width:90vw
    }
}

.tidbit-dd{
	width:24px;
	height:24px;
	margin-right:auto;
	margin-left:auto;
	margin-top: 0.5rem;
    margin-bottom: 0.5rem;
	border-radius:0rem;
}

.s-li .tidbit-dd:hover{
	transform:scale(0.97);
	cursor:pointer;
	filter: invert(78%) sepia(4%) saturate(5944%) hue-rotate(171deg) brightness(92%) contrast(94%);
}

.custom-user-card-col{
	justify-content: space-between;
	height:100%;
}

.float-biz-card{
    z-index:70;
    width:300px;
    height:175px;
    top:-12px;
    right:5.5rem;
    border-radius:0.25rem;
    overflow: hidden;
    box-shadow:#6c6c6c52 -2px 2px 2px 0px;
}

.float-biz-card h6{
	font-size:1rem !important;
}

.user-image-bizCard{
    width: 100%;
    height: 100%;
    object-fit:cover;
    align-self: center;
	border-radius: 0.25rem 0rem 0rem 0.25rem !important;
    // box-shadow: #34343438 0px 0px 2px 1px;
}

.user-image-container-bizCard{
    width:40%;
    border-radius: 0.25rem 0rem 0rem 0.25rem !important;
    overflow: hidden;
    height:100%;
    min-height:180px;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:$dark-blue;
    @media screen and (max-width:$desktop){
        min-height:100px;
    }
}

.biz-card-content{
    width:60% !important;
    border-radius: 0rem 0.25rem 0.25rem 0rem !important;
    padding-top:1rem !important;
    padding-bottom:1rem !important;
	padding-right:1rem !important;
    padding-left:1rem !important;
    margin-bottom:0rem !important;
    // box-shadow: #34343438 -1px 1px 3px 2px;
    @media screen and (max-width:$desktop){
        min-height:100px;
        padding-top:0.5rem !important;
        padding-bottom:0.5rem !important;
        padding-left:0.5rem !important;
        padding-right:0.5rem !important;
    }
}

.tidbit-area-bizCard{
	gap:0.5rem;
	margin-left:auto;
}

.tidbit-icon-bizCard{
	margin-left:0.5rem;
    width:15px;
    height:auto;
	border-radius:0px;
}

.tidbit-text-bizCard{
	font-size: 11px;
	margin-bottom:0rem !important;
	line-height:1.5 !important;
	color:#909090;
}

.award-bar{
    width:100%;
    margin-right:auto;
    margin-left:auto;
    flex-wrap:nowrap;
    border-radius:2rem;
    height:100%;
	gap:0;
	@media screen and (max-width:$desktop){
		gap:0.25rem;
    }
}

.award-bar:hover{
    background-color:$light-grey;
}

.award-small-icon-container{
    width:30px;
    height:30px;
    margin-top:auto;
    margin-bottom:auto;
	background-color:white;
	border-radius: 3rem;
    margin-right:auto;
    margin-left:auto;
	padding:2px;
    position:relative;
	margin-left:-8px;
    @media screen and (max-width:$desktop){
        width:30px;
        height:30px;
		margin-left:-10px;
    }
}

.award-is-being-annoying.filter-orange{
	padding:3px;
}

.award-top-row{
	justify-content: space-between !important;
	padding:1.5rem !important;
}

.award-small-icon{
    width:100%;
	height:100%;
    display: block;
	object-fit: cover;
	border-radius:3rem;
	background-color:#eaeaea;
}

.user-page-award-n-option-container{
	padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
	border-bottom: 1px solid #eaeaea;
}

.user-page-award-n-option-row{
	margin-left:auto;
	gap:2rem;
}

.user_page_user_feed{
	margin-top: 5px;
}

.user-page-message{
    border-radius:0.25rem;
    height:100%;
    display:flex;
    @media screen and (max-width:$desktop){
        padding:0.25rem;
    }
}

.user-page-message:hover{
    cursor:pointer;
}

.user-page-ddd-container{
    width:max-content;
    display:flex;
	padding:0.25rem;
	padding-left:1rem;
	padding-right:1rem;
	margin-right:-1rem;
    border-radius:2rem;
	height:100%;
	
    // margin-right:1rem;
}

.user-page-ddd-container:hover{
    background-color:$hover-grey;
    cursor:pointer;
}

.user-page-ddd-container-over-pic{
	position: absolute;
	padding:0.25rem;
	background-color:white;
	z-index:10;
	bottom:-0.5rem;
	left:-0.5rem;
	border-radius:0.25rem;
}

.user-page-ddd-container-over-pic:hover{
    background-color:$hover-grey;
    cursor:pointer;
}

.user-page-ddd{
    margin-top:auto;
    margin-bottom: auto;
	width:22px;
}

.user-page-adjustables-container .ddd-accord-row{
	border-radius:0rem !important;
}


.ddd-accord-row{
	justify-content: left !important;
	display:flex;
	margin-bottom:1px !important;
}

.ddd-accord-icon{
	width:26px;
}

.ddd-accord-row .dotdotdot-container{
	flex:auto;
	width:auto;
}

.ddd-accord-row .dotdotdot_btn{
	padding:0px !important;
	margin-top:0px;
	margin-right:0px;
}

.ddd-accord-row .dotdotdot{
	width:auto;
}

.friend_card_link{
	position:relative;
}

.friend-card .dotdotdot-container{
	flex:auto;
	width:auto;
}

.friend-card .dotdotdot_dropdown{
	margin-top:-2rem !important;
}


.friend-card .dotdotdot_btn{
	padding:1rem !important;
	width:55px;
	margin-left:auto;
	margin-top:0px;
	margin-right:-1rem;
	position:relative;
	transform:none !important;
	background-color:white;
}

.friend-card .dotdotdot{
	width:auto;
}

.friend-card{
	background-color: white;
	position:relative;
	height:100px;
	border-bottom:1px solid #eaeaea;
	border-radius:0rem;
}

.friend-list-back .friend-card{
	margin-bottom:0rem;
}

.friend-card:hover{
	cursor:pointer;
	transform:scale(0.99) !important;
	z-index:5;
}

.friend-card-top{
	justify-content: space-between !important;
	border-bottom:1px solid #eaeaea;
	margin-bottom:0px !important;
	border-radius:0rem !important;
	padding:1.5rem !important;
}

.friend-card-content{
	justify-content: space-between !important;
	padding:0rem !important;
	padding-left:0rem !important;
	padding-right:1.5rem !important;
	margin-bottom:0rem !important;
	@media screen and (max-width:$desktop){
        padding-right:1rem !important;
    }
}

.friend-card-bottom{
	margin-bottom: 0rem !important;
	border-radius:0rem 0rem 0.25rem 0.25rem !important;
}

.friend-user-col{
	margin-top:auto;
	margin-bottom:auto;
	margin-left:1rem;
}

.friend-user-col .big_wrapper{
	width: 80px;
	min-width: 80px;
	@media screen and (max-width:$desktop){
        width: 60px;
		min-width: 60px;
    }
}

.friend-user-col .big_wrapper.irl{
	width: 45px;
	min-width: 45px;
	height:45px;
	margin-left: 17px;
    margin-top: 0.25rem;
    margin-right: 1rem;
	@media screen and (max-width:$desktop){
        width: 33px;
		min-width: 33px;
		height: 33px;
		margin-left: 15px;
		margin-top: 0px;
		margin-right: 13px;
    }
}


.friend-button-request{
	background-color:white;
	box-shadow: #40404040 -1px 1px 2px 0px;
	padding: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0.25rem;
    font-weight: 400;
	font-size: 11px;
    color: white;
}

.friend-button-request p{
	@media screen and (max-width:$desktop){
        font-size: 11px;
    }
}

.secondary-name-friend-card{
	font-size: 11px;
	margin-top:0.25rem;
}

.user-image-container-friendCard{
	width: 100px;
	color: black;
    height: auto;
}

.user-image-friendCard{
	object-fit: cover;
	border-radius: 0rem;
	height:100%;
	width:100%;
}

.friend_dummy_opacity{
	filter:opacity(0.5)
}

.emptyTextSpace{
	height:15px;
}

.friend-list-back{
	background-color: $back-grey;
	border-radius: $common-border-radius;
	padding-top:1px;
	overflow: hidden;
	display:flex;
	flex-direction:column;
}

.award-lrg-icon{
    width:40px;
    margin-right:1rem;
	@media screen and (max-width:$desktop){
        width:35px;
    }
}

.user-intro{
	background-color:white;
	border-radius: $common-border-radius;
	box-shadow: $common-box-shadow;
	height:auto;
	margin-top:0px;
	margin-bottom:0px;
    @media screen and (max-width:$desktop){
        padding:0rem !important;
		// border-bottom: 1px solid $grey-border;
		// margin-top:0.4rem;
    }
}

.userIntroContent{
	height:100%;
	background-color:white;
}

.userIntroContentButActually{
	height:100%;
	padding:1.5rem !important;
	border-bottom: 1px solid #eaeaea;
	@media screen and (max-width:$desktop){
        padding:1rem !important;
    }
}

.user-snippet-button{
	width:auto;
    border-radius: 2rem;
    margin-top: auto;
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
    margin-left: auto;
	padding: 0.75rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.user-snippet-button:hover{
	transform:scale(0.97);
}

.user-page-open{
	width:16px;
	padding-bottom: 1px;
	margin-right:0.5rem;
}

.user-page-close{
	@extend .user-page-open;
	margin-right:0rem;
}

.user-expandable-button{ 
	@extend .front-expandable-button;
}

.user-expandable-button-close{
	@extend .front-expandable-button-close;
}

.justify-content-between{
    justify-content: space-between;
}

.user-icon-dropdown-btn{
	background:none;
	border:none;
	padding:0rem;
	z-index: 10;
}

.user-icon-dropdown-btn:hover{
	transform: scale(1.2);
	transform-origin: center;
	z-index: 10;
}

.user-ranking-image .user-icon-dropdown-btn:hover{
	transform: scale(2);
	transform-origin: 10px;
}

.user-icon-dropdown{
	@extend .dotdotdot_dropdown;
	background-color:white;
	z-index: 100;
	position:absolute;
	padding:0.5rem;
}

.user-icon-dropdown .abs-card{
	padding-top:0.5rem;
	padding-bottom:0.5rem;
}

// 8888888888888888888888888888888888888888888888888888888888888888888888888888888888888
// 8888888888888888888888888888888888888888888888888888888888888888888888888888888888888
// 8888888888888888888888888888888888888888   888    888   888888888888888888888888888888
// 88888888888888888888888888888888888888888               88888888888888888888888888888
// 88888888 settings 88888888888888888888     8888888888     88888888888888888888888888
// 8888888888888888888888888888888888888888   8888888888    8888888888888888888888888888
// 88888888888888888888888888888888888888     8888888888     88888888888888888888888888
// 88888888888888888888888888888888888888888               88888888888888888888888888888
// 8888888888888888888888888888888888888888   888    888   88888888888888888888888888888
// 8888888888888888888888888888888888888888888888888888888888888888888888888888888888888  
// 

.dark-text-ni{
	color:$darkest-dark;
}

.blue-on-hover:hover .dark-text-ni{
	color:#1995d8 !important;
}

.dark-text{
	color:$darkest-dark !important;
}

.settings-card.accordion-button::after{
    @media screen and (max-width:$desktop){
        background-size:0.75rem;
        width:0.75rem;
        height:0.75rem;
        top:4px;
        left:4px;
    }
}

.settings-menu-row{
	margin-top:0rem;
	margin-bottom:2rem;
	justify-content:end;
	gap:0.5rem;
	border-radius:$common-border-radius;
	overflow: hidden;
	@media screen and (max-width:$desktop){
		margin-top:0rem;
		border-radius: $common-border-radius-mobile;
		gap:2px;
		margin-bottom:1rem;
		flex-wrap: wrap;
    	row-gap: 0.5rem;
	}
}

.settings-main-card{
    // min-height:800px;
	padding:1rem;
    @media screen and (max-width:$desktop){
        padding:0rem;
		padding-top:1rem;
		padding-bottom:1rem;
		margin-top:0rem;
    }
}

.settings-card{
    width:min-content;
	background-color:#eaeaea !important;
	padding-top:0.5rem;
	padding-bottom:0.5rem;
	border-radius:0.25rem;
    padding-left:1rem;
    padding-right:1rem;
    @media screen and (max-width:$desktop){
        padding-left:0.5rem;
        padding-right:0.5rem;
		border-radius:0rem;
    }
}

.settings-card:not(.collapsed){
	background-color:$bright-blue !important;
	color:white !important;
}

.settings-main-card .user-select-top .abs-card{
	padding:0rem !important;
}

.setting-input{
    margin-left: auto;
    margin-right: auto;
    overflow:hidden;
    padding: 0.5rem;
    font-size: 15px;
    border-radius: 0.5rem;
    border: 1px solid $grey-border;
    width: 100%;
    min-height:40px;
	@media screen and (max-width:$desktop){
	    font-size:14px;
	}
}

.tidbit-padding{
    width:100%;
    border-radius: 0.5rem;
    margin-left:auto;
    margin-right:auto;
    margin-top:0.5rem;
    @media screen and (max-width:$desktop){
        padding:0rem;
        border:none;
    }
}

.tidbit-padding .blue-btn{
	margin-top:1rem;
}

.s-icon-btn{
    background-color: $hover-grey;
	padding:0.5rem;
    border-radius: 0.5rem;
    height: 100%;
    width: 100%;
    align-content:center;
    justify-content: center;
    display:flex;
}

.s-icon-btn:hover{
    background-color: #bdbdbd;
}

.s-icon{
    padding:0.25rem;
    width:30px;
    margin-top:auto;
    margin-bottom:auto;
    @media screen and (max-width:$desktop){
        width:22px;
        padding:0.15rem;
    }
}

.s-icon-close{
	@extend .s-icon;
	width:26px;
    padding:0px;
	@media screen and (max-width:$desktop){
        width:22px;
    }
}

.s-icon-btn:after{
	display:none;
}

.settings-tidbit-main{
	padding:1rem !important;
	padding-top:0.5rem !important;
	padding-bottom:0.5rem !important;
	border:none !important;
	background-color: #f1f1f1;
}

.change-phn-eml-btn-in-settings{
	background-color:$bright-blue;
	color:white;
	margin-right:0rem;
}

.setting-text-feild{
    width:100%;
    height: 100%;
    border-radius: 0.5rem;
    border: 1px solid $grey-border;
    padding:0.25rem;
}

.setting-save-btn.loading_sequence{
	background-color:#eaeaea;
}

.settings-menu-row .setting-save-btn{
	@media screen and (max-width:$desktop){
		padding-right:0.5rem;
		padding-left:0.5rem;
		border-radius:0rem !important;
		margin-left: auto !important;
    }
}

.settings-menu-row .setting-save-btn .init_btn_text{
	@media screen and (max-width:$desktop){
		font-size: 11px !important;
    }
}

.setting-save-btn{
    width: 55px;
    height: 55px;
	padding:0.5rem;
	box-shadow: -1px 1px 2px 0px #949494;
	display:flex;
	margin-right:1rem;
	margin-top:1rem;
	margin-bottom:1rem;
    background-color: $bright-orange;
	color:white;
    width:auto;
    display:flex;
    border:none !important;
	margin-bottom:0rem;
    font-weight:600px;
	margin-left:2rem;
	border-radius:0.25rem !important;
	padding-right: 1rem;
    padding-left: 1rem;
    @media screen and (max-width:$desktop){
		margin-left: auto !important;
    }
}

.setting-save-btn .init_btn_text{
	@media screen and (max-width:$desktop){
		font-size: 16px !important;
    }
}

.setting-save-btn:hover{
    // background-color:$hover-grey !important;
    // color:$darkest-dark !important;
    border:none !important;
    box-shadow:none !important;
	transform:scale(0.97);
}

.blue-btn{
    background-color:$bright-blue;
    border:none !important;
    color:white !important;
    // font-weight:600;
}

.blue-btn:hover{
	transform:scale(0.97);
    // color:$darkest-dark !important;
    // background-color:#eaeaea;
}

.intro-yourself-card{
	margin-top: 3rem;
    background-color: white;
    padding: 1rem;
	max-width:700px;
    border-radius: 0.25rem;
	@media screen and (max-width:$desktop){
		margin-top:0rem;
		border-radius:0rem;
		max-width:2000px;
    }
}

.intro-yourself .mf_editor{
	background-color:#f5f5f5;
	border-radius:0.5rem;
	padding:1rem;
}

.icebreaker_img{
	max-width:400px;
	margin-left:auto;
	margin-right:auto;
}

.icebreaker-btn{
	max-width:400px;
	margin-left:auto;
}

// }}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}
// {{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{
// }}}}}}}}}}}}}}}}}}}}}}}}}}}  INVITE PEER   }}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}
// {{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{
// }}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}

.auto-x-margins{
	margin-left:auto;
	margin-right:auto;
	display:flex;
}

.tidbit-Suggestion{
	margin:1rem;
	margin-bottom:2rem;
	padding:1rem;
	background-color:#ffe8cc;
	border-radius: 0.5rem !important;
	@media screen and (max-width:$desktop){
		border-radius:0.1rem !important;
		margin:0rem;
		margin-bottom:2rem;
    }
}

// ///////////////////////////////////////////////////////////////////////
// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// MARKETING >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
// ///////////////////////////////////////////////////////////////////////


//                       _        _   _             _           _
//  _ __ ___   __ _ _ __| | _____| |_(_)_ __   __ _| |__  _   _| |__
// | '_ ` _ \ / _` | '__| |/ / _ \ __| | '_ \ / _` | '_ \| | | | '_ \
// | | | | | | (_| | |  |   <  __/ |_| | | | | (_| | | | | |_| | |_) |
// |_| |_| |_|\__,_|_|  |_|\_\___|\__|_|_| |_|\__, |_| |_|\__,_|_.__/
//   										  |___/

.sales_contact_us{
	padding:1rem;
	height:100%;
	display: flex;
    flex-direction: column;
}

.sales_email_btn{
	display:flex;
}

.sales_contact_us h3{
	font-weight: 400;
}

.sales_contact_us h3.strong{
	font-weight: 700;
}

.sales_email_btn button{
	border:none;
	background-color: $base-orange;
    color: white;
    padding: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 1rem;
	margin-left:auto;
}

.sales_email_btn button:hover{
	transform: scale(0.96);
}

.MH_notesCard2_container{
	@extend .MH_notesCard;
	background-color: #00000000 !important;
	padding:0rem !important;
	border-radius:0rem !important;
	box-shadow: none !important;
	max-width:750px !important;
}

.MH_notesCard_ninja{
	padding:0rem;
	background-color: white;
	border-radius:0.5rem;
}

.twoThirdPart{
	padding:2rem;
	width:60%;
}

.ninja_cat_container{
	width:40%;
	position:relative;
	display:flex;
}

.MH_ninjaCat{
	width:240px;
	height:fit-content;
	margin-top:auto;
}

.MH_paperplane{
	position:absolute;
	top:-4rem;
	right:-4rem;
	width:200px;
}

.topics_explaination{
	width:50%;
}

.MH_TE_pic{
	width:100%;
	height:100%;
}



.MH_notesCard2{
	width:100%;
	background-color:white;
	border-radius:0.5rem;
	padding:2rem;
	box-shadow:#4040405e 0px 1px 2px 0px;
}


.MH_notesCard{
	z-index:2;
	position:relative;
	padding:2rem;
	background-color:white;
	margin-left:auto;
	margin-right:auto;
	border-radius:0.5rem;
	width:50%;
	max-width:500px;
	box-shadow:#4040405e 0px 1px 2px 0px;
	@media screen and (max-width:$big-desktop) and (min-width:$desktop){
		width:60%;
    }
	@media screen and (max-width:$desktop) and (min-width:$small-desktop){
		width:75%;
    }
	@media screen and (max-width:$small-desktop) and (min-width:$tablet){
		width:80%;
    }
	@media screen and (max-width:$tablet) and (min-width:$phone){
		width: 85%;
    }
    @media screen and (max-width:$phone){
		width:85%;
    }
}

.MH_notesCard .MH-button-container{
	width:40%;
	margin-left:auto;
	max-width:150px;
	@media screen and (max-width:$tablet) and (min-width:$phone){
		width: 50%;
    }
	@media screen and (max-width:$phone){
		width:60%;
    }
}

.MH_notesCard h1{
	font-size:56px;
	font-weight:400;
	line-height:1.1;
	@media screen and (max-width:$phone){
		font-size:36px;
	}	
}

.MH_notesCard h1.strong{
	font-weight:800;
}

.MH_notesCard h3{
	font-size:24px;
	font-weight:400;
}

.MH_notesCard h3.strongOrange{
	font-weight:800;
	color:#ffab43; 
}

.MH_notesCard .sign-up-app-button{
	background-color: #ffab43;
}

.MH_notesCard ul{
	padding-left:1rem !important;
}


.crowdImage{
	@extend .MH_notesCard;
	padding:0rem !important;
	background-color:transparent;
	position:relative;
	height:0px;
	overflow:visible;
	z-index:auto;
}

.crowdImage .notes-crowdFront{
	z-index: 3;
    position: absolute;
    top: -108px;
    left: -6.5rem;
    width: calc(100% + 13rem) !important;
    height: auto;
	max-width: none;
	pointer-events: none;
	@media screen and (max-width:$phone){
		top: -80px;
	}
}

.crowdImage .notes-crowdBack{
	z-index: 0;
    position: absolute;
    top: -108px;
    left: -6.5rem;
    width: calc(100% + 13rem) !important;
    height: auto;
	max-width: none;
	pointer-events: none;
	@media screen and (max-width:$phone){
		top: -80px;
	}
}

.MH_rightImageContainer{
	height:0px;
	width:100%;
	position:relative;
}

.MH_leftImageContainer{
	height:0px;
	width:100%;
	position:relative;
}

.MH_rightImage{
	position: absolute;
    height: 338px;
    width: 200px;
	bottom: 90px;
    right: -220px;
    z-index: 100;
    max-width: none;
	@media screen and (max-width:$small-desktop){
		display:none;
	}
}

.MH_leftImage{
	position: absolute;
    height: 338px;
    width: 200px;
    top: 100px;
    left: -220px;
    z-index: 100;
    max-width: none;
	@media screen and (max-width:$small-desktop){
		display:none;
	}
}

.MH_rightImage_bottom{
	position: absolute;
    height: 200px;
    width: 400px;
    bottom: -200px;
    left: 0px;
    z-index: 100;
	display:none;
    max-width: none;
	@media screen and (max-width:$small-desktop){
		display:block;
	}
}

.MH_leftImage_top{
	position: absolute;
	height: 200px;
    width: 400px;
    top: -200px;
    right: 0px;
    z-index: 100;
	display:none;
    max-width: none;
	@media screen and (max-width:$small-desktop){
		display:block;
	}
}


.mb-crazy{
	margin-bottom:6rem !important;
	@media screen and (max-width:$small-desktop){
		margin-bottom:4rem !important;
	}
}

.MH_seussCard{
	position:relative;
	background-color:white;
	margin-left:auto;
	margin-right:auto;
	border-radius:0.5rem;
	width:50%;
	max-width:650px;
	box-shadow:#4040405e 1px 1px 2px 0px;
	@media screen and (max-width:$big-desktop) and (min-width:$desktop){
		width:60%;
    }
	@media screen and (max-width:$desktop) and (min-width:$small-desktop){
		width:75%;
    }
	@media screen and (max-width:$small-desktop) and (min-width:$tablet){
		width:85%;
    }
	@media screen and (max-width:$tablet) and (min-width:$phone){
		width: 95%;
    }
    @media screen and (max-width:$phone){
		width:100%;
		border-radius:0rem;
    }
}

.MH_stripeBlue .MH_seussCard{
	@media screen and (max-width:$phone){
		background-color:#8cbae7;
	}
}

.seuss_img{
	width:55%;
	border-radius: 0.5rem 0rem 0rem 0.5rem;
	object-fit:fill;
	@media screen and (max-width:$phone){
		display:none;
    }
}


.seuss_img_tall{
	display:none;	
	@media screen and (max-width:$phone){
		width:calc(100% - 4rem);
		border-radius: 0.25rem;
		display:block;
		min-height:500px;
		object-fit:cover;
		margin: 2rem;
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
}

.seussPhone{
	display:none;
	@media screen and (max-width:$phone){
		width:auto;
		top:2rem;
		left:1rem;
		position:absolute;
		border-radius: 0.25rem;
		background-color:white;
		padding:1rem;
		display:flex;
    }
}

.seussPhone2{
	display:none;
	@media screen and (max-width:$phone){
		width:auto;
		bottom:calc(2rem + 6px);
		right:1rem;
		position:absolute;
		border-radius: 0.25rem;
		display:flex;
		padding: 1rem;
        background-color: #8cbae7ad;
    }
}

.seussPhone2 .MH-button{
	background-color:white;

}

.side_seuss{
	width:45%;
	padding:2rem;
	@media screen and (max-width:$small-desktop) and (min-width:$tablet){
		padding:1.5rem;
    }
	@media screen and (max-width:$tablet) and (min-width:$phone){
		padding:1rem;
    }
	@media screen and (max-width:$phone){
		display:none;
    }
}

.MH_seussCard_pricing{
	@extend .MH_seussCard;
	position: relative;
	@media screen and (max-width:$phone){
		width: calc(100% - 2rem);
		border-radius: 0.5rem;
		box-shadow: none;
    }
}

.side_seuss_pricing{
	width:55%;
	padding:0.25rem;
	border-radius: 0rem 0.5rem 0.5rem 0rem;
	@media screen and (max-width:$phone){
		height: auto;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		/* padding: 0rem; */
		/* overflow: hidden; */
		position: relative;
		border-radius: 0.5rem;
    }
}

.seuss_img_pricing{
	width:45%;
	border-radius: 0.5rem 0rem 0rem 0.5rem;
	object-fit:fill;
	@media screen and (max-width:$phone){
		// height: 100%;
		// min-width: 100%;
		// object-fit: cover;
		// border-radius: 0rem;
		// position: absolute;
		display:none;
    }
}

.seuss_img_mobile_pricing{
	display:none;
	@media screen and (max-width:$phone){
		width:100%;
		border-radius: 0rem;
		display:block;
		min-height:500px;
		object-fit:cover;
    }
}

.seussPricingBack{
	// @media screen and (max-width:$phone){
	// 	padding-top:0rem !important;
	// 	padding-bottom:0rem !important;
    // }
}

.MH_screenshot{
	width:60%;
	max-width:600px;
	border-radius:0.5rem;
}

.br-05{
	border-radius: 0.5rem;
}

.br-025{
	border-radius:0.25rem;
}


.screenshots_adjustments{
	position:relative;
	margin-top:8rem;
	margin-bottom:8rem;
	// width:50% !important;
	max-width:1000px !important;
	@media screen and (max-width:$phone){
		margin-top:3rem;
		margin-bottom:3rem;
		width:calc(100% - 2rem) !important;
	}
}

.screenshots_adjustments:last-child{
	@media screen and (max-width:$phone){
		margin-bottom:0rem;
	}
}

.screenshots_adjustments .phoneDisplay b{
	font-size:10px;
	margin-bottom:0.5rem;
}

.screenshots_adjustments .phoneDisplay p{
	font-size:10px;
	margin-bottom:0rem;
}

.screenshots_adjustments .my-avg-card{
	@media screen and (max-width:$phone){
		border-radius: 0.5rem !important;
		line-height: 12px !important;
	}
}

.MH_screenshot_back{
	position:absolute;
	border-radius:0.5rem;
	z-index:0;
	height:90%;
	top: 50%;
  	left: 50%;
 	transform: translate(-50%, -50%);
	 @media screen and (max-width:$phone){
		width:90%;
	 }
}

.improve-container{
	height:230px;
	position:relative;
	@media screen and (max-width:$big-desktop) and (min-width:$desktop){
		height: 200px;
    }
	@media screen and (max-width:$desktop) and (min-width:$small-desktop){
		height: 185px;
    }
	@media screen and (max-width:$small-desktop) and (min-width:$tablet){
		height: 175px;
    }
	@media screen and (max-width:$tablet) and (min-width:$phone){
		height: 135px;
    }
    @media screen and (max-width:$phone){
		display:none;
    }
}

.improve-img{
	position: absolute;
    height: 230px;
    width: auto;
	max-width:none;
	@media screen and (max-width:$big-desktop) and (min-width:$desktop){
		height: 200px;
    }
	@media screen and (max-width:$desktop) and (min-width:$small-desktop){
		height: 185px;
    }
	@media screen and (max-width:$small-desktop) and (min-width:$tablet){
		height: 175px;
    }
	@media screen and (max-width:$tablet) and (min-width:$phone){
		height: 135px;
    }
    @media screen and (max-width:$phone){
		display:none;
    }

}

.kingEditImg{
	width:75px;
	height:75px;
}

.MH_50_50{
	width:50%;
	display:flex;
	flex-direction: column;
	// align-items:center;
	justify-content: center;
	@media screen and (max-width:$phone){
		width:100%;
    }

}

.MH_aboutUsImg{
	max-height:350px;
	width:auto;
	object-fit: contain;
}

.MH_standardCont{
	margin-left:auto;
	margin-right:auto;
	width:70%;
	max-width:650px;
	column-gap:1rem;
	@media screen and (max-width:$tablet) and (min-width:$phone){
		width:85%;
    }
	@media screen and (max-width:$phone){
		width:90%;
		flex-direction:column !important;
	}
}

.mobile-col{
	@media screen and (max-width:$phone){
		flex-direction:column !important;
	}
}

.mobile-col-rev{
	@media screen and (max-width:$phone){
		flex-direction: column-reverse !important;
	}
}

.MH_standardCont h1{
	font-size:48px;
	@media screen and (max-width:$phone){
		font-size:32px;
	}
}

.MH_extra_bottom_margin{
	padding-bottom:8rem !important;
	@media screen and (max-width:$phone){
		padding-bottom:5rem !important;
	}
}

.MH_blueBack{
	background-color: #8CBCEB;
	padding-top:6rem;
	padding-bottom:6rem;
	position:relative;
	@media screen and (max-width:$phone){
		padding-top:4rem;
		padding-bottom:4rem;
    }
}

.big_spacing{
	padding-top:10rem !important;
	padding-bottom:10rem !important;
	@media screen and (max-width:$phone){
		padding-top:4rem !important;
		padding-bottom:4rem !important;
    }
}

.MH_whiteBack{
	background-color:#f2f7f9;
	padding-top:6rem;
	padding-bottom:6rem;
	position:relative;
	@media screen and (max-width:$phone){
		padding-top:3rem;
		padding-bottom:3rem;
    }
}


.big_spacing.fml{
	@media screen and (max-width:$small-desktop) and (min-width:$phone) {
		padding-top:16rem !important;
		padding-bottom:16rem !important;
    }
	@media screen and (max-width:$phone){
		padding-top:14rem !important;
		padding-bottom:14rem !important;
    }
}

.big_spacing.MH_whiteBack{
	@media screen and (max-width:$small-desktop){
		padding-top:6rem !important;
		padding-bottom:6rem !important;
    }
}



.aboutUs_page .MH_blueBack{
	padding-top:4rem;
	padding-bottom:4rem;
	@media screen and (max-width:$phone){
		padding-top:2rem;
		padding-bottom:2rem;
    }
}

.disNone-onMobile{
	@media screen and (max-width:$phone){
		display:none;
    }
}

.MH_imageBack{
	position:relative;
	overflow:hidden;
	padding-top:6rem;
	padding-bottom:6rem;
	background-color: #619DD5;
	@media screen and (max-width:$phone){
		padding-top:3rem;
		padding-bottom:3rem;
    }
}

.img_back_inner{
	position:absolute;
	min-width:100%;
	min-height:100%;
	height:auto;
	top: 0;
    left: 0;
	@media screen and (max-width:$phone){
		display:none;
    }
}

.MH_stripeBlue{
	@extend .MH_blueBack;
	background: repeating-linear-gradient(-45deg, #7BB3ED, #7BB3ED calc(25%), #93C6F8 calc(25%), #93C6F8 calc(75%), #7BB3ED calc(75%),#7BB3ED);
	@media screen and (max-width:$phone){
		padding-top:0rem;
		padding-bottom:0rem;
    }
}

.MH_stripeOrange{
	@extend .MH_blueBack;
	background: repeating-linear-gradient(-45deg, #ffc95e, #ffc95e 25%, #FFDD9A 25%, #FFDD9A 75%, #ffc95e 75%, #ffc95e);
	@media screen and (max-width:$phone){
		padding-top:3rem;
		padding-bottom:3rem;
    }
}

.MH_orangeBack{
	@extend .MH_blueBack;
	background-color: #FFDD9A;
}

.MH_lightBlueBack{
	@extend .MH_blueBack;
	background-color: #BCDEFF;
}

.MH_darkBlueBack{
	@extend .MH_blueBack;
	background-color: #4c7295;
}

.makeSmarterContainer{
	display:flex;
}

.MH_threeImgs{
	display:flex;
	flex-direction:row;
	flex-wrap: nowrap;
	width:80%;
	margin-left:auto;
	margin-right:auto;
	justify-content: center;
	gap:2rem;
	max-width:1000px !important;
	@media screen and (max-width:$phone){
		flex-direction:column;
		width:calc(100% - 2rem);
	}
}

.MH_circleRow{
	display:flex;
	flex-direction: column;
	justify-content: center;
	max-width:250px;
	@media screen and (max-width:$small-desktop){
		flex-direction: row;
	}
}

.circleRowText{
	text-align: center;
	margin-top: auto;
	margin-bottom: auto;
	@media screen and (max-width:$small-desktop){
		text-align: left;
	}
}

.MH_circleInfoBox{
	width:30%;
	// min-width:150px;
	max-width:300px;
	display:flex;
	flex-direction:column;
	padding-left: 1rem;
    padding-right: 1rem;
	text-align:center;
	@media screen and (max-width:$phone){
		width:100% !important;
	}
	
}

.MH_circleImg{
	max-width:230px;
	margin-left:auto;
	margin-right:auto;
	@media screen and (max-width:$small-desktop) and (min-width:$tablet){
		max-width:200px;
	}
	@media screen and (max-width:$tablet) and (min-width:$phone){
		max-width:180px;
	}
	@media screen and (max-width:$phone){
		max-width:160px;
	}
}

.MH_whatWeDo{
	width:80% !important;
	display:flex;
	flex-direction: row !important;
	justify-content: space-between;
	max-width:950px !important;
	@media screen and (max-width:$small-desktop){
		flex-direction:column !important;
		width:calc(100% - 2rem) !important;
	}
}

.MH_whatWeDo h3{
	font-size:42px !important;
	font-weight:500 !important;
	@media screen and (max-width:$phone){
		font-size:32px !important;
	}
}

.MH_whatWeDo h3.strong{
	font-size:42px !important;
	font-weight:700 !important;
	@media screen and (max-width:$phone){
		font-size:32px !important;
	}
}

.MH_manyTopics{
	width:80% !important;
	display:flex;
	flex-direction: column !important;
	max-width:900px !important;
	@media screen and (max-width:$phone){
		width:calc(100% - 2rem) !important;
	}
}

.MH_manyTopics h3{
	font-size:42px !important;
	font-weight:500 !important;
	@media screen and (max-width:$phone){
		font-size:32px !important;
	}
}

.MH_liquidTrust_card{
	padding: 2rem;
    background-color: white;
    border-radius: 0.5rem;
    height: min-content;
}

.MH_liquidTrust_card ul{
	padding-left:1rem !important;
}

.MakeSmarterImg{
	width:60%;
	height:auto;
	margin-left:auto;
	margin-right:auto;
	max-width:800px;
	@media screen and (max-width:$tablet){
		width:80%;
	}	
	@media screen and (max-width:$phone){
		display:none;
	}
}

.improveCycle{
	width:60%;
	@media screen and (max-width:$phone){
		width:100% !important;
	}
}

.MH_sixDocs_card{
	padding: 2rem;
    background-color: white;
    border-radius: 0.5rem;
	width:60%;
    height: min-content;
	overflow:hidden;
	margin-left:auto;
	margin-right:auto;
	@media screen and (max-width:$phone){
		width:90% !important;
	}
}

.MH_sixDocs_card h3{
	font-size:24px !important;
}

.sixDocs{
	width: calc(100% + 4.5rem);
    margin-left: -2rem;
    margin-bottom: -2.1rem;
	max-width: calc(100% + 4.5rem);
}


.MakeSmarterImgMobile{
	display:none;
	height:auto;
	margin-left:auto;
	margin-right:auto;
	@media screen and (max-width:$phone){
		display:flex;
		width:100%;
	}
}

.phoneDisplay{
	display:none;
	@media screen and (max-width:$phone){
		display:flex;
	}
}

.compDisplay{
	display:flex;
	@media screen and (max-width:$phone){
		display:none;
	}
}

.makeSmarterImgBtn{
	display:flex;
	width:60%;
	height:auto;
	margin-left:auto;
	margin-right:auto;
	max-width:800px;
	@media screen and (max-width:$phone){
		width:100%;
	}
}

.makeSmarterImgBtn .enter-app-button{
	background-color:$base-orange;
}


.MH_lightOrangeBack{
	@extend .MH_blueBack;
	background-color:#FFE9C0;
}

.MH_cardy{
	background-color: white;
	width:100%;
	padding:2rem;
	border-radius:0.5rem;
	z-index:1;
	position:relative;
}

.MH_twoCardtwoImgs{
	margin-left:auto;
	margin-right:auto;
	display:flex;
	flex-direction:row;
	flex-wrap:nowrap;
	width:60%;
	max-width:650px;
	gap:2rem;
	@media screen and (max-width:$tablet) and (min-width:$phone){
		width:85%;
    }
	@media screen and (max-width:$phone){
		width:90%;
	}
}

.mt-1-but-mobile{
	margin-top:1rem;
	@media screen and (max-width:$phone){
		margin-top:0.25rem;
	}
}

.intro_triangle_text{
	margin-left:-1rem;
}

.MH_intro_triangle{
	width:40%;
	max-width:700px;
	margin-left:auto;
	margin-right:auto;
	@media screen and (max-width:$big-desktop) and (min-width:$desktop){
		width:600px;
    }
	@media screen and (max-width:$desktop) and (min-width:$small-desktop){
		width:550px;
    }
	@media screen and (max-width:$small-desktop) and (min-width:$tablet){
		width:510px;
    }
	@media screen and (max-width:$tablet) and (min-width:$phone){
		width:460px;
	}
	@media screen and (max-width:$phone){
		width:250px;
	}
}

.triangle_container{
	width:620px;
	max-height:900px;
	margin-right:auto;
	margin-left:auto;
	position:relative;
	height:100vh;
	max-width: 700px;
    max-height: 900px;
	@media screen and (max-width:$big-desktop) and (min-width:$desktop){
		width:600px;
		max-height:860px;
    }
	@media screen and (max-width:$desktop) and (min-width:$small-desktop){
		width:550px;
		max-height:800px;
    }
	@media screen and (max-width:$small-desktop) and (min-width:$tablet){
		width:510px;
		max-height:720px;
    }
	@media screen and (max-width:$tablet) and (min-width:$phone){
		width:460px;
		max-height:680px;
	}
	@media screen and (max-width:$phone){
		width: calc(100% - 4rem);
        max-height: 100%;
	}
}

.triangle_container h1{
	font-size:42px;
	line-height: 42px;
	margin-bottom:4rem;
	@media screen and (max-width:$tablet){
		font-size:24px;
		line-height: 30px;
		margin-bottom:3rem;
	}
}


.triangle_left_img{
	position:absolute;
	height:auto;
	width:100%;
	top:0rem;
	left:-1rem;
	@media screen and (max-width:$phone){
		display:none;
	}
}

.triangle_left_img_phone{
	@extend .triangle_left_img;
	display:none;
	@media screen and (max-width:$phone){
		display:flex;
		left:0rem;
		top:1rem;
	}
}

.p_forum_rec_orange{
	
	bottom:0rem;
	right:-1rem;
	position:absolute;
	z-index:10;
	background-color:#FFC76B;
	padding:1rem;
	border-radius:0.5rem;
	width:250px;
	text-align:left;
	@media screen and (max-width:$phone){
		width:200px;
	}
}


.triangle_right_img{
	position:absolute;
	height:auto;
	width:100%;
	bottom:0rem;
	right:-1rem;
	@media screen and (max-width:$phone){
		display:none;
	}
}

.triangle_right_img_phone{
	@extend .triangle_right_img;
	display:none;
	
	@media screen and (max-width:$phone){
		display:flex;
		right:0rem;
		bottom:1rem;
	}
}

.p_forum_rec_blue{
	top:0rem;
	left:-1rem;
	position:absolute;
	z-index:10;
	background-color:#68B2FF;
	padding:1rem;
	border-radius:0.5rem;
	width:250px;
	text-align:left;
	@media screen and (max-width:$phone){
		width:200px;
	}
}

.MH_contain_one{
	width:100%;
	gap:1rem;
	margin-left:auto;
	margin-right:auto;
	display:flex;
	flex-direction:row;
	flex-wrap:nowrap;
	@media screen and (max-width:$phone){
		display:flex;
		flex-direction:column;
		flex-wrap:nowrap;
    }
}

.triangle_btn{
	border-radius:0.5rem !important;
	margin-right:auto;
	justify-content: center;
	text-align:center;
}

.MH_lightblueBtn{
	background-color: #C8DFFA !important;
}

.MH_lightorangeBtn{
	background-color: #EDDEBC !important;
}

.MH_whiteBtn{
	background-color: white !important;
}

.MH_back_triangle_1{
	position:relative;
}

.triangle_back_right_img{
	position:absolute;
	top: -2rem;
    right: -2.5rem;
    height: 550px;
	@media screen and (max-width:$big-desktop) and (min-width:$desktop){
		height: 500px;
    }
	@media screen and (max-width:$desktop) and (min-width:$small-desktop){
		height: 450px;
		top: -1rem;
		right: -1.5rem;
    }
	@media screen and (max-width:$small-desktop) and (min-width:$tablet){
		height: 425px;
		top: -1rem;
		right: -1.5rem;
    }
	@media screen and (max-width:$tablet){
		height: 400px;
		top: -1rem;
		right: -1.5rem;
	}
}

.triangle_back_left_img{
	position:absolute;
	top: -2rem;
    left: -2.5rem;
    height: 550px;
	@media screen and (max-width:$big-desktop) and (min-width:$desktop){
		height: 500px;
    }
	@media screen and (max-width:$desktop) and (min-width:$small-desktop){
		height: 450px;
		top: -1rem;
    	left: -1.5rem;
    }
	@media screen and (max-width:$small-desktop) and (min-width:$tablet){
		height: 425px;
		top: -1rem;
    	left: -1.5rem;
    }
	@media screen and (max-width:$tablet){
		height: 400px;
		top: -1rem;
    	left: -1.5rem;
	}
}

.MH_cardy_1{
	@extend .MH_cardy;
	margin-right:2rem;
	margin-top:2rem;
	@media screen and (max-width:$phone){
        margin-right:1rem;
		margin-top:1rem;
    }
}


.MH_cardy_2{
	@extend .MH_cardy;
	margin-left:2rem;
	margin-top:2rem;
	@media screen and (max-width:$phone){
        margin-right:1rem;
		margin-top:1rem;
    }
}

.MH_contain_two{
	width:60%;
	max-width:650px;
	@extend .MH_contain_one;
	@media screen and (max-width:$tablet){
		width:85%;
	}
}

.MH_contain_three{
	@extend .MH_contain_two;
	flex-direction:column;
	@media screen and (max-width:$small-desktop){
		width:85%;
	}
}

.MH_screenshotHeaderCards .MH-button{
	background-color:#ffab43 !important;
}


.MH_pattern_card_container{
	padding:2rem;
	border-radius:0.5rem;
	background-color:#a1d1ff;
	position:relative;
	width:calc(60% + 4rem);
	max-width:calc(650px + 4rem);
	@media screen and (max-width:$phone){
        padding:0rem;
		width:calc(100% - 2rem);
		background-color:#bcdeff;
    }
}

.MH_pattern_card{
	gap:0.5rem;
	padding:2rem;
}

// .MH_smallImageBigText_image{
// 	width:20%;
// 	height:auto;
// 	margin-bottom:auto;
// }

.MH_smallImageBigText_text{
	width:100%;
	height:auto;
	gap: 5px;
    display: flex;
    flex-direction: column;
}

.MH_screenshotHeaderCards{
	margin-left:auto;
	margin-right:auto;
	display:flex;
	flex-direction:row;
	flex-wrap:nowrap;
	width:50%;
	max-width:500px;
	gap:2rem;
	@media screen and (max-width:$tablet) and (min-width:$phone){
		width:85%;
    }
	@media screen and (max-width:$phone){
		width:90%;
	}
}

.MH_screenshotHeaderCards .MH_cardy{
	padding:2rem;
	box-shadow: rgba(64, 64, 64, 0.368627451) 0px 1px 2px 0px;
}

.MH_screenshotHeaderCards h3{
	font-size:32px;
	@media screen and (max-width:$phone){
		font-size:24px;
	}
}

.MH_screenshotHeaderCards .h3-logo.craycray_logo{
	font-size:32px;
	font-weight:600;
	display:inline;
	@media screen and (max-width:$phone){
		font-size:24px;
	}
}

.MH_40_img{
	width:40%;
	height:auto;
	object-fit: contain;
	@media screen and (max-width:$phone){
        width:auto;
		max-height:250px;
		align-self: center;
    }
}

.MH_2cards_2img{
	width:40%;
	height:auto;
	min-height:200px;
	object-fit: contain;
	@media screen and (max-width:$phone){
        display:none;
    }
}

.MH_2cards_2img_phone{
	display:none;
	@media screen and (max-width:$phone){
        width:80%;
		display:flex;
		margin-left: auto;
    	margin-right: auto;
    }
}

.MH_contain_one_text{
	width:60%;
	text-align:left;
	margin-top:auto;
	margin-bottom:auto;
	@media screen and (max-width:$phone){
		width:100%;
	}
}

.MH_contain_one h1{
	font-size:60px;
	line-height:1.1;
	@media screen and (max-width:$desktop){
		font-size:32px;
	}
}

.MH_blue_text{
	color:#008FFF;
}

.MH_contain_one h2{
	font-size:36px;
	font-weight:300;
	line-height:1.1;
	@media screen and (max-width:$desktop){
		font-size:32px;
	}
}

.MH_contain_one h2.strong{
	font-size:36px;
	font-weight:600;
	@media screen and (max-width:$desktop){
		font-size:32px;
	}
}

.MH_contain_two .MH-button-container.mt-1{
	@media screen and (max-width:$desktop){
		margin-top:0rem !important;
	}
}

.provides_gap{
	gap:0.5rem;
}

.provides_button{
	padding:1rem;
	padding-left:2rem;
	padding-right:2rem;
	background-color:white;
	border-radius:1rem;
}

.provides_button h1{
	font-size:32px;
	@media screen and (max-width:$desktop){
		font-size:24px;
	}
}

.h1-logo.craycray_logo{
	font-size:50px;
	font-weight:400;
	letter-spacing:-0.12em;
	margin-right:0.25em;
	line-height:1.1;
	@media screen and (max-width:$tablet){
		font-size:36px;
	}
}

.h2-logo.craycray_logo{
	font-size:50px;
	font-weight:300;
	letter-spacing:-0.12em;
	margin-right:0.25em;
	line-height:1.1;
	margin-bottom:0.25rem;
	@media screen and (max-width:$desktop){
		font-size:32px;
	}
}

.h3-logo.craycray_logo{
	font-size:17px;
	font-weight:400;
	display: inline;
}

.s-logo.craycray_logo{
	font-size:11px;
	font-weight:400;
	display: inline;
}

.p-logo.craycray_logo{
	font-size:15px;
	font-weight:400;
	display: inline;
}

.craycray_logo{
	letter-spacing:-0.115em;
	margin-right:0.2em;
	display:inline;
}

.competition-time-text{
	// background: linear-gradient(90deg, #0084e5, #085d9b);
	// -webkit-background-clip: text;
	width:fit-content;
	// background-clip: text;
	// color: transparent;
	// -webkit-text-fill-color: transparent;
	color:#585858;
	font-size:26px;
	font-weight:700 !important;
	margin-bottom:0px;
	@media screen and (max-width:$phone){
		margin-bottom:2px;
		font-size:22px;
	}
}

.sideCard-TitleText .craycray_logo{
	font-size:16px !important;   
	color: #0073b0 !important;
    font-weight: 500;
	letter-spacing: 0em;
}

.sideCard-TitleText p{
	font-size:16px !important;   
}

.sideCard-TitleText .upside_down_i{
	margin-bottom:0rem;
}

.upside_down_i{
	transform: scaleY(-0.9);
	display: inline-block !important;
}

.cycle-heading{
	width:50%;
	justify-content:center;
	@media screen and (max-width:$desktop){
		width:100%;
	}
}

.cycle-heading h3{
	font-size:1.5rem;
	font-weight:400;
}

.cycle-heading h3.strong{
	font-weight:600;
}

.cycle-text-container{
	position:relative;
	height:200px;
	width:50%;
	max-width:650px;
	margin-left:auto;
	margin-right:auto;
}

.cycle-text{
	width:200px;
	position: absolute;
	top: 50%;
	left:50%;
	opacity: 0;
	transform: translate(-50%, -50%);
	transition: opacity 1s ease-in-out;
	// @media screen and (max-width:$phone){
	// 	margin-bottom:-1px !important;
	// }
}

.cycle-text.active{
	opacity: 1;
	transition: opacity 1s ease-in-out;
}

.cycle-img{
	width:100%;
	z-index:1;
}

.kingOhillImg{
	border-radius:1rem;
}

.bookImg{
	width:65%;
	margin-left:auto;
	margin-right:auto;
}

.MH_orange_text{
	color:#D18C27;
}

.MH_contain_text_40{
	width:40%;
	text-align:left;
	margin-top:auto;
	margin-bottom:auto;
	@media screen and (max-width:$desktop){
		width:100%;
	}
}

.MH_60_img{
	width:60%;
	@media screen and (max-width:$desktop){
		width:100%;
	}
}

.opacity-0{
	opacity:0;
}

.phrase_repeat{
	white-space: nowrap;
	font-size: 11px;
	position: absolute;
	bottom:-4px;
	color:#8b8b8b;
	z-index:1;
	font-weight:300;
	max-width: 100vw;
	overflow-x:hidden;
	@media screen and (max-width:$desktop){
		font-size: 8px;
		bottom:-3px;
    }
}

.phrase_light{
	@media screen and (max-width:$phone){
		// color:#c7c7c7;
		bottom:-9px;
	}
}

.phrase_dark{
	@extend .phrase_repeat;
	color:#6b6b6b;
}

.phrase_dark .PR_highlight{
	color:#0076cd;
}

.phrase_repeat p{
	font-size:11px;
	color:#adadad;
}

.PR_highlight{
	color:#5b9dd0;
	font-weight:400;
}

.be_orange .PR_highlight{
	color:#d39d4e;
	font-weight:400;
}

.priceTextLarge{
	font-size:3rem;
	font-weight:600;
}

.gray-text{
	color:#8f8f8f;
}

.featureTableContainer{
	overflow-x: auto;
	margin-right: auto;
    margin-left: auto;
	@media screen and (max-width:$phone){
		margin-left:1rem;
	}
}

.featureTable{
	min-width:100%;
	width: fit-content;
	margin-left:auto;
	margin-right:auto;
	gap:0.5rem;
	@media screen and (max-width:$phone){
		padding-right:1rem;
	}
}

.featureCol_1{
	background-color:white;
	border-radius:0.5rem;
	overflow:hidden;
}

.featureCol_2{
	background-color:white;
	border-radius:0.5rem;
	overflow:hidden;
}

.featureCol_3{
	background-color:white;
	border-radius:0.5rem;
	overflow:hidden;
}

.featureCol_4{
	background-color:white;
	border-radius:0.5rem;
	overflow:hidden;
}

.featureTableTop{
	color:white;
	padding: 0.5rem;
	height:40px;
	display:flex;
	min-width:150px;
	justify-content:center;
}

.featureCol_1 .featureTableTop{
	background-color:#404040;
	
}

.featureCol_2 .featureTableTop{
	background-color: $base-blue;
	// color:$darkest-dark;
}

.featureCol_3 .featureTableTop{
	background-color: $base-orange;
	// color:$darkest-dark;
}

.featureCol_4 .featureTableTop{
	background-color: #21c49e;
	// color:$darkest-dark;
}

.featureTableHighlight{
	background-color:#eaeaea;
	padding: 0.5rem;
	height:40px;
	display:flex;
	white-space: nowrap;
}

.featureTableRow{
	align-content:center;
	justify-content: center;
	padding: 0.5rem;
	height:40px;
	display:flex;
	white-space: nowrap;
}

.featureCol_1 .featureTableRow{
	justify-content: start;
}

.featureTableTop p{
	margin-bottom:0rem;
	font-weight:500;
}

.featureTableHighlight p{
	margin-bottom: 0rem;
}

.featureTableRow p{
	margin-bottom: 0rem;
}

.MH_problemContainer{
	width:60%;
	max-width:550px;
	justify-content: space-between;
	background-color:white;
	border-radius:0.5rem;
	padding:1rem;
	margin-bottom:1rem;
	display:flex;
	flex-direction: row;
	margin-right:auto;
	margin-left:auto;
	align-items: center;
	@media screen and (max-width:$tablet) and (min-width:$phone){
		width:85%;
		padding:1rem;
    }
	@media screen and (max-width:$phone){
		width:90%;
		padding:1rem;
		flex-direction: column;
	}
}

.MH_problemContainer h2{
	font-weight:600;
}

.MH_problemContainer:last-child{
	margin-bottom:0rem;
}

.MH_problemContainer-col{
	@extend .MH_problemContainer;
	padding:1rem;
	flex-direction: column;
	margin-bottom:0rem;
}

.MH_prob_button{
	margin-top:1rem;
	width:60%;
	max-width:550px;
	margin-left:auto;
	margin-right:auto;
	display:flex;
	justify-content: right;
	@media screen and (max-width:$tablet) and (min-width:$phone){
		width:85%;
    }
	@media screen and (max-width:$phone){
		width:90%;
	}
}

.MH_prob_button_wide{
	@extend .MH_prob_button;
	width:80%;
	max-width:900px;
}

.SEOimg{
	background-color:#bcdeff;
	padding:1rem;
	padding-top:0rem;
	padding-bottom:0rem;
    // margin-left: -1rem;
    // margin-right: -1rem;
    // width: calc(100% + 2rem);
    // max-width: calc(100% + 2rem);
    // margin-bottom: -1rem;
    margin-top: 0rem;
	// border-top:2px solid $base-blue;
}

.MH_bs{
	box-shadow: 1px 1px 1px 1px #40404040;
}

.MH_scale{
	border-radius: 1rem;
	@extend .MH_clear_component;
	width:70% !important;
	max-width:750px !important;
	display:flex;
	flex-direction: column;
	margin-left:auto;
	margin-right:auto;
	overflow:hidden;

	@media screen and (max-width:$tablet) and (min-width:$phone){
		width:85% !important;
    }
	@media screen and (max-width:$phone){
		width:90% !important;
	}
}

.MH_scale ul{
	padding-left:1rem;
}

.img-scale{
	border-radius:0rem 0rem 0.5rem 0.5rem;
	width: calc(100% + 4px);
	height:calc(100% + 2px);
	max-width: none;
	top: 2px;
	position:relative;
}

.MH_clear_component{
	width:60%;
	max-width:550px;
	display:flex;
	flex-direction: column;
	margin-left:auto;
	margin-right:auto;
	@media screen and (max-width:$tablet) and (min-width:$phone){
		width:85%;
    }
	@media screen and (max-width:$phone){
		width:90%;
	}
}

.MH_clear_component h3{
	font-size:28px;
	font-weight:400;
	@media screen and (max-width:$phone){
		font-size:22px;
	}
}

.MH_clear_component h3.strong{
	font-size:28px;
	font-weight:700;
	@media screen and (max-width:$phone){
		font-size:22px;
	}
}

// .MH_clearComponent_button{
// 	@extend .MH_prob_button;

// }

.problemImg{
	width:140px;
	height:140px;
	margin-left:1rem;
	@media screen and (max-width:$phone){
		margin-left:0rem;
    }
}

.MH_columnContainer{
	@extend .MH_problemContainer;
	max-width:650px;
	padding-left:1rem;
	padding-right:1rem;
	padding:1rem;
	flex-direction:row !important;
}

.MH_columnContainer h1{
	font-size:32px;
	@media screen and (max-width:$tablet){
		font-size:24px;
	}

}

.MH_columnContainer h1.strong{
	font-weight: 800;
	color:$base-blue;
}


.col-lil-text{
	@media screen and (max-width:$phone){
		font-size:14px;
	}
}

.col-lil-text p{
	@media screen and (max-width:$phone){
		font-size:14px;
	}
}


.columnImgRel{
	width:160px;
	min-width:160px;
	height:auto;
	min-height:160px;
	margin-left:0rem;
	margin-bottom:0rem;
	position:relative;
	@media screen and (max-width:$small-desktop) and (min-width:$phone){
		width:120px;
		min-width:120px;
		min-height:170px;
	}
	@media screen and (max-width:$phone){
		width:90px;
		min-width:90px;
		display:none;
	}
}

.columnImgAbs{
	width:160px;
	height:160px;
	bottom:-1rem;
	position:absolute;
	@media screen and (max-width:$small-desktop) and (min-width:$phone){
		width:120px;
		min-width:120px;
		min-height:170px;
	}
	@media screen and (max-width:$phone){
		width:90px;
		min-width:90px;
		bottom:-1.15rem;
		display:none;
	}
}

.columnImgRel_short{
	display:none;
	@media screen and (max-width:$phone){
		display:block;
		width:100%;
		min-width:160px;
		height:auto;
		min-height:100px;
		margin-left:0rem;
		margin-bottom:0rem;
		position:relative;
	}
}

.columnImgAbs_short{
	display:none;
	@media screen and (max-width:$phone){
		display:block;
		width:160px;
		min-width:160px;
		height:auto;
		min-height:100px;
		bottom:-1rem;
		left: 50%;
		transform: translateX(-50%);
		position:absolute;
	}
}


.colSml{
	display:block;
	@media screen and (max-width:$small-desktop) {
		display:none;
	}
}

.colMid{
	display:none;
	@media screen and (max-width:$small-desktop) and (min-width:$phone){
		display:block;
	}
}

//                       _        _   _             _           _
//  _ __ ___   __ _ _ __| | _____| |_(_)_ __   __ _| |__  _   _| |__
// | '_ ` _ \ / _` | '__| |/ / _ \ __| | '_ \ / _` | '_ \| | | | '_ \
// | | | | | | (_| | |  |   <  __/ |_| | | | | (_| | | | | |_| | |_) |
// |_| |_| |_|\__,_|_|  |_|\_\___|\__|_|_| |_|\__, |_| |_|\__,_|_.__/
// 										  |___/
//  ___  _     ____
// / _ \| |   |  _ \
// | | | | |   | | | |
// | |_| | |___| |_| |
// \___/|_____|____/


.app-wide .nav-accord{
	margin-bottom:0rem;
}

.app-wide .nav-accord .width-in{
	width:100% !important;
}

.app-wide .avatar-container{
	margin-right:0.75rem;
	@media screen and (max-width:$desktop){
		margin-right:0.25rem;
    }
}

.MH_logo_copyWriting{
	width: 416px;
    height: 150px;
    display: flex;
    justify-content: flex-start;
    margin-right: auto;
    margin-bottom: -2.5rem;
	filter: invert(100%) sepia(5%) saturate(19%) hue-rotate(215deg) brightness(103%) contrast(102%);
	@media screen and (max-width:$desktop){
		width:277px;
		height:100px;
		margin-bottom: -1.5rem;
    }
}

.MH_img_front{
	position:absolute;
	top:4px;
}

.marketingHubMainContainer{
	
	align-content: center;
	justify-content: end;
	max-height:90vh;
	width:100%;
	margin-bottom:3rem;
	overflow:hidden;
}

.marketingHubMain{
	width:100%;
	height:100%;
	object-fit:cover;
	object-position:bottom;
	border-radius:0rem;
	display:block;
	@media screen and (min-width:$desktop){
		display:none;
    }
}

.marketingHubMain_wide{
	@extend .marketingHubMain;
	display:none !important;
	@media screen and (min-width:$desktop){
		display:block !important;
    }
}

.marketing_hub_back{
	background:white !important;
	min-height:100vh;
	height:100%;
}

.MH_topcontainer{
	width:100%;
	display:flex;
	top:0rem;
	height:100%;
}

.MH_toptext{
	gap:1rem;
	padding-bottom:9rem !important;
	width:auto;
	// max-width:700px;
	@media screen and (max-width:$phone){
        padding-bottom:6rem !important;
    }
}

.topText_statement{
	margin-left:2rem;
	@media screen and (max-width:$phone){
        margin-left:1.25rem;
    }
}

.MH_MainExplainer_container{
	background-color:#e9edf0;
	color: $darkest-dark !important;
	border-radius:0.5rem;
	width:60%;
	max-width:700px;
	margin-left:auto;
	margin-right:auto;
	padding:1rem;
	gap:1rem;
	margin-bottom:1rem;
	@media screen and (max-width:$phone){
		width: calc(100% - 1rem);
		margin-left: 0.5rem;
		margin-right: 0.5rem;
		margin-bottom: 0.5rem;
	}
}

.MH_MainExplainer_container h1{
	font-size: 2rem;
	margin-bottom:0.1rem;
	@media screen and (max-width:$phone){
		font-size:1.5rem;
	}
}


.MH_valueExplainer_container{
	@extend .MH_MainExplainer_container;
	gap:0rem;
	background-color: #e9edf0;
    border-radius: 0.5rem;
   
	margin-top:1rem;
	margin-bottom:1rem;
}

.MH_valueExplainer_container ul{
	margin-bottom:0rem;
}

.MH_TopExplainer_container{
	@extend .MH_MainExplainer_container;
	gap:0rem
}

.MH_TopExplainer_container p{
	font-size:15px;
}


.MH_TopExplainer_container h1{
	font-size: 15px;
	font-weight:500;
	// margin-top:0.25rem;
	margin-bottom:0.5rem;
	@media screen and (max-width:$phone){
		font-size: 15px;
	}
}

.MH_top_explainer_img{
	margin-left:auto;
	margin-right:auto;
	width:80%;
	margin-bottom:1rem;
	@media screen and (max-width:$phone){
		width:90%;
	}
}

.KOH_img{
	border-radius:1.5rem;
	margin-top:0.5rem;
}

.orange-text{
	color:#cb8a12;
}



.MH_exampleExplainer_container{
	@extend .MH_MainExplainer_container;
	background-color:white;
	padding:0rem;
	@media screen and (max-width:$phone){
		gap:0.5rem
	}
}

.MH_wide_img{
	width:100%;
}

.MH_explainer_row{
	gap:1rem;
	@media screen and (max-width:$phone){
		gap:0.5rem
	}
}

.MH_explainer_img{
	width:100%;
	height:fit-content;
	border-radius:0.5rem;
	@media screen and (max-width:$phone){
		display:none
	}
}

.MH_explainer_img_sml{
	display:none;
	@media screen and (max-width:$phone){
		display:flex;
		width:100%;
		height:fit-content;
		border-radius:0.5rem;
		margin-top:0.5rem;
	}
}

.MH_explainer_text{
	width:100%;
	gap:0.5rem;
	padding-top:1rem;
}


.MH_topcontainer h1{
	color:white;
	font-size:32px;
	font-weight:400;
	margin-bottom:0rem;
	@media screen and (max-width:$desktop) and (min-width:$tablet){
        font-size:28px;
    }
    @media screen and (max-width:$tablet) and (min-width:$phone){
        font-size:26px;
    }
    @media screen and (max-width:$phone){
        font-size:24px;
    }
}

.MH_topcontainer p{
	color:white !important;
	@media screen and (max-width:$phone){
        font-size: 11px;
    }
}

.MH_toptext .enter-app-button{
	background-color: #0C5F9D;
}

.MH_toptext .see-more-button{
	background-color:#8b511b;
}

.MH_toptext .sign-up-app-button{
	background-color:#8b511b;
}

.MH_toptext .MH-button p{
	color:white !important;
}

.MH-button-container{
	gap:0.5rem;
	@media screen and (max-width:$phone){
        gap:0.5rem;
    }
}

.MH-button{
	background-color:$back-grey;
	padding:0.5rem;
	border-radius:0.25rem;
	padding-left:1rem;
	padding-right:1rem;
	align-items:center;
	border:none;
	font-size:11px;
    @media screen and (max-width:$tablet){
		padding-left:0.75rem;
		padding-right:0.75rem;
    }
}

.MH-button p{
	font-size: 11px;
}

.MH-button:hover{
	transform:scale(0.97);
	cursor:pointer;
}

.MH_arrow_down{
	width: 15px;
    height: 15px;
    margin-left: 0.5rem;
	@media screen and (max-width:$phone){
		width: 15px;
		height: 15px;
    }
}

// .enter-app-button{
// 	background-color:#7bb3ed;
// }

// .sign-up-app-button{
// 	background-color: #ffb65b;
// }

.sign-up-app-button p{
	color:black !important;
}

// .see-more-button{
// 	background-color:rgb(255 196 104);
// }

.see-more-button p{
	color:black !important;
}

.see-more-button-2{
	background-color:#eaeaea;
}

.enter-app-button p{
	color:black !important;
}

.MH_button_row_by_its_self .sign-up-app-button{
	background-color: #ffc178;
}

.enter-app-button-2{
	@extend .enter-app-button;
	background-color:#eaeaea;
}

.MH_greycontainer{
	background-color:#e9edf0;
	color:black !important;
	border-radius:0.5rem;
	width:60%;
	max-width:700px;
	margin-top:1rem;
	margin-bottom:1rem;
	// @media screen and (max-width:$desktop) and (min-width:$tablet){
    //     width:calc(60% + 4rem);
	// 	margin-top:1rem;
	// 	margin-bottom:1rem;
    // }
    @media screen and (max-width:$tablet) and (min-width:$phone){
        width:calc(70% + 4rem);
		margin-top:1rem;
		margin-bottom:1rem;
    }
    @media screen and (max-width:$phone){
        width:90%;
		margin-top:1rem;
		margin-bottom:1rem;
    }
}

.MH_greycontainer_textbox{
	padding:1rem;
	padding-left:0rem;
	display:flex;
	@media screen and (max-width:$desktop) and (min-width:$phone){
		padding:2rem !important;
	}
	@media screen and (max-width:$phone){
        padding:1rem !important;
    }
}

.MH_greycontainer h1{
	font-size:1.25rem;
    @media screen and (max-width:$tablet) and (min-width:$phone){
        font-size:1.25rem;
    }
    @media screen and (max-width:$phone){
        font-size:1.25rem;
    }
}

.MH_greycontainer_image{
	width:40%;
	object-fit: contain;
	margin:1rem;
	@media screen and (max-width:$desktop){
		display:none !important;
		margin:0rem !important;
	}
}

.MH_gc_img_sml{
	display:none;
	@media screen and (max-width:$desktop){
		display:flex !important;
		width:100%;
		max-width:350px;
		margin-left:auto !important;
		margin-right:auto !important;
	}
}

.MH_tripleContainer{
	width:60%;
	gap:1rem;
	max-width:700px;
	// @media screen and (max-width:$desktop) and (min-width:$tablet){
    //     width:calc(60% + 4rem);
	// 	gap:1rem;
    // }
    @media screen and (max-width:$tablet) and (min-width:$phone){
        width:calc(70% + 4rem);
		gap:1rem;
    }
    @media screen and (max-width:$phone){
        width:90%;
		gap:0.5rem;
    }
}

.MH_tripcol_container{
	background-color:#e9edf0;
	border-radius:0.5rem;
	margin-bottom:1rem;
	@media screen and (max-width:$desktop){
		overflow:hidden;
		margin-bottom:1rem;
	}
}

.MH_tripcol_image{
	border-radius:0.1rem;
	margin:2rem;
	@media screen and (max-width:$desktop){
		margin:0rem;
	}
}

h1.MH_tripcol_text{
	font-size:1.25rem;
	@media screen and (max-width:$desktop){
		font-size:1rem;
	}
}

.MH_tripcol_text{
	color:$darkest-dark !important;
	margin-left:2rem;
	margin-right:2rem;
	margin-bottom:2rem;
	@media screen and (max-width:$desktop) and (min-width:$phone){
		margin:0rem !important;
		padding:1rem;
		font-size: 14px !important;
	}
	@media screen and (max-width:$phone){
        font-size: 11px;
		margin:0rem !important;
		padding:0.75rem;
    }
}

.MH_button_row_by_its_self{
	width:60%;
	max-width:700px;
	justify-content: end;
	margin-bottom:1rem;
	@media screen and (max-width:$desktop) and (min-width:$tablet){
        // width:calc(60% + 4rem);
		margin-bottom:1rem;
    }
    @media screen and (max-width:$tablet) and (min-width:$phone){
        width:calc(70% + 4rem);
		margin-bottom:1rem;
    }
    @media screen and (max-width:$phone){
        width:90%;
		margin-bottom:1rem;
    }
}

.MH_button_row_by_its_self .see-more-button{
	background-color:#e9edf0 !important;
}

.MH_button_row_by_its_self .see-more-button p{
	color:black !important;
}

.MH_button_row_by_its_self .enter-app-button{
	background-color: #67c7ff;
}

.MH-side-page{
	height:auto;
	min-height:15vh;
}


.MH-side-page .MH_toptext{
	gap: 0rem;
    padding: 1rem !important;
    margin-top: auto;
    background-color: rgb(0 0 0 / 83%);
	width:60% !important;
	max-width: 900px;
	@media screen and (max-width:$large-desktop) and (min-width:$tablet){
        width:70% !important;
    }
    @media screen and (max-width:$tablet) and (min-width:$phone){
        width:80% !important;
		margin-top: 4rem;
    }
    @media screen and (max-width:$phone){
        width:90% !important;
		margin-top: 3rem;
    }
}

.MH-side-page .MH_topcontainer h1{
	font-size:4rem;
	@media screen and (max-width:$large-desktop) and (min-width:$tablet){
        font-size:3rem;
    }
    @media screen and (max-width:$tablet) and (min-width:$phone){
        font-size:2.5rem;
    }
    @media screen and (max-width:$phone){
        font-size:2rem;
    }
}

.MH-sidepage-container h2{
	margin-bottom:0rem !important;
	margin-top:0.5rem !important;
}

.MH_linkBackText{
	position: absolute;
	font-size: 11px;
    top: -1.5rem;
    left: 1rem;
    color: #64749d !important;
    font-weight: 300;
}

.MH_linkBackText:hover{
	font-weight:500;
	color:#0d63d4 !important;
	cursor: pointer;
}

.MH-sidepage-container{
	margin-left:auto;
	margin-right:auto;
	width:50%;
	max-width:700px;
	margin-top:2rem;
	@media screen and (max-width:$desktop) and (min-width:$tablet){
        width:65%;
    }
    @media screen and (max-width:$tablet) and (min-width:$phone){
        width:75%;
    }
    @media screen and (max-width:$phone){
        width:90%;
    }
}

.MH-sidepage-content{
	gap:1rem;
}

.MH_tripleContainer-sidepage{
	gap:1rem;
}

.MH_sideImage{
	margin:1rem;
}

.align-center{
	text-align: center;
}

.champ-text-color{
	color:orange;
	font-weight: 700;
}

.rival-text-color{
	color:red;
	font-weight: 700;
}

.MH_button_row_by_its_self-sidepage{
	width: 100%;
    justify-content: end;
    margin-bottom: 2rem;
}

.MH-sidepage-container-wide{
	padding:1rem;
	margin-left:auto;
	margin-right:auto;
}

.inviteFriendsLinkRow{
	justify-content: end;
	gap:0.5rem;
}

.inviteFriendsLinkRow .share-icon-drop{
	width:25px;
}

.one_meme{
	display:flex;
	flex-wrap: wrap;
	margin-left:auto;
	margin-right:auto;
	gap: 1rem;
    justify-content: center;
	max-width:1200px;
}

.meme-flexbox{
	display:flex;
	flex-wrap: wrap;
	margin-left:auto;
	margin-right:auto;
	gap: 1rem;
    justify-content: center;
	width:fit-content;
	max-width:500px;
}

.meme_container{
	width:auto;
	// max-width:300px;
	padding-top:1rem;
	padding-bottom:1rem;
	// background-color: #f6f6f6;
	height:100%;
}

.meme_container_wide{
	@extend .meme_container;
	max-width:500px;
}

.inviteFriendsHeader{
	text-align:center;
	margin-bottom:2rem;
}

.clickToCopy{
	padding: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    // margin-top: auto;
    background-color: #007fc7;
    margin-left: 1rem;
    width: auto;
    border-radius: 1rem;
}

.clickToCopy p{
	font-weight:600 !important;
	margin-bottom:0rem !important;
	color:white;

}

.meme-image{
	max-height:500px;
}

.clickToCopy .copiedText{
	display:none;
}

.copiedAnimation .copiedText{
	display:block !important;
}

.copiedAnimation .copyText{
	display:none !important;
}

.clickToCopy .copyText{
	display:hidden;
}

.copiedAnimation{
	animation-name: copied-animation;
	animation-duration:1s;
}

@keyframes copied-animation {
	0%   {transform: scale(1); background-color:#007fc7;}
	50% {transform: scale(0.9);background-color:#56c1ff;}
	100% {transform: scale(1); background-color:#007fc7;}
}

// ###################################################################################
// ###################################################################################
// ############################# WELCOME #############################################
// ###################################################################################
// ###################################################################################

.small-text-on-mobile{
	@media screen and (max-width:$desktop){
		font-size: 11px;
    }
}

.wel-v2-img{
	padding:0px;
	padding-left:2.5rem;
	padding-right:2.5rem;
	// min-height:350px;
	@media screen and (max-width:$desktop){
		padding-left:0rem;
		padding-right:0rem;
    }
}

.welcome-gray-box{
	// background-color:$light-grey-background;
	margin-top:1rem;
	margin-bottom:1rem;
	border-radius:1rem;
	padding:1rem;
	width:auto;
	height:auto;
	font-weight:500;
	z-index:3;
	@media screen and (max-width:$desktop){
		width:auto;
		max-width:100%;
    }
}

.welcome-gray-box p{
	font-size:16px !important;
}

.welcome-gray-box li{
	font-size:16px !important;
}

// Loading Button //////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////
// ...............................................................................

.init_btn_text{
	display:block;
}

.loading_sequence .init_btn_text{
	display:none;
}

.loading_sequence{
	color:black;
}

.loading_sequence .emoji_sequence_container{
	min-height:40px;
	width: 150px;
	background-color:#eaeaea;
	border-radius:0.25rem;
	margin-bottom:1rem;
	margin-top:1rem;
}

.create-post-page .emoji_sequence_container{
	margin-top:0rem;
	margin-bottom:0rem;
}

.emoji_sequence{
	font-size:22px;
	display:none;
	margin-top:auto;
	margin-bottom:auto;
	margin-left:auto;
	margin-right:auto;

}

.loading_sequence .emoji_sequence{
	display:block !important;
}

// ####################################################################################
// ################### TIP TAP ########################################################
// ####################################################################################

.mf_editor{
	min-height: 300px;
    padding: 1.5rem;
    background-color: white;
	
}

.post-page-post-text .mf_editor{
	min-height:50px;
	padding:0rem !important;
}

.new-post-text .mf_editor{
	min-height:100vh;
}

.editor-image{
    display: block;
	width: auto;
    height: auto !important;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 500px;
    border-radius: 0.25rem;
    min-height: 100px;
	margin-top:3rem;
	margin-bottom:3rem;
}


.editor-image.ProseMirror-selectednode{
	border:1px $base-blue solid;
}

// .editor-image::after{
// 	content: "";
// 	position: absolute;
// 	top: 0;
// 	right: 2px;
// 	width: 20px;
// 	height: 30px;
// 	border-right: 2px solid red;
// 	z-index:1000;
// 	background-color:red;
// 	// animation: blink 1s infinite;
// }
  
@keyframes blink{
0% {
	opacity: 0;
}
50% {
	opacity: 1;
}
100% {
	opacity: 0;
}
}


.editor-video{
	margin-top: 2rem;
    margin-bottom: 2rem;
	max-width:100%;
	margin-left: auto;
    display: block;
    margin-right: auto;
}

.ProseMirror{
	min-height:300px;
}

.post-page-post-text .ProseMirror{
	min-height:20px !important;
}

.ProseMirror:focus-visible{
	outline: none !important;
}

.editor-toolbar-section{
	background-color:$darkest-dark;
	padding:0.25rem;
	gap:0.25rem;
	padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.25rem;
}

.editor-toolbar{
	gap:0.5rem;
}


.editor-text-button{
	padding:0.25rem;
	padding-left:0.5rem;
	padding-right:0.5rem;
	color:white;
}

.editor-text-button:hover{
	cursor:pointer;
	transform:scale(0.95);
	color: $bright-blue;
    border-radius: 0.5rem;
}

// .editor-text-button.active{
// 	background-color: #eaeaea;
//     border-radius: 0.5rem;
// }

.text_editor_icon{
	width:calc(20px + 1rem);
	padding:0.4rem;
	@extend .filter-white;
}

.editor-button:hover{
	cursor:pointer;
	transform:scale(0.95);
}

.editor-button:hover .text_editor_icon{
	@extend .filter-light-gray;
}

.editor-button.active .text_editor_icon{
	@extend .filter-blue;
}

.editor-button.active:hover .text_editor_icon{
	@extend .filter-blue;
}

.header-dropdown-button{
	margin-top:auto;
	margin-bottom:auto;
}

.header-dropdown-content{
	position: absolute;
	width:max-content;
	top:48px;
	left:-8px;
	background-color:$darkest-dark;
	padding:0.25rem;
	border-radius:0.25rem;
}

.image-dropdown-content{
	position: absolute;
	width:max-content;
	top:48px;
	left:-8px;
	background-color:$darkest-dark;
	padding:0.25rem;
	border-radius:0.25rem;
}

.mentionsListCard{
	padding: 1rem;
    background-color: $darkest-dark;
    border-radius: 0.25rem;
    color: white;
}

.mentionsListCard ol{
	margin-bottom:0rem;
}

.activeMentionLi{
	color:$bright-blue;
}

.editor-mention{
	color:#0c53bb;
}

.tippy-box{
	max-width:100% !important;
}

.editorDrop{
	border-radius:0.5rem;
	margin-bottom:0.25rem;
	flex-direction:column;
	position:relative;
}

.editorDropOpen{
	// background-color:$base-blue-grad;
	// border:1px solid #d1d1d1;
	margin-bottom:1rem;
}

.editorDropClosed{
	// background-color:$base-orange-grad;
	// background-color:#eaeaea;
	// background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
	// display:flex;
}

.nonEditorPocket > :first-child:hover{
	cursor:pointer;
}


.editorDrop.editorDropOpen > :first-child{
	// border-bottom:1px solid #d1d1d1;
	margin-bottom:0.5rem;
	padding: 0.5rem;
	padding-top:10px;
	padding-bottom:10px;
	position: relative;
	padding-left: 1.5rem;
	// background-color:#eaeaea;
	// background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
	border-radius:0.5rem 0.5rem 0rem 0rem;
}

.editorDrop.editorDropClosed > :first-child{
	border-bottom:0px solid #d1d1d1;
	margin-bottom:auto;
	padding-top:10px;
	padding-bottom:10px;
	position: relative;
	padding-left: 1.5rem;
	padding-right:10px;
}

.editorDrop > :first-child::before{
	content: '';
	position: absolute;
	top: 50%;
	left: 5px;
	transform: translateY(-50%);
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-left: 7px solid #000;
	transition: transform 0.3s ease;
}

.editorDrop.editorDropOpen > :first-child::before{
	transform: translateY(-50%) rotate(90deg);
}

.editorDrop > :last-child{
	margin-bottom:0.5rem !important;
}




////////////////////////////////////////////////////////////////////////////////////////
// .editorDrop *:first-child::after{
// 	content: '';
// 	position: absolute;
// 	left: 50%;
// 	transform: translateX(-50%);
// 	bottom: -5px;
// 	width: calc(100% - 40px);
// 	height: 1px;
// 	background-color:  #d1d1d1;
// }

// .editorDrop.editorDropClosed *:first-child::after{
// 	content: '';
// 	position: absolute;
// 	left: 50%;
// 	transform: translateX(-50%);
// 	bottom: -5px;
// 	width: 50%;
// 	height: 0px;
// 	background-color: #00000000;
// }

.editorDrop > *:not(:first-child){
	margin: 0.5rem;
	padding-left:0rem;
    margin-left: 1.5rem;
    margin-right: 0rem;
}

.editorDrop #text{
	margin: 0.5rem;
    margin-left: 1.5rem;
    margin-right: 0rem;
}

.see-changes .diff_added.textPocket{
	// border:1px solid #abdbff !important;
	// margin-bottom:1rem;
	position:relative;
}

.see-changes .diff_added.textPocket::after{
	content: url(../images/icons/ya.svg);
	position: absolute;
	top: 13px;
    right: 10px;
    width: 35px;
    height: 15px;
}

.see-changes .diff_added.editorDrop::after{
	content: url(../images/icons/ya.svg);
	position: absolute;
	top: 13px;
    right: 10px;
    width: 35px;
    height: 15px;
}

.see-removed .diff_added.textPocket p{
	color: $diffy-add;
}

.see-removed .diff_removed.textPocket{
	display:block !important;
	// border:1px solid #ffdbae !important;
	margin-bottom:1rem;
}

.see-changes .diff_removed.textPocket::after{
	content: url(../images/icons/na.svg);
	position: absolute;
	top: 13px;
    right: 10px;
    width: 35px;
    height: 15px;
}

.see-changes .diff_removed.editorDrop::after{
	content: url(../images/icons/na.svg);
	position: absolute;
	top: 13px;
    right: 10px;
    width: 35px;
    height: 15px;
}

.see-removed .diff_removed.editorDrop{
	display:block !important;
}


.see-removed .diff_removed.textPocket p{
	color: $diffy-remove;
}

.diffAdded_highlight{
	position:relative;
}

.diffRemoved_highlight{
	position:relative;
}

.see-changes .diffAdded_highlight::after{
	content: url(../images/icons/cha.svg);
	position: absolute;
	top: 10px;
    right: 10px;
    width: 23px;
    height: 23px;
    padding: 0.1rem;
    background-color: white;
    border-radius: 1rem;
}

.see-changes .diffRemoved_highlight::after{
	content: url(../images/icons/cha.svg);
	position: absolute;
	top: 10px;
    right: 10px;
    width: 23px;
    height: 23px;
    padding: 0.1rem;
    background-color: white;
    border-radius: 1rem;
}

.editorDrop ol{
	margin-left:1rem;
}

.editorDrop ul{
	margin-left:1rem;
}

.editorDrop .editor-image{
	padding:1rem;
}

// 000000000000000000000000000000000000000000000000000000000000000000000000000000000000
// 0000000000000000.00000000000000000000000000000.00000000000000000000000000000000.0000
// 00000000000000000000000'0000000000000000000000000000000000000000000000000000.0000000
// 00000.000000000000000000000000000000000000000000000000000000000000000000000000000000
// 000000000000000000000000000000000000000000000000000000.00000000000000000000000000000
// 000000000000000000000.0000000000   SPACES   0000000000000000000000000000000.00000000
// 0000000000000____000000000000000000000000000000000000000000000000000.000000000000000
// 0000000000|        |0000000000000000000000000000000000000000000000000000000000000000
// 00000000(_|________|__)0000000.0000000000000000000000000000.000000000000000000000000
// 0000000000|        |0000000000000000000000000000000000000000000000000000000000000000
// 00000000000\______/0000000000000000.000000000000000000000000000000000000000.00000000
// 00000000000000000000000000000000000000000000000000000000.000000000000000000000000000

.spaces_logo_image{
	height:100%;
	margin-bottom:0px;
}

.think-tank-logo-menu{
	margin-bottom:auto;
	margin-top:auto;
}


// .thinkTankReviveCard .spaces_logo_image{
// 	width:60px;
// 	position:relative;
// 	height:auto;
// 	margin-left:1rem;
// }

.reviveImage{
	width:60px;
	height:60px;
	margin-top:auto;
	margin-bottom:auto;
	position:relative;
	height:auto;
	margin-left:1rem;
}

.thinkTankReviveCard .lil_think_tank{
	background-color: white;
	width: 24px;
    height: 24px;
	bottom: -3px;
    right: 0px;
    border-radius: 3rem;
    padding: 3px;
    padding-bottom: 5px;
}

.corpo-icon-black{
	border:none !important;
}

.corpo-icon-white{
	background-color: white;
    border-radius: 0.5rem;
	padding:2px;
}

.selectable:hover .logo_at_sign{
	color:#1c95ef !important;
}

// .selectable:hover .logo_copyWriting{
// 	filter: invert(43%) sepia(91%) saturate(1405%) hue-rotate(182deg) brightness(97%) contrast(93%);
// }

.logo_at_sign{
	color: white;
    font-weight: 200;
    margin-bottom: auto;
    margin-top: auto;
    margin-left: 0.5rem;
    margin-right: -0.25rem;
}

.spaces_logo_image_top{
	margin-left:auto;
	margin-right:auto;
	width: 60px;
    margin-top: 1.25rem;
    margin-bottom: 0.25rem;
}

.spaces_logo_image_bottom{
	margin-left:auto;
	margin-right:auto;
	width:85px;
	margin-bottom:2rem;
}

.spaces-container{
	width:75%;
	margin-left:auto;
	margin-right:auto;
	padding:0rem;
	z-index:2;
	margin-top:2rem;
	margin-bottom:2rem;
	min-height:90vh;
	border-radius:1rem;
	overflow:hidden;
}

.spaces-side-col{
	padding:1rem;
	width:15%;
	background-color:#1d2127;
	height:auto;
}

.spaces-main-col{
	width:85%;
	padding:2rem;
}

.spaces-nav-link{
	border:none;
	background-color: #00000000;
	color:white;

}

.spaces-nav-link.active{
	color:$link-blue;
}

.spaces-nav-link:hover{
	color:$link-blue;
	cursor:pointer;
	transform:scale(0.97);
}

.spaces-text-input{
	background-color: #eaeaea;
	border-radius: 0.5rem;
	border:none;
	padding: 10px;
	padding-top:5px;
	padding-bottom:5px;
    width: 100%;
}

.spaces-main-col h3{
	font-weight:400;
}

.spaces-main-col h3.strongish{
	font-weight:600 !important;
}

.spaces_image_area{
	padding: 1rem;
    background-color: #eaeaea;
    border-radius: 0.5rem;
}

.spaces-setting-btn{
	width:fit-content;
	padding:0.5rem;
	padding-left:1rem;
	padding-right:1rem;
	border: 2px solid #1c95ef;
	border-radius: 2rem;
	margin-bottom:0.5rem;
	font-size:12px;
}

.spaces-setting-btn .cat-icon{
	margin-left:0rem;
}

.spaces-save-btn{
	width:fit-content;
	padding:0.5rem;
	padding-left:1rem;
	padding-right:1rem;
	background-color:$base-orange;
	border-radius: 2rem;
	margin-bottom:0.5rem;
	font-size:12px;
	align-content: center;
	color:white; 
	border:none !important;
}

.spaces-save-btn:hover{
	transform:scale(0.97);
	color:white;
}

.spaces-save-btn p{
	font-size:12px;
}

.spaces-setting-btn p{
	font-size:12px;
}

.spaces-checkbox{
	padding:0.5rem;
	padding-left:1rem;
	padding-right:1rem;
	width:fit-content;
}

.spaces-checkbox:hover{
	transform:scale(0.97);
}


.spaces-setting-btn:hover{
	transform:scale(0.97);
}

.spaces-setting-img{
	width: 100px;
    height: 100px;
    margin-bottom: 1rem;

}

.change_categories_area{
	padding:0.5rem;
	border:1px solid #dbdbdb;
	border-radius:0.25rem;
}

.cat_cross{
	width:14px;
	height:14px;
}

.cat-input {
	background-color:transparent !important;
	border:none !important;
	display: inline-block;
	min-width: 50px;
	align-content:center;
	width:max-content;
	padding:0.5rem;
	margin-bottom:0rem !important;
	
}

.cat-main-name .cat-input{
	font-size:15px !important;
}

.cat-main-name p{
	font-size:15px !important;
}

.secondary-cat-name{
	width:max-content;
	// margin-left:1rem;
	align-content:center;
	padding:0.5rem;
	padding-top:0.25rem;
	padding-bottom:0.25rem;
	padding-right:0rem;
	margin-bottom:0rem !important;
	color:#0060a8;
	font-size:12px;
}

.being-dragged {
	background: orange !important;
	@extend .bg-info;
}
.category-at-rest {
	background-color:#eaeaea;
	border-radius:0.5rem;
}

.category-at-rest:hover{
	cursor:pointer;
}

.category-hover-over {
	background: $base-blue !important;
}
// .drag-interactive-bg {
// 	background-color: $bright-blue;
// }
.category-is-bad{
	background-color: $base-red;
}
// #end_drop_area{
// 	background-color: yellow;
// }

.cat-clicked{
	border:1px solid #bbdcf5;
}

.cat-accord{
	display:none;
	padding-bottom:0.5rem;
}

.cat-accord .cat-input{
	padding-top:0.25rem;
	padding-bottom:0.25rem;
	font-size:12px;
}

.cat-clicked .cat-accord{
	display:flex !important;
}

.cat-clicked .cat-main-name .cat-input{
	padding-bottom:0.25rem;
}

.cat-icon{
	width:20px;
	margin-left:0.5rem;
	margin-right:0.5rem;
	margin-top:auto;
	margin-bottom:auto;
}

.cat-clicked .cat-icon{
	margin-top:0.65rem;
}

.cat-clicked .cat-side{
	background-color: #bbdcf5;
}

.cat-side{
	width:calc(20px + 1rem);
	display: flex;
    justify-content: center;
	border-radius: 0.4rem 0rem 0rem 0.4rem;
}

.cat-content{
	width:calc(100% - (20px + 1rem));
}

.cat-info-text{
	font-size:12px;
	margin-left:auto;
	margin-bottom: auto;
    margin-top: auto;
    margin-right: 0rem;
	color: #b7b7b7;
}

.cat_info{
	width:12px;
	height:12px;
}

.cat-info-btn{
	padding:0.5rem;
	padding-top:0rem;
	padding-bottom:0rem;
}

.cat-info-dropdown{
	z-index: 10;
    border-radius: 0.5rem;
    box-shadow: rgba(44, 44, 44, 0.1294117647) -2px 2px 4px 2px;
    width: max-content;
    min-width: max-content;
    padding: 1rem;
}

.email-invites-box{
	border:1px solid #dbdbdb;
	padding:1rem;
	margin-bottom:01rem;
	border-radius:0.25rem;
}

.email-domain-box{
	width: -webkit-fill-available;
}

.spaces-suggestion{
	margin:0rem !important;
}

.spaces-link-box{
	border: 2px solid $base-blue;
    padding: 1rem;
    border-radius: 0.25rem;
	margin-bottom:1rem;
}

.spaces-list{
	background-color:#eaeaea;
	margin-bottom:1rem;
	overflow:hidden;
	border-radius:0.25rem;
}

.spaces-list-header{
	background-color:#dddddd;
	padding:0.5rem;
	margin-bottom:0rem;
	font-weight:600;
	padding-left:1rem;
}

.spaces-list-item{
	padding:0.5em;
	padding-left:1rem;
}

.spaces-list-item .btn{
	padding-top:0rem;
	padding-bottom:0rem;
}

.cancel-account-button{
	background-color: #c18f47;
    border: none;
    padding: 0.5rem;
    padding-left: 1rem;
    margin-top: auto;
    width: fit-content;
    padding-right: 1rem;
}

.exit-cancel-account{
	border:none;
	border-radius:0.25rem;
	margin-left:auto;
	margin-right:0.5rem;
	padding-left:1rem;
	padding-right:1rem;
}

.exit-cancel-account:hover{
	transform:scale(0.97);
}

.thinkTankLoadingCard{
	margin-top:2rem;
	padding:2rem;
	gap:2rem;
}

.thinkTankLoading-left{
	width:40%;
	position:relative;
}

.thinkTankLoading-right{
	width:60%;
	align-content: center;
}

.thinkTankLoadingImg{
	width:100%;
	// position:absolute;
	// top:0px;
	// left:0px;
}


.thinkTankLoadingTitle{
	font-size:32px;
}

.thinkTankLoadingImg2{
	will-change: transform;
	position:absolute;
	top: 50%;
  	left: 50%;
 	transform: translate(-50%, -50%);
	width:100%;
	animation: rotateCircles 3.5s ease-in-out infinite;
}

@keyframes rotateCircles {
	0% {transform: translate(-50%, -50%) rotate(0deg);}
	100% {transform: translate(-50%, -50%) rotate(360deg);}
}

.TT_cccontainer{
	position:absolute;
	width:80%;
	height:80%;
	top: 50%;
  	left: 50%;
 	transform: translate(-50%, -50%);
}

.TT_circleContainer{
	position: relative;
 	width: 100%;
  	height: 100%;
  	animation: rotate 3s ease-in-out infinite;
}

.TT_circle{
	position: absolute;
	width: 20px;
	height: 20px;
	background-color: #1c95ee;
	border-radius: 50%;
}

.TT_circleDark{
	background-color: #294a63 !important;
}

.TT_circle:nth-child(1) { top: 0; left: 50%; transform: translate(-50%, -50%); }
.TT_circle:nth-child(2) { top: 14.64%; left: 85.36%; transform: translate(-50%, -50%); }
.TT_circle:nth-child(3) { top: 50%; left: 100%; transform: translate(-50%, -50%); }
.TT_circle:nth-child(4) { top: 85.36%; left: 85.36%; transform: translate(-50%, -50%); }
.TT_circle:nth-child(5) { bottom: 0; left: 50%; transform: translate(-50%, 50%); }
.TT_circle:nth-child(6) { top: 85.36%; left: 14.64%; transform: translate(-50%, -50%); }
.TT_circle:nth-child(7) { top: 50%; left: 0; transform: translate(-50%, -50%); }
.TT_circle:nth-child(8) { top: 14.64%; left: 14.64%; transform: translate(-50%, -50%); }

@keyframes rotate {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

.thinkTankReviveCard{
	@extend .MH_seussCard;
	padding: 1rem;
	margin-top:2rem;
	margin-bottom:0.5rem;
	position: relative;
	border-radius: 0.5rem !important;
	@media screen and (max-width:$phone){
		width: calc(100% - 2rem);
		border-radius: 0.5rem;
		box-shadow: none;
	}
}

.spaces_invite_btn{
	margin-top: auto;
	margin-left:auto;
	border:2px solid $base-blue;
	border-radius:0.5rem;
	padding:0.5rem;
	padding-left:1rem;
	padding-right:1rem;
	background-color:white;
}

.spaces_invite_input{
	margin-top:auto;
	border-radius:0.5rem;
	padding:0.5rem;
	padding-left:1rem;
	padding-right:1rem;
	margin-bottom: 0.5rem;
    border: none;
    background-color: #eaeaea;
}

.spaces_invite_btn:hover{
	transform:scale(0.97);
}

.spaces_invite_content{
	padding:2rem;
	min-height:350px;
	@media screen and (max-width:$desktop){
		padding:1rem;
		min-height: 200px;
		position: absolute;
		background-color: #fffffff2;
		top:50%;
		left:50%;
		transform: translate(-50%, -50%);
		border-radius:0.5rem;
		width: calc(100% - 2rem);
		max-width: 450px;
	}
}

.invite_seuss{
	max-width:1000px;
	margin-top:5rem;
	@media screen and (max-width:$desktop){
		margin-top:0rem;
		width:100%;
		// min-height:calc(100vh - 6rem);
		display:block !important;
		border-radius: 0rem;
	}
}

.invite_seuss_img{
	@media screen and (max-width:$desktop){
		width:100%;
		border-radius:0.5rem;
		object-fit: cover;
		height:calc(100vh - 6rem);
		display:block !important;
	}
}

.invite_overlay{
	display:none;
	@media screen and (max-width:$desktop){
		display:block;
		width:100%;
		height:100%;
		position:absolute;
		top: 0px;
		left: 0px;
		background-color: #00000066;
	}
}

.spaces_questionare{
	background-color:white;
	border-radius:0.5rem;
	width:70%;
	max-width:650px;
	height:auto;
	padding:2rem;
	z-index: 10;
    position: relative;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
	@media screen and (max-width:$desktop){
		width:100%;
		padding:1rem;
		margin-top:0rem;
		border-radius:0rem;
	}
}

.spaces_setting_top_announcement{
	margin-bottom:1rem;
	border: 1px #1c95ef solid;
    padding: 1rem;
    border-radius: 0.5rem;
}

.spaces_questionare_btn{
	padding:0.5rem;
	padding-top: 0.25rem;
    padding-bottom: 0.25rem;
	background-color:#eaeaea;
	border-radius:0.5rem;
}

.spaces_questionare_btn:hover{
	transform:scale(0.97);
	background-color:#dedede;
}

.questionare-yes-btn{
	background-color:#83caff;
}

.questionare-no-btn{
	background-color:#ffbe6e;
}

.questionare-done-btn{
	background-color:#72c8ff;
	padding-left:1.5rem;
	padding-right:1.5rem;
	margin-left:auto;
}



// ###################################################################################
// ####################  EXTRA / UNORGANIZED #########################################
// ##################################################################################

.z-0{
	z-index:0;
}

.pl-0{
	padding-left:0rem;
}

.space-between{
	justify-content: space-between !important;
}

.float-right{
	float:right;
}

.page {
    @extend .px-2;
    @extend .pb-3;
}

.notice {
    @extend .my-2;
    @extend .alert-info;
    @extend .container;
}

.alert {
    @extend .row;
    @extend .my-2;
    @extend .alert-warning;
    @extend .container;
}

.my-alert{
    margin-top:0px !important;
    margin-bottom:0rem !important;
    padding:3rem;
}

.liquid-alert{
	background-color:white !important;
	margin-top:0px !important;
    margin-bottom:0rem !important;
    padding:3rem;
}

.liquid-alert-img{
	margin-bottom:1rem;
}

.dropdown_area .notice_area{
    padding-left:0.25rem;
    padding-right:0.25rem;
    padding-bottom:0.5rem;
    padding-top:0rem;
}

.dropdown_area .my-alert{
    padding:2rem !important;
    box-shadow: none !important;
    border-radius:0.5rem;
}

.blue-text {
	color: $link-blue-two !important;
	font-weight:400 !important;
}

.light-blue-text{
    color: $base-blue !important;
}

.prod_image{
    max-width: 100%;
}
.blue-on-hover:hover{
	color:$link-blue-two !important;
	cursor:pointer;
}

.norm-on-hover:hover{
	cursor:default;
}

// #############################################
// Text area

.r{
    font-family: poppins, noto sans, arial, sans-serif;
    color:$darkest-dark;
}

a {
    cursor: pointer;
}

.text-lrg {
    font-size: large;
}

.robo{
    font-family: roboto, poppins, noto sans, arial, sans-serif;
    font-weight:lighter;
}

.pop{
    font-family: poppins, noto sans, arial, sans-serif;
}

.strong{
    font-weight: 600 !important;
}

.strongish{
    font-weight: 600;
}

// Fixing a really stupid bootstrap inital value
.row{
    --bs-gutter-x:0px;
}


// Tinytexteditor
.tinymce{
	width:100%;
	height:5rem;
}

.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type){
	border-right:none !important;
}

.tox .tox-toolbar, .tox .tox-toolbar__primary, .tox .tox-toolbar__overflow{
	background:none !important;
}

// Abstract

.min-h-100vh{
	min-height:100vh;
}


.uni-icon-gap{
	gap:0.5rem;
}

.uni-icon-gap-half{
	gap:0.25rem;
}

.mobile-display-none{
	@media screen and (max-width:$desktop){
        display: none;
    }
}

.p-1{
    padding:1rem !important;
}

.p-05{
	padding:0.5rem;
}

.p-25{
    padding:0.25rem !important;
}

.p-0{
	padding:0px !important;
}

.mobile-p-0{
	@media screen and (max-width:$desktop){
        padding:0px !important;
    }
}

.no-margin{
    margin:0rem !important;
}

.no-wrap{
	white-space: nowrap;
}

.mt-auto{
	margin-top:auto;
}

.mb-2px{
	margin-bottom:2px;
}

.mr-2px{
	margin-right:2px;
}

.mb-25{
    margin-bottom: 1px !important;
}


.mt-25{
    margin-top: 1px !important;
}

.mt-2{
	margin-top:2rem !important;
}


.mt-04{
    margin-top: 1px !important;
}

.mt-05{
    margin-top: 1px !important;
	@media screen and (max-width:$desktop){
        margin-top:1px;
    }
}

.margin-top-1-then-5{
    margin-top:0.8rem !important;
    @media screen and (max-width:$desktop){
        margin-top:0.4rem !important;
    }
}

.mb-01rem{
	margin-bottom:0.1rem;
}

.mb-05{
    margin-bottom: 1px !important;
	@media screen and (max-width:$desktop){
        margin-bottom: 1px;
    }
}

.mb-025{
	margin-bottom:0.25rem;
}

.mb-05-seriously{
    margin-bottom: 0.5rem !important;
}

.mt-05-seriously{
    margin-top: 0.5rem !important;
}

.mt-02{
	margin-top:1px;
}

.mt-02-mobile{
    @media screen and (max-width:$desktop){
        margin-top:1px !important;
    }
}

.ml-02-mobile{
    @media screen and (max-width:$desktop){
        margin-left:2px !important;
    }
}

.mr-02-mobile{
    @media screen and (max-width:$desktop){
        margin-right:2px !important;
    }
}

.mb-02-mobile{
    @media screen and (max-width:$desktop){
        margin-bottom:1px !important;
    }
}

.mr-03-mobile{
    @media screen and (max-width:$desktop){
        margin-right:$margin-mobile-1 !important;
    }
}

.mt-03-mobile{
    @media screen and (max-width:$desktop){
        margin-top:$margin-mobile-1 !important;
    }
}

.ml-03-mobile{
    @media screen and (max-width:$desktop){
        margin-left:$margin-mobile-1 !important;
    }
}

.mb-03-mobile{
    @media screen and (max-width:$desktop){
        margin-bottom:$margin-mobile-1 !important;
    }
}

.ml-05-mobile{
    @media screen and (max-width:$desktop){
        margin-left:0.5rem !important;
    }
}

.mr-05-mobile{
    @media screen and (max-width:$desktop){
        margin-right:0.5rem !important;
    }
}

.mb-05-mobile{
    @media screen and (max-width:$desktop){
        margin-bottom:0.5rem !important;
    }
}

.mt-05-mobile{
    @media screen and (max-width:$desktop){
        margin-top:0.5rem !important;
    }
}

.mt-04-mobile{
    @media screen and (max-width:$desktop){
        margin-top:0.4rem !important;
    }
}

.mb-075-mobile{
    @media screen and (max-width:$desktop){
        margin-bottom:0.75rem !important;
    }
}

.mt-1-mobile{
    @media screen and (max-width:$desktop){
        margin-top:1rem !important;
    }
}

.mb-1-mobile{
    @media screen and (max-width:$desktop){
        margin-bottom:1rem !important;
    }
}

.ml-neg-25-mobile{
	@media screen and (max-width:$desktop){
        margin-left:-0.25rem !important;
    }
}

.mr-05-seriously{
	margin-right:0.5rem;
}

.fuck-mb{
	margin-bottom:1rem;
	@media screen and (max-width:$desktop){
        margin-bottom:0.5rem !important;
    }
}

.ml-auto{
	margin-left:auto;
}

.mb-0{
    margin-bottom:0px !important;
}

.mt-1{
    margin-top: 1rem !important;
}

.mb-1{
    margin-bottom:1rem !important;
}

.mb-10{
    margin-bottom:1rem;
}

.mb-02{
    margin-bottom:2px;
}

.mb-08{
    margin-bottom: 0.8rem !important;
}

// DO NOT CHANGE UNLESS YOU FIX COMPLETELY. THIS DIRECTLY CHANGES THE MARGIN OF POST TOP SECTION
.mb-13{
    margin-bottom:0.4rem !important;
    @media screen and (max-width:$desktop){
        margin-bottom:0.2rem !important;
    }
}

.mb-16{
    margin-bottom: 1.6rem;
}

.mt-16{
    margin-top: 1.6rem;
}

.ml-05{
    margin-left: 2px;
}

.ml-05-seriously{
	margin-left:0.5rem;
}

.pl-05{
    padding-left: 0.25rem !important;
    @media screen and (max-width:$desktop){
        padding-left:0.25rem !important;
    }
}

.ml-0{
	margin-left:0rem !important;
}

.ml-1{
    margin-left: 1rem !important;
}

.ml-2{
    margin-left: 2rem !important;
}

.ml-25{
    margin-left: 2.5rem !important;
}


.ml-3{
    margin-left: 3rem !important;
}


.ml-4{
    margin-left: 4rem !important;
}

.mr-1{
	margin-right:1rem;
}

.mr-05{
    margin-right: 0.4rem !important;
    @media screen and (max-width:$desktop){
        margin-right:0.2rem !important;
    }
}

.mt-neg-1{
	@media screen and (max-width:$desktop){
		margin-top:-1rem;
    }
}

.mr-1{
    margin-right: 1rem !important;
}

.mr-2{
    margin-right: 2rem !important;
}

.prl-25{
    padding-right:0.25rem;
    padding-left:0.25rem;
}

.ital{
    font-style:italic;
}

.mobile-border-curves-left{
    @media screen and (max-width:$desktop){
        border-radius: 0.25rem 0rem 0rem 0.25rem !important;
    }
}

.font-12{
	font-size: 11px;
}


.abs-card{
    height:auto;
    width:100%;
    color:$darkest-dark;
    background-color: white;
    border-radius: $common-border-radius;
    margin-bottom: $margin-dt-1;
    justify-content: center;
    text-align: left;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left:1rem;
    padding-right:1rem;
    @media screen and (max-width:$desktop){
        border-radius: $common-border-radius-mobile;
        margin-bottom: 0rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.abs-container{
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    margin-top: 0.4rem;
    gap:0.4rem;
    @media screen and (max-width:$desktop){
        gap:0.5rem;
    }
}

.abs-inner-card{
    flex: 1 0 auto;
    //changed height from auto to fit content to fix confirm selected topics area.
    height:fit-content;
    width:auto;
    color:$darkest-dark !important;
    background-color: white;
    border-radius: $common-border-radius;
    justify-content: center;
    text-align: left;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left:1.5rem;
    padding-right:1.5rem; 
    font-size:16px;
}

.inline-text-formatting{
    display:block;
}

.my-span{
    display:inline;
}

.h-100{
    height:100%;
}

.h-total{
	min-height:100vh;
}

.h-inherit{
    height:inherit;
}

.w-100{
    width:100%;
}

.w-auto{
	width:auto;
}

.btn-close{
    cursor:pointer
}

 .is_clickable p:last-child, .post-page-post p:last-child{
    margin-bottom:0px;
}

.abs-btn:hover{
    background-color:$bright-blue !important;
    color:white;
}

.white-text{
    color:white !important;
}

.white-text-ni{
	color:$darkest-dark;
}


.form-select{
    border-radius: 0.5rem;
}

.card{
    border:none;
}

.abs-hover:hover{
    cursor: pointer;
}

.card{
	// I hate everything fuck yo card
	border-radius:$common-border-radius;
}

.no-border{
	border:none !important;
}

.no-border-radius{
	border-radius:0rem !important;
}

.tester{
	background-color:red;
}

.boop{
	margin-left: 2rem;
	@media screen and (max-width:$desktop){
        margin-left: 2rem;
    }
}

.z-index-10{
	z-index:10;
}

.feed-dot{
	margin-left:2rem;
}

.accordion .container-fluid{
	padding:0px;
}

//this is from the gem cookies_eu
$cookies-eu-background-color: white;
$cookies-eu-border-color: #E4E4E4;
$cookies-eu-button-background-color: #4D90FE;
$cookies-eu-button-color: #FFFFFF;
$cookies-eu-button-background-color-no: red;
$cookies-eu-mobile: 'max-width: 767px';

.cookies-eu {
  line-height: 40px;

  position: fixed;
  z-index: 999;
  right: 0;
  bottom: 0;
  left: 0;

  padding: 1rem;

  border-top: 1px solid $cookies-eu-border-color;
  background: $cookies-eu-background-color;

  @media ($cookies-eu-mobile) {
    font-size: 11px;
    line-height: 30px;

    // position: initial;
  }

  .cookies-eu-content-holder {
    display: inline-block;
	text-align: left;
    padding: 0 20px;
	width:100%;
	margin-top:auto;
	margin-bottom:auto;
  }

}

.cookies-eu-button-holder {
    display: inline-block;
	text-align: right;
	width:100%;
    .cookies-eu-ok {
      font-size: 11px;
      font-weight: bold;

      line-height: 20px;

      min-width: 26px;
      margin-right: 5px;
      padding: 5px 12px;

      cursor: pointer;
      text-align: center;
      white-space: nowrap;

      color: $cookies-eu-button-color;
      border: 0;
	  border-radius:0.5rem;
      outline: none;
      background-color: $cookies-eu-button-background-color;
    }
	.cookies-eu-no {
		@extend .cookies-eu-ok;
		background-color: $cookies-eu-button-background-color-no;
	}
}

//LUKE DID THIS FOR LIKE BUTTON TEXT
//see: https://developer.mozilla.org/en-US/docs/Web/CSS/user-select
.unselectable {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}


//LUKE DID THIS FOR THE TELEPHONE STUFF
@import 'intl-tel-input/build/css/intlTelInput.css';
.iti__flag {background-image: url("intl-tel-input/build/img/flags.png");}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("intl-tel-input/build/img/flags@2x.png");}
}

.here{
	background-color:yellow;
	color:red;
}

//LUKE DID THIS FOR EDITORRRR
.ProseMirror p.is-editor-empty:first-child::before {
	color: #adb5bd;
	content: attr(data-placeholder);
	float: left;
	height: 0;
	pointer-events: none;
 }


 //perhaps this > https://dev.to/matrixersp/how-to-make-an-input-field-grow-shrink-as-you-type-513l
.check-no {
	@extend .bg-secondary;
}
.checkable-area {
	@extend .card;
	@extend .border;
	padding: 10px;
}

